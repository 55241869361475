import {connect} from "react-redux";
import DownloadModal from "../component/DownloadModal";
import {
    closeOptionsModalAction,
    triggerDownloadAction,
    getWordCloudAction,
    deleteMeetingFromDashboardAction
} from "../../../actions/meetingActions";

const mapStateToProps = (state) => ({
    meeting: state.meeting,
});

const mapDispatchToProps = (dispatch) => ({
    closeOptionsModal: () => dispatch(closeOptionsModalAction()),
    triggerDownload: (type, format) => dispatch(triggerDownloadAction(type, format)),
    getWordCloud: () => dispatch(getWordCloudAction()),
    deleteMeetingFromDashboard: (meetingID) => dispatch(deleteMeetingFromDashboardAction(meetingID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadModal);