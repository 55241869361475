import {connect} from "react-redux";
import Modal from '../component/Modal';
import {
    forgotPasswordRequestAction,
    loginRequestAction,
    registrationRequestAction, resetPasswordAction
} from "../../../actions/modalActions";
import {
    calendarSyncRequestRegisterAction,
    outlookSyncRegisterAction,
    googleSyncRegisterAction
} from "../../../actions/calendarSyncActions";
import {
    getUserCalendarInfoRegisterAction
} from "../../../actions/userActions";

const mapStateToProps = (state) => ({
    auth: state.auth,
    calendarSync: state.calendarSync,
    calendars: state.calendarReducer,
});

const mapDispatchToProps = (dispatch) => ({
        sendLoginPayload: (loginEmail, loginPassword) => dispatch(loginRequestAction(loginEmail, loginPassword)),
        sendRegisterPayload: (registrationPayloadObject) => dispatch(registrationRequestAction(registrationPayloadObject)),
        resetPassword: (forgotPasswordEMail) => dispatch(forgotPasswordRequestAction(forgotPasswordEMail)),
        googleSyncActionRegister: (syncPayload) => dispatch(googleSyncRegisterAction(syncPayload)),
        outlookSyncActionRegister: (syncPayload) => dispatch(outlookSyncRegisterAction(syncPayload)),
        calendarSyncRequestActionRegister: (data) => dispatch(calendarSyncRequestRegisterAction(data)),
        resetPasswordA: (token, password) => dispatch(resetPasswordAction(token, password)),
        getUserCalendarInfoRegister: () => dispatch(getUserCalendarInfoRegisterAction()),
    })
;

export default connect(mapStateToProps, mapDispatchToProps)(Modal);