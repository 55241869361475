import {createStore, applyMiddleware, compose} from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger  from 'redux-logger';
import rootReducer from './reducers';
import rootSaga from './sagas/rootSaga';

const inititalState = {};
const sagaMiddleware = createSagaMiddleware();
const middleWares = [sagaMiddleware, logger];
const store = createStore(
    rootReducer,
    inititalState,
    compose(applyMiddleware(...middleWares))
);

sagaMiddleware.run(rootSaga);

export default store;
