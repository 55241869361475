import {
    GOOGLE_SYNC,
    OUTLOOK_SYNC
} from "../actions/types";

const initialState = {
    googleCalendarConnected: false,
    outlookCalendarConnected: false,
    googleCalendarEmail: '',
    outlookCalendarEmail: '',
    googleSyncLoading: false,
    outlookSyncLoading: false
}

export default function(state = initialState, action) {
    switch (action.type) {
        case GOOGLE_SYNC:
            return { 
                ...state, 
                googleCalendarConnected: action.params.googleCalendarConnected,
                googleCalendarEmail: action.params.googleCalendarEmail,
                googleSyncLoading: action.params.googleSyncLoading
            };
        case OUTLOOK_SYNC:
            return { 
                ...state, 
                outlookCalendarConnected: action.params.outlookCalendarConnected,
                outlookCalendarEmail: action.params.outlookCalendarEmail,
                outlookSyncLoading: action.params.outlookSyncLoading
            };
        default:
            return state;
    }
}