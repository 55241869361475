import React, {Component} from "react";
import highlights from "../../../assets/star-icon.png";
import transcripts from "../../../assets/chat-icon.png";
import settings from "../../../assets/setting-icon.png";

import "../style/Tab.css";

class Tab extends Component {

    handleActive = (activeTab) => {
        this.props.handleActive(activeTab)
    };

    render() {
        const {type, widget} = this.props;

        return (
            <div
                onClick={() => this.handleActive(type)}
                className="tab-outer">

                {type === "highlights" &&
                <img alt="Highlights" src={highlights} className="tab-img"/>
                }

                {type === "transcript" &&
                <img alt="Transcript" src={transcripts} className="tab-img"/>
                }

                {type === "settings" &&
                <img alt="Settings" src={settings} className="tab-img"/>
                }

                {type === "highlights" &&
                <p className={type === "highlights" && widget.activeTab.activeTab === "highlights" ? "tab-text active" : "tab-text"}>
                    HIGHLIGHTS
                </p>
                }

                {type === "transcript" &&
                <p className={type === "transcript" && widget.activeTab.activeTab === "transcript" ? "tab-text active" : "tab-text"}>
                    TRANSCRIPT
                </p>
                }

                {type === "settings" &&
                <p className={type === "settings" && widget.activeTab.activeTab === "settings" ? "tab-text active" : "tab-text"}>
                    SETTINGS
                </p>
                }
            </div>
        );
    }
}

export default Tab;