export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const REGISTRATION_REQUEST = 'REGISTRATION_REQUEST';
export const SESSION_VALIDITY_REQUEST = 'SESSION_VALIDITY_REQUEST';
export const USER_MEETING_OVERVIEW_RECEIVED = 'USER_MEETING_OVERVIEW_RECEIVED';
export const USER_MEETING_OVERVIEW_NOT_RECEIVED = 'USER_MEETING_OVERVIEW_NOT_RECEIVED';
export const SESSION_USER_DATA_RECEIVED = 'SESSION_USER_DATA_RECEIVED';
export const ONGOING_ACTIVE_CALLS = 'ONGOING_ACTIVE_CALLS';
export const NO_ONGOING_ACTIVE_CALLS = 'NO_ONGOING_ACTIVE_CALLS';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';
export const REGISTRATION_FAILED = 'REGISTRATION_FAILED';
export const REGISTRATION_SUCCESSFUL = 'REGISTRATION_SUCCESSFUL';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const SHOW_RESET_PASSWORD_SUCCESS = 'SHOW_RESET_PASSWORD_SUCCESS';
export const SHOW_RESET_PASSWORD_FAILURE = 'SHOW_RESET_PASSWORD_FAILURE';
export const CLOSE_RESET_PASSWORD_TOAST = 'CLOSE_RESET_PASSWORD_TOAST';
export const SUBSCRIPTION_CANCELLATION_REQUEST = 'SUBSCRIPTION_CANCELLATION_REQUEST';
export const SUBSCRIPTION_CANCELLATION_REQUEST_SUCCESS = 'SUBSCRIPTION_CANCELLATION_REQUEST_SUCCESS';
export const SUBSCRIPTION_CANCELLATION_REQUEST_FAILURE = 'SUBSCRIPTION_CANCELLATION_REQUEST_FAILURE';
export const FORGOT_PASSWORD_REQUEST_SUCCESS = 'FORGOT_PASSWORD_REQUEST_SUCCESS';
export const FORGOT_PASSWORD_REQUEST_FAILED = 'FORGOT_PASSWORD_REQUEST_FAILED';
export const TOGGLE_DROPDOWN = 'TOGGLE_DROPDOWN';
export const CHANGE_SETTINGS_WIDGET = 'CHANGE_SETTINGS_WIDGET';
export const UPDATE_USER_INFO_REQUEST = 'UPDATE_USER_INFO_REQUEST';
export const GET_USER_CALENDAR_INFO_REQUEST = 'GET_USER_CALENDAR_INFO_REQUEST';
export const GET_USER_CALENDAR_INFO_REGISTER_REQUEST = 'GET_USER_CALENDAR_INFO_REGISTER_REQUEST';
export const GET_UPCOMING_USER_EVENTS = 'GET_UPCOMING_USER_EVENTS';
export const UPDATE_DEFAULT_CALL_OPT_REQUEST = 'UPDATE_DEFAULT_CALL_OPT_REQUEST';
export const GET_UPCOMING_USER_EVENTS_SUCCESS = 'GET_UPCOMING_USER_EVENTS_SUCCESS';
export const GET_UPCOMING_USER_EVENTS_FAILURE = 'GET_UPCOMING_USER_EVENTS_FAILURE';
export const CALENDARS_LOADING = 'CALENDARS_LOADING';
export const NO_CALENDAR_SYNCED = 'NO_CALENDAR_SYNCED';
export const GOOGLE_CALENDAR_SYNCED = 'GOOGLE_CALENDAR_SYNCED';
export const OUTLOOK_CALENDAR_SYNCED = 'OUTLOOK_CALENDAR_SYNCED';
export const UPDATE_USER_PASSWORD_REQUEST = 'UPDATE_USER_PASSWORD_REQUEST';
export const UPDATE_USER_INFO_REQUEST_SUCCESSFUL = 'UPDATE_USER_INFO_REQUEST_SUCCESSFUL';
export const UPDATE_USER_INFO_REQUEST_FAILED = 'UPDATE_USER_INFO_REQUEST_FAILED';
export const SHOW_TOAST = 'SHOW_TOAST';
export const CLOSE_TOAST = 'CLOSE_TOAST';
export const MEETING_CALLING_IN_REQUEST = 'MEETING_CALLING_IN_REQUEST';
export const MEETING_CALLING_IN_REQUEST_SUCCESS = 'MEETING_CALLING_IN_REQUEST_SUCCESS';
export const MEETING_CALLING_IN_REQUEST_FAILURE = 'MEETING_CALLING_IN_REQUEST_FAILURE';
export const CLOSE_MEETING_CREATION_TOAST = 'CLOSE_MEETING_CREATION_TOAST';
export const ARCHIVED_MEETINGS_REQUEST = 'ARCHIVED_MEETINGS_REQUEST';
export const SHOW_UPDATED_SEARCH = 'SHOW_UPDATED_SEARCH';
export const ARCHIVED_MEETINGS_REQUEST_SUCCESS = 'ARCHIVED_MEETINGS_REQUEST_SUCCESS';
export const ARCHIVED_MEETINGS_REQUEST_FAILURE = 'ARCHIVED_MEETINGS_REQUEST_FAILURE';
export const GET_MEETING_DETAILS_REQUEST = 'GET_MEETING_DETAILS_REQUEST';
export const GET_ALL_MEETING_DETAILS_REQUEST = 'GET_ALL_MEETING_DETAILS_REQUEST';
export const GET_ALL_LIVE_MEETING_DETAILS_REQUEST = 'GET_ALL_LIVE_MEETING_DETAILS_REQUEST';
export const GET_LIVE_MEETING_DETAILS_REQUEST = 'GET_LIVE_MEETING_DETAILS_REQUEST';
export const GET_LIVE_MEETING_DETAILS_REQUEST_SUCCESS = 'GET_LIVE_MEETING_DETAILS_REQUEST_SUCCESS';
export const GET_ALL_LIVE_MEETING_DETAILS_REQUEST_SUCCESS = 'GET_ALL_LIVE_MEETING_DETAILS_REQUEST_SUCCESS';
export const GET_LIVE_MEETING_DETAILS_REQUEST_FAILURE = 'GET_LIVE_MEETING_DETAILS_REQUEST_FAILURE';
export const GET_MEETING_DETAILS_REQUEST_SUCCESS = 'GET_MEETING_DETAILS_REQUEST_SUCCESS';
export const GET_MEETING_DETAILS_REQUEST_FAILURE = 'GET_MEETING_DETAILS_REQUEST_FAILURE';
export const SET_MEETING_NAME_AND_DATE = 'SET_MEETING_NAME_AND_DATE';
export const SET_MEETING_NAME_AND_DATE_SUCCESS = 'SET_MEETING_NAME_AND_DATE_SUCCESS';
export const GET_MEETINGS_FROM_SEARCH_REQUEST = 'GET_MEETINGS_FROM_SEARCH_REQUEST';
export const GET_MEETINGS_FROM_SEARCH_FOUND = 'GET_MEETINGS_FROM_SEARCH_FOUND';
export const GET_MEETINGS_FROM_SEARCH_NOT_FOUND = 'GET_MEETINGS_FROM_SEARCH_NOT_FOUND';
export const FLUSH_SEARCH_RESULTS = 'FLUSH_SEARCH_RESULTS';
export const GOOGLE_SYNC = 'GOOGLE_SYNC';
export const OUTLOOK_SYNC = 'OUTLOOK_SYNC';
export const CALENDAR_SYNC_REQUEST = 'CALENDAR_SYNC_REQUEST';
export const GOOGLE_SYNC_REGISTER = 'GOOGLE_SYNC_REGISTER';
export const OUTLOOK_SYNC_REGISTER = 'OUTLOOK_SYNC_REGISTER';
export const CALENDAR_SYNC_REQUEST_REGISTER = 'CALENDAR_SYNC_REQUEST_REGISTER';
export const CHANGE_MAKE_CALL_STATUS_REQUEST = 'CHANGE_MAKE_CALL_STATUS_REQUEST';
export const TRANSCRIPTS_REQUEST = 'TRANSCRIPTS_REQUEST';
export const GET_TRIGGERS_REQUEST = 'GET_TRIGGERS_REQUEST';
export const GET_TRIGGERS_REQUEST_FAILURE = 'GET_TRIGGERS_REQUEST_FAILURE';
export const GET_TRIGGERS_REQUEST_SUCCESS = 'GET_TRIGGERS_REQUEST_SUCCESS';
export const LOAD_TRIGGERS = 'LOAD_TRIGGERS';
export const DELETE_TRIGGER_REQUEST = 'DELETE_TRIGGER_REQUEST';
export const DELETE_TRIGGER_REQUEST_SUCCESS = 'DELETE_TRIGGER_REQUEST_SUCCESS';
export const DELETE_TRIGGER_REQUEST_FAILURE = 'DELETE_TRIGGER_REQUEST_FAILURE';
export const ADD_TRIGGER_REQUEST = 'ADD_TRIGGER_REQUEST';
export const SHOW_TRIGGER_DUPLICATE_TOAST = 'SHOW_TRIGGER_DUPLICATE_TOAST';
export const HIDE_TRIGGER_DUPLICATE_TOAST = 'HIDE_TRIGGER_DUPLICATE_TOAST';
export const ADD_TRIGGER_REQUEST_SUCCESS = 'ADD_TRIGGER_REQUEST_SUCCESS';
export const ADD_TRIGGER_REQUEST_FAILURE = 'ADD_TRIGGER_REQUEST_FAILURE';
export const OPEN_EDIT_TRANSCRIPT_MODAL = 'OPEN_EDIT_TRANSCRIPT_MODAL';
export const OPEN_EDIT_TRANSCRIPT_MODAL_LIVE = 'OPEN_EDIT_TRANSCRIPT_MODAL_LIVE';
export const CLOSE_EDIT_TRANSCRIPT_MODAL = 'CLOSE_EDIT_TRANSCRIPT_MODAL';
export const SHOW_EDIT_MODAL = 'SHOW_EDIT_MODAL';
export const SHOW_EDIT_MODAL_LIVE = 'SHOW_EDIT_MODAL_LIVE';
export const HIDE_EDIT_MODAL = 'HIDE_EDIT_MODAL';
export const HIDE_EDIT_MODAL_LIVE = 'HIDE_EDIT_MODAL_LIVE';
export const UPDATE_TRANSCRIPT_REQUEST = 'UPDATE_TRANSCRIPT_REQUEST';
export const UPDATE_TRANSCRIPT_LIVE_REQUEST = 'UPDATE_TRANSCRIPT_LIVE_REQUEST';
export const TRANSCRIPTS_REQUEST_LOADING = 'TRANSCRIPTS_REQUEST_LOADING';
export const UPDATE_TRANSCRIPT_REQUEST_SUCCESS = 'UPDATE_TRANSCRIPT_REQUEST_SUCCESS';
export const UPDATE_TRANSCRIPT_REQUEST_FAILURE = 'UPDATE_TRANSCRIPT_REQUEST_FAILURE';
export const SEARCH_IN_MEETING_REQUEST = 'SEARCH_IN_MEETING_REQUEST';
export const SEARCH_IN_MEETING_REQUEST_FOUND = 'SEARCH_IN_MEETING_REQUEST_FOUND';
export const SEARCH_IN_MEETING_REQUEST_NOT_FOUND = 'SEARCH_IN_MEETING_REQUEST_NOT_FOUND';
export const FLUSH_CONTENT_SEARCH_RESULTS = 'FLUSH_CONTENT_SEARCH_RESULTS';
export const OPEN_DOWNLOAD_MODAL = 'OPEN_DOWNLOAD_MODAL';
export const CLOSE_DOWNLOAD_MODAL = 'CLOSE_DOWNLOAD_MODAL';
export const TRIGGER_DOWNLOAD = 'TRIGGER_DOWNLOAD';
export const AUDIO_AVAILABLE = 'AUDIO_AVAILABLE';
export const AUDIO_NOT_AVAILABLE = 'AUDIO_NOT_AVAILABLE';
export const GET_WORD_CLOUD_REQUEST = 'GET_WORD_CLOUD_REQUEST';
export const GET_WORD_CLOUD_REQUEST_SUCCESS = 'GET_WORD_CLOUD_REQUEST_SUCCESS';
export const GET_WORD_CLOUD_REQUEST_FAILURE = 'GET_WORD_CLOUD_REQUEST_FAILURE';
export const MESSAGE_CATEGORY_REQUEST = 'MESSAGE_CATEGORY_REQUEST';
export const LIVE_MESSAGE_CATEGORY_REQUEST = 'LIVE_MESSAGE_CATEGORY_REQUEST';
export const MESSAGE_CATEGORY_REQUEST_SUCCESS = 'MESSAGE_CATEGORY_REQUEST_SUCCESS';
export const LIVE_MESSAGE_CATEGORY_REQUEST_SUCCESS = 'LIVE_MESSAGE_CATEGORY_REQUEST_SUCCESS';
export const MESSAGE_CATEGORY_REQUEST_FAILURE = 'MESSAGE_CATEGORY_REQUEST_FAILURE';
export const MESSAGE_HIGHLIGHT_REQUEST = 'MESSAGE_HIGHLIGHT_REQUEST';
export const MESSAGE_HIGHLIGHT_REQUEST_SUCCESS = 'MESSAGE_HIGHLIGHT_REQUEST_SUCCESS';
export const MESSAGE_HIGHLIGHT_REQUEST_FAILURE = 'MESSAGE_HIGHLIGHT_REQUEST_FAILURE';
export const STOP_TRIGGER = 'STOP_TRIGGER';
export const SWITCH_TABS = 'SWITCH_TABS';
export const ADD_HIGHLIGHT_REQUEST = 'ADD_HIGHLIGHT_REQUEST';
export const FETCH_TRANSCRIPT_COMMENTS_REQUEST = 'FETCH_TRANSCRIPT_COMMENTS_REQUEST';
export const FETCH_FINISHED_TRANSCRIPT_COMMENTS_REQUEST = 'FETCH_FINISHED_TRANSCRIPT_COMMENTS_REQUEST';
export const FETCH_TRANSCRIPT_COMMENTS_SUCCESS = 'FETCH_TRANSCRIPT_COMMENTS_SUCCESS';
export const UPDATE_COMMENTS = 'UPDATE_COMMENTS';
export const UPDATE_HIGHLIGHT_COMMENTS = 'UPDATE_HIGHLIGHT_COMMENTS';
export const FETCH_TRANSCRIPT_COMMENTS_FAILURE = 'FETCH_TRANSCRIPT_COMMENTS_FAILURE';
export const ADD_COMMENT_REQUEST = 'ADD_COMMENT_REQUEST';
export const ADD_HIGHLIGHT_COMMENT_REQUEST = 'ADD_HIGHLIGHT_COMMENT_REQUEST';
export const DELETE_TRANSCRIPT_COMMENT_REQUEST = 'DELETE_TRANSCRIPT_COMMENT_REQUEST';
export const DELETE_TRANSCRIPT_HIGHLIGHT_COMMENT_REQUEST = 'DELETE_TRANSCRIPT_HIGHLIGHT_COMMENT_REQUEST';
export const ADD_CUSTOM_NOTE_REQUEST = 'ADD_CUSTOM_NOTE_REQUEST';
export const DELETE_MEETING_REQUEST = 'DELETE_MEETING_REQUEST';
export const DELETE_MEETING_FROM_MODAL_REQUEST = 'DELETE_MEETING_FROM_MODAL_REQUEST';
export const DELETE_MEETING_FROM_DASHBOARD_REQUEST = 'DELETE_MEETING_FROM_DASHBOARD_REQUEST';
export const DELETE_MEETING_REQUEST_SUCCESS = 'DELETE_MEETING_REQUEST_SUCCESS';
export const DELETE_MEETING_REQUEST_FAILURE = 'DELETE_MEETING_REQUEST_FAILURE';
export const TRANSCRIPT_MESSAGE_CATEGORY_REQUEST = 'TRANSCRIPT_MESSAGE_CATEGORY_REQUEST';
export const DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST';
export const CLOSE_DELETE_MODAL = 'CLOSE_DELETE_MODAL';
export const OPEN_DELETE_MODAL = 'OPEN_DELETE_MODAL';
export const GET_MEETING_HIGHLIGHT_COMMENTS_REQUEST = 'GET_MEETING_HIGHLIGHT_COMMENTS_REQUEST';
export const GET_MEETING_HIGHLIGHT_COMMENTS_REQUEST_SUCCESS = 'GET_MEETING_HIGHLIGHT_COMMENTS_REQUEST_SUCCESS';
export const MEETING_HIGHLIGHT_COMMENTS_LOADED = 'MEETING_HIGHLIGHT_COMMENTS_LOADED';
export const GET_MEETING_HIGHLIGHT_COMMENTS_REQUEST_FAILURE = 'GET_MEETING_HIGHLIGHT_COMMENTS_REQUEST_FAILURE';
export const OPEN_PROFILE_PICTURE_MODAL = 'OPEN_PROFILE_PICTURE_MODAL';
export const CLOSE_PROFILE_PICTURE_MODAL = 'CLOSE_PROFILE_PICTURE_MODAL';
export const UPLOAD_PROFILE_PICTURE_REQUEST = 'UPLOAD_PROFILE_PICTURE_REQUEST';
export const CHECK_RESET_TOKEN_VALIDITY_REQUEST = 'CHECK_RESET_TOKEN_VALIDITY_REQUEST';
export const RESET_TOKEN_NOT_VALID = 'RESET_TOKEN_NOT_VALID';
export const UPDATE_MEETING_NAME_REQUEST = 'UPDATE_MEETING_NAME_REQUEST';
