import React, {Component} from 'react'
import {connect} from "react-redux";

import Header from "../../components/Header/container/Header";
import Sidebar from "../../components/Sidebar/container/Sidebar";
import * as Constants from '../../components/Common';

import lockIcon from "../../assets/lock-icon.png";
import DeleteIcon from "@material-ui/icons/Delete";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SearchIcon from '@material-ui/icons/Search';

import EditTranscriptModal from "../../components/EditTranscriptModal/container/EditTranscriptModal";
import DownloadModal from "../../components/DownloadModal/container/DownloadModal";

import {checkSessionValidityAction} from "../../actions/authActions";
import {
    deleteMeetingAction, flushSearchResultAction,
    getArchivedMeetingsAction,
    getMeetingsFromSearchAction
} from "../../actions/meetingActions";

import {dashboard_url} from "../../components/Common";
import dateFormat from "dateformat";
import moment from "moment";
import "./Archives.css";
import ProfilePictureModal from "../../components/ProfilePictureModal/container/ProfilePictureModal";

let scope;

class Archives extends Component {

    constructor(props) {
        super(props);
        scope = this;

        this.state = {
            meetingName: "",
            meetingCount: 0,
            searchMeetingsInput: '',
            currentPage: 1,
            dropDownMenuVisible: true,
            windowWidth: window.innerWidth,
            modalInputValue: props.meeting.showEditModal ? props.meeting.editTranscript : "",
        };

        this.update = this.update.bind(this);
    }

    handleResize = (e) => {
        this.setState({windowWidth: window.innerWidth})
    };

    handeClick = () => {
        if (!this.state.dropDownMenuVisible) {
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            dropDownMenuVisible: !prevState.dropDownMenuVisible
        }));
    };

    handleOutsideClick = (e) => {
        if (this.node.contains(e.target)) {
            return;
        }

        this.handeClick();
    };

    update(e) {
        let name = e.target.name;
        let value = e.target.value;
        scope.setState({[name]: value});
    };

    convertSecondstoTime = (seconds) => {

        let hours = Math.floor(seconds / 3600);
        let minutes = Math.floor((seconds - (hours * 3600)) / 60);
        let seconds2 = seconds - (hours * 3600) - (minutes * 60);

        let timeString = "";
        timeString = hours.toString().padStart(2, '0') + ' h ' +
            minutes.toString().padStart(2, '0') + ' m ' +
            seconds2.toString().padStart(2, '0') + ' s';

        if (hours < 1 && minutes < 10)
            timeString =
                timeString = hours.toString() + ' h ' +
                    minutes.toString() + ' m ' +
                    seconds2.toString().padStart(2, '0') + ' s';

        return timeString;
    };

    handlePageChange = (type) => {
        if (type === "subtract") {
            if (this.state.currentPage > 1) {
                let tempCurrPage = this.state.currentPage;
                this.setState({
                    currentPage: tempCurrPage - 1,
                    meetingCount: this.state.meetingCount - 20
                }, () => {
                    if (this.props.meeting.meetingSearchResults.length < 1)
                        this.props.getArchivedMeetings(this.state.currentPage);
                    else
                        this.props.getMeetingsFromSearch(this.state.searchMeetingsInput, this.state.currentPage);
                });
            }
        }

        if (type === "add") {
            let tempCurrPage = this.state.currentPage;
            this.setState({
                currentPage: tempCurrPage + 1,
                meetingCount: this.state.meetingCount + 20
            }, () => {
                if (this.props.meeting.meetingSearchResults.length < 1)
                    this.props.getArchivedMeetings(this.state.currentPage);
                else
                    this.props.getMeetingsFromSearch(this.state.searchMeetingsInput, this.state.currentPage);
            });
        }
    };

    formatAMPM = (date) => {
        console.log(date);
        let hours = parseInt(date.split(":")[0]);
        let minutes = parseInt(date.split(":")[1]);
        let ampm = hours >= 12 ? 'AM' : 'PM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        let strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    };

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);

        if (localStorage.getItem("authToken") !== null) {
            this.props.checkSessionValidity();
            this.props.getArchivedMeetings(1);
            this.setState({
                modalInputValue: this.props.meeting.editTranscript
            })
        } else
            window.location.href = (Constants.login_url);
    }

    searchMeetingName = () => {
        if (this.state.searchMeetingsInput.length > 0) {
            this.props.getMeetingsFromSearch(this.state.searchMeetingsInput, this.state.currentPage);
        }

        if (this.state.searchMeetingsInput.trim() === "") {
            this.props.flushSearchResult();
            this.setState({currentPage: 1});
        }
    };

    render() {
        const {meetingName, currentPage, windowWidth} = this.state;
        const {auth, meeting} = this.props;

        return (

            <div className="w-100 main-container">

                {meeting.showEditModal &&
                <EditTranscriptModal
                    editMeetingID={meeting.editMeetingID}
                    editTranscriptID={meeting.editTranscriptID}
                    editTranscript={meeting.editTranscript}
                />
                }

                {meeting.showOptionsModal &&
                <DownloadModal
                />
                }

                <Sidebar
                    page={"archives"}
                />

                <Header
                    page={"archives"}
                />

                {auth.showProfilePictureModal &&
                <ProfilePictureModal/>
                }

                <div className=
                         {windowWidth > 1024 ?
                             "main-container-body w-100 px-3 pt-3 overflow-hidden" :
                             "main-container-body w-100 px-3 pt-3"
                         }>
                    <div className="row">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-4 mb-4 mb-sm-4 mb-md-4 mb-lg-0 pe-lg-2"
                        >

                            <div className="card w-100 box-shadow border-1">

                                <div style={{margin: "0 auto"}} className="input-group p-3">

                                    <span className="input-group-text bg-white border-end-0">

                                        <SearchIcon
                                            style={{height: "1rem"}}
                                        />
                                    </span>

                                    <input
                                        type="text"
                                        autoComplete="off"
                                        className="form-input-search f-12"
                                        name="searchMeetingsInput"
                                        value={this.state.searchMeetingsInput}
                                        onChange={this.update}
                                        onKeyUp={() => this.searchMeetingName()}
                                        placeholder="Search Meetings"
                                    />
                                </div>

                                <div className="w-100 meetings-folder">

                                    <h2 className="fw-b f-14 px-3 pt-3 mb-0">Meeting Folders</h2>

                                    <div className="px-3 py-3 set-height">

                                        <ul className="m-0 p-0">

                                            <li className="d-flex align-items-center justify-content-start">
                                                <img src={lockIcon} alt="" className="me-3"/>
                                                <span className="fw-b f-11 f-blue">My Meetings</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-8 ps-lg-2">
                            <div className="card w-100 box-shadow border-0 ">
                                <div className="px-2 py-2">

                                    <div style={{display: "flex"}}>

                                        <h2 className="f-16 fw-b mb-4 p-1">My Meetings</h2>

                                        {currentPage > 1 &&
                                        <ChevronLeftIcon
                                            onClick={() => {
                                                this.handlePageChange("subtract")
                                            }}
                                            style={{margin: ".1rem .25rem 0 1rem", cursor: "pointer"}}/>
                                        }

                                        {currentPage > 1 ?
                                            <p style={{margin: ".1rem 0 0 0"}}>Page {currentPage}</p>
                                            :
                                            <p style={{margin: ".1rem 0 0 1rem"}}>Page {currentPage}</p>
                                        }
                                        {
                                            (meeting.meetingSearchResults.length < 1) ?
                                                (Math.abs(auth.userTotalMeetings - this.state.meetingCount)) >= 20
                                                &&
                                                <ChevronRightIcon
                                                    onClick={() => {
                                                        this.handlePageChange("add")
                                                    }}
                                                    style={{margin: ".1rem 0 0 .25rem", cursor: "pointer"}}/> :
                                                (meeting.meetingSearchResults.length >= 20)
                                                &&
                                                <ChevronRightIcon
                                                    onClick={() => {
                                                        this.handlePageChange("add")
                                                    }}
                                                    style={{margin: ".1rem 0 0 .25rem", cursor: "pointer"}}/>
                                        }
                                    </div>

                                    {(meeting.meetingSearchResults.length < 1) &&
                                    <div className="w-100 my-meetings-list">
                                        {(meetingName.length < 1 || (!meeting.showNothingFound && !meeting.archivedMeetingsLoading && meeting.archivedMeetingsFetched && meeting.meetingSearchResults.length < 1)) &&
                                        meeting.archivedMeetings.map((key, i) =>

                                            <div className="list-block">

                                                <div className="f-11 mb-2">

                                                    {dateFormat(
                                                        moment(
                                                            moment.utc(
                                                                new Date(key.created_time).toString()
                                                            )
                                                        ).local(),
                                                        'dddd, mmm dd, yyyy', true)
                                                    }
                                                </div>
                                                <div className="card box-shadow border-0 w-100 p-3">

                                                    <div
                                                        style={{cursor: "pointer"}}
                                                        className="d-flex align-items-center justify-content-between mb-2"
                                                    >

                                                        <h3
                                                            onClick={() => {
                                                                window.location.href = `${dashboard_url}?id=${key.meeting_id}`;
                                                                localStorage.setItem("dashboardMeetingName", key.meeting_name);
                                                                localStorage.setItem("dashboardMeetingDateAndTime", key.created_time);
                                                                localStorage.setItem("dashboardMeetingAudio", key.recordUrl);
                                                                localStorage.setItem("currentMeeting", key.meeting_id);
                                                                localStorage.setItem("currentMeetingPin", key.pin);
                                                                localStorage.setItem("currentMeetingDuration", key.duration);
                                                            }}
                                                            className="f-14 fw-b f-blue mb-0">

                                                            {key.meeting_name}
                                                        </h3>

                                                        <div
                                                            className="d-flex bg-light ms-3 px-2 py-1 rounded align-items-center d-flex justify-content-between f-12 pointer"
                                                        >

                                                            <DeleteIcon
                                                                onClick={() => this.props.deleteMeeting(false, key.meeting_id, currentPage)}
                                                                style={{height: "1rem", width: "1rem"}}/>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mb-1">

                                                        <label className="f-11 fw-b me-2">Time:&nbsp;</label>

                                                        <p className="f-11 mb-0">
                                                            {
                                                                dateFormat(
                                                                    moment(
                                                                        moment.utc(
                                                                            new Date(key["created_time"]).toString()
                                                                        )
                                                                    ).local(),
                                                                    'HH:MM', true).toString()
                                                            }
                                                            {/*    {dateFormat(*/}
                                                            {/*    ,*/}
                                                            {/*    'HH:MM', true)*/}
                                                            {/*}*/}
                                                        </p>
                                                    </div>

                                                    <div className="d-flex mb-1">

                                                        <label className="f-11 fw-b me-2">Duration:&nbsp;</label>

                                                        <p className="f-11 mb-0">{this.convertSecondstoTime(key.duration)}</p>
                                                    </div>

                                                    <div className="d-flex mb-1">

                                                        <label className="f-11 fw-b me-2">Highlights:&nbsp;</label>

                                                        <p className="f-11 mb-0">{key.highlightscount}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    }

                                    {(meeting.meetingSearchResults.length > 0) &&
                                    <div className="w-100 my-meetings-list">
                                        {meeting.meetingSearchResults.map((key, i) =>

                                            <div className="list-block">

                                                <div className="f-11 mb-2">

                                                    {dateFormat(
                                                        moment(
                                                            moment.utc(
                                                                new Date(key.created_time).toString()
                                                            )
                                                        ).local(),
                                                        'dddd, mmm dd, yyyy', true)
                                                    }
                                                </div>
                                                <div className="card box-shadow border-0 w-100 p-3">

                                                    <div
                                                        style={{cursor: "pointer"}}
                                                        className="d-flex align-items-center justify-content-between mb-2"
                                                    >

                                                        <h3
                                                            onClick={() => {
                                                                window.location.href = `${dashboard_url}?id=${key.meeting_id}`;
                                                                localStorage.setItem("dashboardMeetingName", key.meeting_name);
                                                                localStorage.setItem("dashboardMeetingDateAndTime", key.created_time);
                                                                localStorage.setItem("dashboardMeetingAudio", key.recordUrl);
                                                                localStorage.setItem("currentMeeting", key.meeting_id);
                                                                localStorage.setItem("currentMeetingPin", key.pin);
                                                                localStorage.setItem("currentMeetingDuration", key.duration);
                                                            }}
                                                            className="f-14 fw-b f-blue mb-0">

                                                            {key.meeting_name}
                                                        </h3>

                                                        <div
                                                            className="d-flex bg-light ms-3 px-2 py-1 rounded align-items-center d-flex justify-content-between f-12 pointer"
                                                        >

                                                            <DeleteIcon
                                                                onClick={() => {
                                                                    this.props.deleteMeeting(true, key.meeting_id, currentPage);
                                                                    //this.props.flushSearchResult();
                                                                    //this.setState({searchMeetingsInput: ""})
                                                                }}
                                                                style={{height: "1rem", width: "1rem"}}/>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mb-1">

                                                        <label className="f-11 fw-b me-2">Time:&nbsp;</label>

                                                        <p className="f-11 mb-0">
                                                            {
                                                                dateFormat(
                                                                    moment(
                                                                        moment.utc(
                                                                            new Date(key["created_time"]).toString()
                                                                        )
                                                                    ).local(),
                                                                    'HH:MM', true).toString()
                                                            }
                                                        </p>
                                                    </div>

                                                    <div className="d-flex mb-1">

                                                        <label className="f-11 fw-b me-2">Duration:&nbsp;</label>

                                                        <p className="f-11 mb-0">{this.convertSecondstoTime(key.duration)}</p>
                                                    </div>

                                                    <div className="d-flex mb-1">

                                                        <label className="f-11 fw-b me-2">Highlights:&nbsp;</label>

                                                        <p className="f-11 mb-0">{key.highlightscount}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    dropDown: state.dropDown,
    meeting: state.meeting,
});

const mapDispatchToProps = (dispatch) => ({
    checkSessionValidity: () => dispatch(checkSessionValidityAction()),
    getArchivedMeetings: (pageNo) => dispatch(getArchivedMeetingsAction(pageNo)),
    deleteMeeting: (isSearch, meetingID, currentPage) => dispatch(deleteMeetingAction(isSearch, meetingID, currentPage)),
    getMeetingsFromSearch: (meetingName, pageNo) => dispatch(getMeetingsFromSearchAction(meetingName, pageNo)),
    flushSearchResult: () => dispatch(flushSearchResultAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Archives);