import {
    ARCHIVED_MEETINGS_REQUEST,
    MEETING_CALLING_IN_REQUEST,
    GET_MEETING_DETAILS_REQUEST,
    SET_MEETING_NAME_AND_DATE,
    GET_MEETINGS_FROM_SEARCH_REQUEST,
    FLUSH_SEARCH_RESULTS,
    CHANGE_MAKE_CALL_STATUS_REQUEST,
    OPEN_EDIT_TRANSCRIPT_MODAL,
    CLOSE_EDIT_TRANSCRIPT_MODAL,
    UPDATE_TRANSCRIPT_REQUEST,
    GET_TRIGGERS_REQUEST,
    DELETE_TRIGGER_REQUEST,
    ADD_TRIGGER_REQUEST,
    SEARCH_IN_MEETING_REQUEST,
    FLUSH_CONTENT_SEARCH_RESULTS,
    OPEN_DOWNLOAD_MODAL,
    CLOSE_DOWNLOAD_MODAL,
    TRIGGER_DOWNLOAD,
    GET_WORD_CLOUD_REQUEST,
    GET_LIVE_MEETING_DETAILS_REQUEST,
    MESSAGE_CATEGORY_REQUEST,
    MESSAGE_HIGHLIGHT_REQUEST,
    STOP_TRIGGER,
    SWITCH_TABS,
    ADD_COMMENT_REQUEST,
    FETCH_TRANSCRIPT_COMMENTS_REQUEST,
    DELETE_TRANSCRIPT_COMMENT_REQUEST,
    ADD_CUSTOM_NOTE_REQUEST,
    DELETE_MEETING_REQUEST,
    TRANSCRIPT_MESSAGE_CATEGORY_REQUEST,
    DELETE_MEETING_FROM_DASHBOARD_REQUEST,
    FETCH_FINISHED_TRANSCRIPT_COMMENTS_REQUEST,
    CLOSE_DELETE_MODAL,
    GET_ALL_MEETING_DETAILS_REQUEST,
    GET_ALL_LIVE_MEETING_DETAILS_REQUEST,
    OPEN_EDIT_TRANSCRIPT_MODAL_LIVE,
    UPDATE_TRANSCRIPT_LIVE_REQUEST,
    HIDE_EDIT_MODAL_LIVE,
    GET_MEETING_HIGHLIGHT_COMMENTS_REQUEST,
    DELETE_TRANSCRIPT_HIGHLIGHT_COMMENT_REQUEST,
    LIVE_MESSAGE_CATEGORY_REQUEST,
    ADD_HIGHLIGHT_COMMENT_REQUEST,
    UPDATE_MEETING_NAME_REQUEST,
    SHOW_TRIGGER_DUPLICATE_TOAST,
    HIDE_TRIGGER_DUPLICATE_TOAST
} from "./types";

export const inviteToMeetingAction = (userID, meetingAdmin, meetingAdminName, meetingName, meetingURL, meetingPasscode) => ({
    type: MEETING_CALLING_IN_REQUEST,
    params: {userID, meetingAdmin, meetingAdminName, meetingName, meetingURL, meetingPasscode}
});

export const getArchivedMeetingsAction = (pageNo) => ({
    type: ARCHIVED_MEETINGS_REQUEST,
    params: {pageNo}
});

export const getAllMeetingDetailsAction = (meetingID) => ({
    type: GET_ALL_MEETING_DETAILS_REQUEST,
    params: {meetingID}
});

export const getMeetingDetailsAction = (meetingID) => ({
    type: GET_MEETING_DETAILS_REQUEST,
    params: {meetingID}
});

export const setMeetingNameAndDateAction = (meetingName, localDate, recordURL) => ({
    type: SET_MEETING_NAME_AND_DATE,
    params: {meetingName, localDate, recordURL}
});

export const getMeetingsFromSearchAction = (meetingName, pageNo) => ({
    type: GET_MEETINGS_FROM_SEARCH_REQUEST,
    params: {meetingName, pageNo}
});

export const flushSearchResultAction = () => ({
    type: FLUSH_SEARCH_RESULTS
});

export const changeMakeCallAction = (meetingID, switchStatus) => ({
    type: CHANGE_MAKE_CALL_STATUS_REQUEST,
    params: {meetingID, switchStatus}
});

export const getTriggersAction = () => ({
    type: GET_TRIGGERS_REQUEST,
});

export const deleteTriggerAction = (triggerID) => ({
    type: DELETE_TRIGGER_REQUEST,
    params: {triggerID}
});

export const addTriggerAction = (triggerType, triggerText) => ({
    type: ADD_TRIGGER_REQUEST,
    params: {triggerType, triggerText}
});

export const showDuplicateTriggerAction = () => ({
    type: SHOW_TRIGGER_DUPLICATE_TOAST,
});

export const hideDuplicateTriggerAction = () => ({
   type: HIDE_TRIGGER_DUPLICATE_TOAST,
});

export const openEditModalAction = (transcriptID, meetingID, transcript) => ({
    type: OPEN_EDIT_TRANSCRIPT_MODAL,
    params: {transcriptID, meetingID, transcript}
});

export const openEditModalLiveAction = (transcriptID, meetingID, transcript) => ({
    type: OPEN_EDIT_TRANSCRIPT_MODAL_LIVE,
    params: {transcriptID, meetingID, transcript}
});

export const closeEditTranscriptModalAction = () => ({
    type: CLOSE_EDIT_TRANSCRIPT_MODAL
});

export const closeEditTranscriptModalLiveAction = () => ({
    type: HIDE_EDIT_MODAL_LIVE
});

export const updateTranscriptAction = (meetingID, transcriptID, transcript) => ({
    type: UPDATE_TRANSCRIPT_REQUEST,
    params: {meetingID, transcriptID, transcript}

});

export const updateTranscriptLiveAction = (meetingID, transcriptID, transcript) => ({
    type: UPDATE_TRANSCRIPT_LIVE_REQUEST,
    params: {meetingID, transcriptID, transcript}

});

export const getContentFromSearchAction = (meetingID, meetingContent) => ({
    type: SEARCH_IN_MEETING_REQUEST,
    params: {meetingID, meetingContent}

});

export const flushContentResultAction = () => ({
    type: FLUSH_CONTENT_SEARCH_RESULTS
});

export const openOptionsModalAction = (type) => ({
    type: OPEN_DOWNLOAD_MODAL,
    params: {type}
});

export const closeOptionsModalAction = () => ({
    type: CLOSE_DOWNLOAD_MODAL
});

export const triggerDownloadAction = (type, format) => ({
    type: TRIGGER_DOWNLOAD,
    params: {type, format}
});

export const getWordCloudAction = () => ({
    type: GET_WORD_CLOUD_REQUEST
});

export const getLiveMeetingDataAction = (meetingID) => ({
    type: GET_LIVE_MEETING_DETAILS_REQUEST,
    params: {meetingID}
});

export const getAllLiveMeetingDataAction = (meetingID) => ({
    type: GET_ALL_LIVE_MEETING_DETAILS_REQUEST,
    params: {meetingID}
});

export const handleCategoryAction = (category, msgID,  meetingID) => ({
    type: MESSAGE_CATEGORY_REQUEST,
    params: {category, msgID, meetingID}
});

export const handleLiveCategoryAction = (category, msgID,  meetingID) => ({
    type: LIVE_MESSAGE_CATEGORY_REQUEST,
    params: {category, msgID, meetingID}
});

export const transcriptHandleCategoryAction = (category, msgID,  meetingID) => ({
    type: TRANSCRIPT_MESSAGE_CATEGORY_REQUEST,
    params: {category, msgID, meetingID}
});

export const handleHighlightAction = (meetingID, msgID, highlight) => ({
    type: MESSAGE_HIGHLIGHT_REQUEST,
    params: {meetingID, msgID, highlight}
});

export const stopTriggerAction = () => ({
    type: STOP_TRIGGER
});

export const handleActiveAction = (activeTab) => ({
    type: SWITCH_TABS,
    params: {activeTab}
});

export const addHighlightAction = (meetingID) => ({
    type: MESSAGE_HIGHLIGHT_REQUEST,
    params: {meetingID}
});

export const saveCommentAction = (comment, transcriptID) => ({
    type: ADD_COMMENT_REQUEST,
    params: {comment, transcriptID}
});

export const saveHighlightCommentAction = (comment, transcriptID) => ({
    type: ADD_HIGHLIGHT_COMMENT_REQUEST,
    params: {comment, transcriptID}
});

export const fetchTranscriptCommentsAction = (transcriptID) => ({
    type: FETCH_TRANSCRIPT_COMMENTS_REQUEST,
    params: {transcriptID}
});

export const deleteCommentAction = (commentID) => ({
    type: DELETE_TRANSCRIPT_COMMENT_REQUEST,
    params: {commentID}
});

export const deleteHighlightCommentAction = (commentID) => ({
    type: DELETE_TRANSCRIPT_HIGHLIGHT_COMMENT_REQUEST,
    params: {commentID}
});

export const addNoteToMeetingAction = (meetingID, note) => ({
    type: ADD_CUSTOM_NOTE_REQUEST,
    params: {meetingID, note}
});

export const deleteMeetingAction = (isSearch, meetingID, currentPage) => ({
    type: DELETE_MEETING_REQUEST,
    params: {isSearch, meetingID, currentPage}
});

export const deleteMeetingFromDashboardAction = (meetingID) => ({
    type: DELETE_MEETING_FROM_DASHBOARD_REQUEST,
    params: {meetingID}
});

export const fetchFinishedTranscriptCommentsAction = (meetingID) => ({
    type: FETCH_FINISHED_TRANSCRIPT_COMMENTS_REQUEST,
    params: {meetingID}
});

export const closeDeleteModalAction = () => ({
    type: CLOSE_DELETE_MODAL
});

export const getMeetingHighlightCommentsAction = (highlightedIDs) => ({
    type: GET_MEETING_HIGHLIGHT_COMMENTS_REQUEST,
    params: {highlightedIDs}
});

export const updateMeetingNameAction = (meetingID, meetingName) => ({
    type: UPDATE_MEETING_NAME_REQUEST,
    params: {meetingID, meetingName}
});