import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Constants from './components/Common';

/** Initializing GA Module */
import ReactGA from 'react-ga';

/** Initializing GTM Module */
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: Constants.googleTagID,
    auth: Constants.gtm_auth,
    preview: Constants.gtm_preview
    // dataLayerName: 'AccountDetailsPageDataLayer'
}

TagManager.initialize(tagManagerArgs)
/**  Initializing GTM Module Compelete*/

/** Initializing GA Module */
ReactGA.initialize(Constants.analyticsID);
ReactGA.pageview(window.location.pathname + window.location.search);
/** Initializing GA Module Compelete*/




ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
