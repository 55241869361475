import {connect} from "react-redux";
import UpcomingMeetingsWidget from "../component/UpcomingMeetingsWidget";
import {getUserScheduledMeetingsAction} from "../../../actions/userActions";

const mapStateToProps = (state) => ({
    calendarReducer: state.calendarReducer
});

const mapDispatchToProps = (dispatch) => ({
    getUserScheduledMeetings: (pageNo) => dispatch(getUserScheduledMeetingsAction(pageNo))
});

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingMeetingsWidget);