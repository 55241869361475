import {
    LOGIN_REQUEST,
    LOGIN_FAILED,
    LOGIN_SUCCESSFUL,
    REGISTRATION_REQUEST,
    REGISTRATION_FAILED,
    REGISTRATION_SUCCESSFUL,
    SESSION_USER_DATA_RECEIVED,
    SESSION_VALIDITY_REQUEST,
    UPDATE_USER_INFO_REQUEST,
    UPDATE_USER_INFO_REQUEST_SUCCESSFUL,
    UPDATE_USER_INFO_REQUEST_FAILED,
    SHOW_TOAST,
    CLOSE_TOAST,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_REQUEST_SUCCESS,
    FORGOT_PASSWORD_REQUEST_FAILED,
    CLOSE_DELETE_MODAL,
    OPEN_DELETE_MODAL,
    OPEN_PROFILE_PICTURE_MODAL,
    CLOSE_PROFILE_PICTURE_MODAL,
    ONGOING_ACTIVE_CALLS,
    NO_ONGOING_ACTIVE_CALLS,
    USER_MEETING_OVERVIEW_RECEIVED,
    SHOW_RESET_PASSWORD_SUCCESS,
    SHOW_RESET_PASSWORD_FAILURE,
    CLOSE_RESET_PASSWORD_TOAST,
    SUBSCRIPTION_CANCELLATION_REQUEST_SUCCESS,
    SUBSCRIPTION_CANCELLATION_REQUEST_FAILURE,
} from "../actions/types";

const initialState = {
    userLoading: true,
    user: {},
    loginLoading: false,
    loginFailed: false,
    responseMessage: "",
    registrationLoading: false,
    registrationFailed: false,
    showToast: false,
    userInfoUpdated: '',
    userInfoUpdating: false,
    forgotPasswordRequestLoading: false,
    forgotPasswordSuccess: false,
    forgotPasswordMessage: '',
    showDeleteModal: false,
    showProfilePictureModal: false,
    userActiveCalls: [],
    userTotalMeetings: "",
    userTotalMeetingMinutes: "",
    somethingWentWrong: false,
    resetPasswordRequestLoading: false,
    resetPasswordSuccessful: false,
    showResetPasswordStatusToast: false,
    resetPasswordMessage: "",
    subscriptionCanceled: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SESSION_VALIDITY_REQUEST:
            return {
                ...state,
                userLoading: true,
            };

        case ONGOING_ACTIVE_CALLS:
            return {
                ...state,
                userActiveCalls: action.data
            };

        case NO_ONGOING_ACTIVE_CALLS:
            return {
                ...state,
                userActiveCalls: []
            };

        case SESSION_USER_DATA_RECEIVED:
            return {
                ...state,
                userLoading: false,
                user: action.data
            };

        case LOGIN_REQUEST:
            return {
                ...state,
                loginLoading: true,
            };

        case LOGIN_FAILED:
            return {
                ...state,
                loginLoading: false,
                loginFailed: true,
                responseMessage: action.data.message
            };

        case LOGIN_SUCCESSFUL:
            return {
                ...state,
                loginLoading: false,
                loginFailed: false,
                responseMessage: action.message
            };

        case REGISTRATION_REQUEST:
            return {
                ...state,
                loginLoading: true,
            };

        case REGISTRATION_FAILED:
            return {
                ...state,
                loginLoading: false,
                loginFailed: true,
                responseMessage: action.message
            };

        case REGISTRATION_SUCCESSFUL:
            return {
                ...state,
                loginLoading: false,
                loginFailed: false,
                responseMessage: action.message
            };

        case UPDATE_USER_INFO_REQUEST:
            return {
                ...state,
                showToast: false,
                userInfoUpdating: true,
            };

        case UPDATE_USER_INFO_REQUEST_SUCCESSFUL:
            return {
                ...state,
                showToast: true,
                userInfoUpdating: false,
                userInfoUpdated: true,
                somethingWentWrong: false,
            };

        case UPDATE_USER_INFO_REQUEST_FAILED:
            return {
                ...state,
                showToast: true,
                userInfoUpdated: false,
                userInfoUpdating: false,
                somethingWentWrong: true
            };

        case SHOW_TOAST:
            return {
                ...state,
                showToast: true
            };

        case CLOSE_TOAST:
            return {
                ...state,
                showToast: false,
                userInfoUpdated: false,
                userInfoUpdating: false,
                somethingWentWrong: false
            };

        case FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                forgotPasswordRequestLoading: true
            };

        case FORGOT_PASSWORD_REQUEST_SUCCESS:
            return {
                ...state,
                showToast: true,
                forgotPasswordRequestLoading: false,
                forgotPasswordSuccess: action.data.status,
                forgotPasswordMessage: action.data.message
            };

        case FORGOT_PASSWORD_REQUEST_FAILED:
            return {
                ...state,
                showToast: true,
                forgotPasswordRequestLoading: false,
                forgotPasswordSuccess: action.data.status,
                forgotPasswordMessage: action.data.message
            };

        case OPEN_DELETE_MODAL:
            return {
                ...state,
                showDeleteModal: true,
            };

        case CLOSE_DELETE_MODAL:
            return {
                ...state,
                showDeleteModal: false
            };

        case OPEN_PROFILE_PICTURE_MODAL:
            return {
                ...state,
                showProfilePictureModal: true,
            };

        case CLOSE_PROFILE_PICTURE_MODAL:
            return {
                ...state,
                showProfilePictureModal: false,
            };

        case USER_MEETING_OVERVIEW_RECEIVED:
            return {
                ...state,
                userTotalMeetings: action.data.total_meetings,
                userTotalMeetingMinutes: action.data.total_duration
            };

        case SHOW_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordRequestLoading: false,
                resetPasswordSuccessful: true,
                showResetPasswordStatusToast: true,
                resetPasswordMessage: action.data,
            };

        case SHOW_RESET_PASSWORD_FAILURE:
            return {
                ...state,
                resetPasswordRequestLoading: false,
                resetPasswordSuccessful: false,
                showResetPasswordStatusToast: true,
                resetPasswordMessage: action.data,
            };

        case CLOSE_RESET_PASSWORD_TOAST:
            return {
                ...state,
                showResetPasswordStatusToast: false,
                resetPasswordMessage: "",
            };

        case SUBSCRIPTION_CANCELLATION_REQUEST_SUCCESS:
            return {
                ...state,
                subscriptionCanceled: true,
            };

        case SUBSCRIPTION_CANCELLATION_REQUEST_FAILURE:
            return {
                ...state,
                subscriptionCanceled: false,
            };

        default:
            return state;
    }
}
