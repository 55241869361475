import {connect} from "react-redux";
import ArchivesComponent from "../component/ArchivesComponent";
import {getArchivedMeetingsAction} from "../../../actions/meetingActions";


const mapStateToProps = (state) => ({
    meeting: state.meeting,
    auth: state.auth
});

const mapDispatchToProps = (dispatch) => ({
    getArchivedMeetings: (pageNo) => dispatch(getArchivedMeetingsAction(pageNo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArchivesComponent);