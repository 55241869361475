import {
    MEETING_CALLING_IN_REQUEST,
    MEETING_CALLING_IN_REQUEST_SUCCESS,
    MEETING_CALLING_IN_REQUEST_FAILURE,
    CLOSE_MEETING_CREATION_TOAST,
    ARCHIVED_MEETINGS_REQUEST,
    ARCHIVED_MEETINGS_REQUEST_SUCCESS,
    ARCHIVED_MEETINGS_REQUEST_FAILURE,
    GET_MEETING_DETAILS_REQUEST,
    GET_MEETING_DETAILS_REQUEST_SUCCESS,
    GET_MEETING_DETAILS_REQUEST_FAILURE,
    SET_MEETING_NAME_AND_DATE,
    SET_MEETING_NAME_AND_DATE_SUCCESS,
    GET_MEETINGS_FROM_SEARCH_REQUEST,
    GET_MEETINGS_FROM_SEARCH_FOUND,
    GET_TRIGGERS_REQUEST,
    GET_TRIGGERS_REQUEST_SUCCESS,
    TRANSCRIPTS_REQUEST_LOADING,
    GET_TRIGGERS_REQUEST_FAILURE,
    LOAD_TRIGGERS,
    GET_MEETINGS_FROM_SEARCH_NOT_FOUND,
    FLUSH_SEARCH_RESULTS,
    SHOW_EDIT_MODAL,
    HIDE_EDIT_MODAL,
    SEARCH_IN_MEETING_REQUEST,
    SEARCH_IN_MEETING_REQUEST_FOUND,
    SEARCH_IN_MEETING_REQUEST_NOT_FOUND,
    FLUSH_CONTENT_SEARCH_RESULTS,
    OPEN_DOWNLOAD_MODAL,
    CLOSE_DOWNLOAD_MODAL,
    GET_WORD_CLOUD_REQUEST,
    GET_WORD_CLOUD_REQUEST_SUCCESS,
    GET_WORD_CLOUD_REQUEST_FAILURE,
    AUDIO_AVAILABLE,
    OPEN_EDIT_TRANSCRIPT_MODAL,
    SHOW_UPDATED_SEARCH,
    MEETING_HIGHLIGHT_COMMENTS_LOADED,
    HIDE_TRIGGER_DUPLICATE_TOAST,
    SHOW_TRIGGER_DUPLICATE_TOAST,
} from "../actions/types";

const initialState = {
    liveMeetingLoading: false,
    meetingCreated: false,
    meetingName: '',
    failureMessage: '',
    successMessage: '',
    showToast: false,
    archivedMeetingsFetched: false,
    archivedMeetingsLoading: false,
    archivedMeetings: [],
    meetingDataLoading: false,
    meetingDataFetched: false,
    archivedMeetingTranscripts: [],
    meetingDetailsName: '',
    meetingDetailsDate: '',
    meetingAudioURL: '',
    searchReturnedResults: false,
    searchResultsLoading: false,
    meetingSearchResults: [],
    showNothingFound: false,
    triggersLoading: true,
    triggersReceived: false,
    triggers: [],
    showEditModal: false,
    editTranscriptsLoading: false,
    editMeetingID: '',
    editTranscriptID: '',
    editTranscript: '',
    contentResultsLoading: false,
    meetingContentSearchReturnedResults: false,
    meetingContentSearchReturnedResultsFetched: [],
    showNothingFoundInMeeting: false,
    showOptionsModal: false,
    wordCloudLoading: false,
    wordCloudArr: [],
    wordCloudReceived: false,
    wordCloudEmpty: false,
    audioDataLoading: false,
    audioAvailable: false,
    audioData: {},
    downloadModalType: "",
    meetingCommentsLoaded: false,
};

export default function (state = initialState, action) {
    switch (action.type) {

        case MEETING_CALLING_IN_REQUEST:
            return {
                ...state,
                showToast: false,
                liveMeetingLoading: true,
                meetingCreated: false,
                failureMessage: ''
            };

        case MEETING_CALLING_IN_REQUEST_SUCCESS:
            return {
                ...state,
                showToast: true,
                liveMeetingLoading: false,
                meetingCreated: action.data.success,
                meetingName: action.data.meetingName,
                successMessage: action.data.message,
                failureMessage: ''
            };

        case MEETING_CALLING_IN_REQUEST_FAILURE:
            return {
                ...state,
                showToast: true,
                liveMeetingLoading: false,
                meetingCreated: action.data.success,
                failureMessage: action.data.message,
                successMessage: ''
            };

        case CLOSE_MEETING_CREATION_TOAST:
            return {
                ...state,
                showToast: false
            };

        case ARCHIVED_MEETINGS_REQUEST:
            return {
                ...state,
                archivedMeetingsLoading: true,
                archivedMeetingsFetched: false,
            };

        case ARCHIVED_MEETINGS_REQUEST_SUCCESS:
            return {
                ...state,
                archivedMeetingsLoading: false,
                archivedMeetingsFetched: true,
                archivedMeetings: action.data.data
            };

        case ARCHIVED_MEETINGS_REQUEST_FAILURE:
            return {
                ...state,
                archivedMeetingsLoading: false,
                archivedMeetingsFetched: false
            };

        case GET_MEETING_DETAILS_REQUEST:
            return {
                ...state,
                meetingDataLoading: true,
                liveMeetingLoading: true,
                meetingDataFetched: false,
                archivedMeetingTranscripts: [],
            };

        case GET_MEETING_DETAILS_REQUEST_SUCCESS:
            return {
                ...state,
                meetingDataLoading: false,
                liveMeetingLoading: true,
                meetingDataFetched: action.data.status,
                archivedMeetingTranscripts: action.data.data,
            };

        case GET_MEETING_DETAILS_REQUEST_FAILURE:
            return {
                ...state,
                meetingDataLoading: false,
                meetingDataFetched: action.data.status,
                archivedMeetingTranscripts: action.data.data,
            };

        case SET_MEETING_NAME_AND_DATE:
            return {
                ...state,
            };

        case  SET_MEETING_NAME_AND_DATE_SUCCESS:
            return {
                ...state,
                meetingDetailsName: action.data.meetingName,
                meetingDetailsDate: action.data.localDate,
                meetingAudioURL: action.data.recordURL,
            };

        case GET_MEETINGS_FROM_SEARCH_REQUEST:
            return {
                ...state,
                searchReturnedResults: false,
                searchResultsLoading: true,
            };

        case GET_MEETINGS_FROM_SEARCH_FOUND:
            return {
                ...state,
                searchReturnedResults: true,
                searchResultsLoading: false,
                meetingSearchResults: action.data
            };

        case SHOW_UPDATED_SEARCH:
            let meetingSearchResultsSC = state.meetingSearchResults;
            let index = meetingSearchResultsSC.findIndex(e => e.meeting_id === action.data);
            if (index > -1) meetingSearchResultsSC.splice(index, 1);
            return {
                ...state,
                meetingSearchResults: meetingSearchResultsSC
            };

        case GET_MEETINGS_FROM_SEARCH_NOT_FOUND:
            return {
                ...state,
                showNothingFound: true,
                searchReturnedResults: true,
                searchResultsLoading: false,
                meetingSearchResults: []
            };

        case FLUSH_SEARCH_RESULTS:
            return {
                ...state,
                showNothingFound: false,
                searchReturnedResults: false,
                meetingSearchResults: []
            };

        case GET_TRIGGERS_REQUEST:
            return {
                ...state,
                triggersLoading: true,
                triggers: []
            };

        case SHOW_TRIGGER_DUPLICATE_TOAST:
            return {
                ...state,
                showToast: true,
            };

        case HIDE_TRIGGER_DUPLICATE_TOAST:
            return {
                ...state,
                showToast: false,
            };

        case GET_TRIGGERS_REQUEST_SUCCESS:
            return {
                ...state,
                triggersLoading: false,
                triggersReceived: true,
                triggers: action.data
            };

        case GET_TRIGGERS_REQUEST_FAILURE:
            return {
                ...state,
                triggersLoading: false,
                triggersReceived: false,
                triggers: []
            };

        case  LOAD_TRIGGERS:
            return {
                ...state,
                triggersLoading: true
            };

        case OPEN_EDIT_TRANSCRIPT_MODAL:
            return {
                ...state,
                showEditModal: true,
                editMeetingID: action.meetingID,
                editTranscriptID: action.transcriptID,
                editTranscript: action.transcript,
            };

        case SHOW_EDIT_MODAL:
            return {
                ...state,
                showEditModal: true,
                editMeetingID: action.data.meetingID,
                editTranscriptID: action.data.transcriptID,
                editTranscript: action.data.transcript,
            };

        case HIDE_EDIT_MODAL:
            return {
                ...state,
                showEditModal: false,
                editTranscriptsLoading: false,
                editMeetingID: '',
                editTranscriptID: '',
                editTranscript: '',
            };

        case TRANSCRIPTS_REQUEST_LOADING:
            return {
                ...state,
                editTranscriptsLoading: true
            };

        case SEARCH_IN_MEETING_REQUEST:
            return {
                ...state,
                contentResultsLoading: true,
            };

        case SEARCH_IN_MEETING_REQUEST_FOUND:
            return {
                ...state,
                contentResultsLoading: false,
                meetingContentSearchReturnedResults: true,
                meetingContentSearchReturnedResultsFetched: action.data,
                showNothingFoundInMeeting: false,
            };

        case SEARCH_IN_MEETING_REQUEST_NOT_FOUND:
            return {
                ...state,
                contentResultsLoading: false,
                showNothingFoundInMeeting: true,
            };

        case FLUSH_CONTENT_SEARCH_RESULTS:
            return {
                ...state,
                contentResultsLoading: false,
                meetingContentSearchReturnedResults: false,
                meetingContentSearchReturnedResultsFetched: [],
                showNothingFoundInMeeting: false,
            };

        case OPEN_DOWNLOAD_MODAL:
            return {
                ...state,
                showOptionsModal: true,
                downloadModalType: action.params.type
            };

        case CLOSE_DOWNLOAD_MODAL:
            return {
                ...state,
                showOptionsModal: false,
            };

        case GET_WORD_CLOUD_REQUEST:
            return {
                ...state,
                wordCloudLoading: true,
            };

        case GET_WORD_CLOUD_REQUEST_SUCCESS:
            return {
                ...state,
                wordCloudLoading: false,
                wordCloudArr: action.data,
                wordCloudReceived: true,
                wordCloudEmpty: false,
            };

        case GET_WORD_CLOUD_REQUEST_FAILURE:
            return {
                ...state,
                wordCloudLoading: false,
                wordCloudArr: [],
                wordCloudReceived: true,
                wordCloudEmpty: true,
            };

        case AUDIO_AVAILABLE:
            return {
                ...state,
                audioDataLoading: false,
                audioAvailable: true,
                audioData: action.data,
            };

        case MEETING_HIGHLIGHT_COMMENTS_LOADED:
            return {
                ...state,
                meetingCommentsLoaded: true
            }

        default:
            return state;
    }
}
