import {
    LOGIN_REQUEST,
    REGISTRATION_REQUEST,
    CHANGE_SETTINGS_WIDGET,
    CLOSE_TOAST,
    CLOSE_MEETING_CREATION_TOAST,
    FORGOT_PASSWORD_REQUEST,
    RESET_PASSWORD_REQUEST
} from "./types";

export const loginRequestAction = (loginEmail, loginPassword) => ({
    type: LOGIN_REQUEST,
    params: {loginEmail, loginPassword}
});

export const registrationRequestAction = (registrationPayloadObject) => ({
    type: REGISTRATION_REQUEST,
    params: {registrationPayloadObject}
});

export const handleSettingsWidgetAction = (choice) => ({
    type: CHANGE_SETTINGS_WIDGET,
    params: choice
});

export const closeToastAction = () => ({
    type: CLOSE_TOAST
});

export const closeMeetingCreationFailureToastAction = () => ({
    type: CLOSE_MEETING_CREATION_TOAST
});

export const forgotPasswordRequestAction = (forgotPasswordEmail) => ({
    type: FORGOT_PASSWORD_REQUEST,
    params: {forgotPasswordEmail}
});

export const resetPasswordAction = (token,password) => ({
    type: RESET_PASSWORD_REQUEST,
    params: {token, password}
});
