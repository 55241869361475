import React, {Component} from "react";
import {Modal, Spinner} from "react-bootstrap";

let scope;

class EditTranscriptModal extends Component {
    constructor(props) {
        super(props);
        scope = this;

        this.state = {
            modalInputValue: localStorage.getItem("editTranscriptData")
        }
    }

    update(e) {
        let name = e.target.name;
        let value = e.target.value;
        scope.setState({[name]: value});
    }

    updateTranscripts = () => {
        if (this.state.modalInputValue.trim() === this.props.editTranscript)
            this.props.closeEditTranscriptModal();

        if (this.props.type === "dashboard") {
            this.props.closeEditTranscriptModal();
            this.props.updateTranscript(localStorage.getItem("editMeetingID"),
                localStorage.getItem("editTranscriptID"),
                this.state.modalInputValue.trim());
            localStorage.removeItem("editMeetingID");
            localStorage.removeItem("editTranscriptID");
            localStorage.removeItem("editTranscriptData");
        }

        if (this.props.type === "live") {
            this.props.closeEditTranscriptModalLive();
            this.props.updateTranscriptLive(localStorage.getItem("editMeetingID"),
                localStorage.getItem("editTranscriptID"),
                this.state.modalInputValue.trim());
            localStorage.removeItem("editMeetingID");
            localStorage.removeItem("editTranscriptID");
            localStorage.removeItem("editTranscriptData");
        }

        this.props.flushContentResult();
    };

    render() {
        const {meeting} = this.props;

        return (
            <Modal show={true}
                   onHide={() => {
                       localStorage.removeItem("editMeetingID");
                       localStorage.removeItem("editTranscriptID");
                       localStorage.removeItem("editTranscriptData");

                       this.props.type === "dashboard" ?

                           this.props.closeEditTranscriptModal() :
                           this.props.closeEditTranscriptModalLive();
                   }}>
                <Modal.Header closeButton>

                    <Modal.Title>
                        Edit Content
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <textarea
                            className="edit-transcript-text-area"
                            value={this.state.modalInputValue}
                            name="modalInputValue"
                            onChange={this.update}
                        />
                </Modal.Body>
                <Modal.Footer>

                    <button
                        className={this.state.modalInputValue.trim() === "" ?
                            "modal-button save-changes disabled":
                            "modal-button save-changes"}

                        disabled={this.state.modalInputValue.trim() === ""}
                        onClick={() => this.updateTranscripts()}>
                        {!meeting.editTranscriptsLoading ?
                            "SAVE CHANGES" :
                            <Spinner
                                className="button-loading-spinner"
                                animation="border"
                                variant="light"/>
                        }
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default EditTranscriptModal;