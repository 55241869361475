import {connect} from "react-redux";
import InviteWidget from "../component/InviteWidget";
import {inviteToMeetingAction} from "../../../actions/meetingActions";

const mapStateToProps = (state) => ({
    auth: state.auth,
    meeting: state.meeting
});

const mapDispatchToProps = (dispatch) => ({
    inviteToMeeting: (userID, meetingAdmin, meetingAdminName, meetingName, meetingURL, meetingPasscode) => dispatch(inviteToMeetingAction(userID, meetingAdmin, meetingAdminName, meetingName, meetingURL, meetingPasscode))
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteWidget);