import {connect} from "react-redux";

import Sidebar from "../component/Sidebar";
import {openProfilePictureAction} from "../../../actions/authActions";

const mapStateToProps = (state) => ({
    auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
    openProfilePicture: () => dispatch(openProfilePictureAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);