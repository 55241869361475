import {
    GOOGLE_SYNC,
    OUTLOOK_SYNC,
    CALENDAR_SYNC_REQUEST,
    GOOGLE_SYNC_REGISTER,
    OUTLOOK_SYNC_REGISTER,
    CALENDAR_SYNC_REQUEST_REGISTER
} from "./types";

export const googleSyncAction = (syncPayload) => ({
    type: GOOGLE_SYNC,
    params: syncPayload
});

export const outlookSyncAction = (syncPayload) => ({
    type: OUTLOOK_SYNC,
    params: syncPayload
});

export const calendarSyncRequestAction = (data) => ({
    type: CALENDAR_SYNC_REQUEST,
    params: data
});

export const googleSyncRegisterAction = (syncPayload) => ({
    type: GOOGLE_SYNC_REGISTER,
    params: syncPayload
});

export const outlookSyncRegisterAction = (syncPayload) => ({
    type: OUTLOOK_SYNC_REGISTER,
    params: syncPayload
});

export const calendarSyncRequestRegisterAction = (data) => ({
    type: CALENDAR_SYNC_REQUEST_REGISTER,
    params: data
});

