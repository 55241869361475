import React, {Component} from "react";
import {Modal} from "react-bootstrap";

class DownloadModal extends Component {

    render() {
        const {type} = this.props;

        return (
            <Modal show={true}
                   onHide={() => this.props.closeOptionsModal()}>
                <Modal.Header closeButton>

                    <Modal.Title>
                        Meeting Options
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div>

                        <h5>
                            Download
                        </h5>

                        {type === "transcript" &&
                        <p style={{margin: "0"}}>
                            Download your meeting transcripts in PDF or DOC format
                        </p>
                        }

                        {type === "transcript" &&
                        <button
                            className="modal-button download"
                            onClick={() => this.props.triggerDownload("transcripts", "pdf")}
                        >
                            Download PDF
                        </button>
                        }

                        {type === "transcript" &&
                        <button
                            className="modal-button download"
                            onClick={() => this.props.triggerDownload("transcripts", "doc")}
                        >
                            Download DOC
                        </button>
                        }

                        {type === "highlights" &&
                        <p style={{margin: "1rem 0 0 0"}}>
                            Download your meeting highlights in PDF or DOC format
                        </p>
                        }

                        {type === "highlights" &&
                        <button
                            className="modal-button download"
                            onClick={() => this.props.triggerDownload("highlights", "pdf")}
                        >
                            Download PDF
                        </button>
                        }

                        {type === "highlights" &&
                        <button
                            className="modal-button download"
                            onClick={() => this.props.triggerDownload("highlights", "doc")}
                        >
                            Download DOC
                        </button>
                        }
                    </div>

                    <hr/>

                    <div>

                        <h5>
                            Delete Meeting
                        </h5>

                        <p>
                            <span style={{color: "red"}}>Danger Zone</span>: Once you delete a meeting, there is no
                            going back. Please be certain.
                        </p>

                        <button
                            className="delete-meeting-button"
                            onClick={() => this.props.deleteMeetingFromDashboard(localStorage.getItem("currentMeeting"))}
                        >
                            Delete
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default DownloadModal;