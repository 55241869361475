import {TOGGLE_DROPDOWN} from "../actions/types";

const initialState = {
    dropDownMenuVisible: false
};

export default function(state = initialState, action) {
    switch (action.type) {
        case TOGGLE_DROPDOWN:
            return {
                ...state,
                dropDownMenuVisible: !state.dropDownMenuVisible
            };
        default:
            return state;
    }
}