import { combineReducers } from 'redux';
import authReducer from './authReducer';
import dropDownReducer from './dropDownReducer';
import widgetReducer from "./widgetReducer";
import meetingReducer from "./meetingReducer";
import calendarSyncReducer from "./calendarSyncReducer";
import calendarReducer from "./calendarReducer";
import liveMeetingReducer from "./liveMeetingReducer";
import commentReducer from "./commentReducer";

export default combineReducers({
    auth: authReducer,
    dropDown: dropDownReducer,
    widget: widgetReducer,
    meeting: meetingReducer,
    liveMeeting: liveMeetingReducer,
    commentReducer: commentReducer,
    calendarSync: calendarSyncReducer,
    calendarReducer: calendarReducer
});