import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import Header from '../../components/Header/component/Header'
import Modal from "../../components/Modal/container/Modal";

class Logout extends Component {

    constructor(props) {

        super(props);
        this.state = {
            windowWidth: window.innerWidth
        }
    }

    handleResize = (e) => {
        this.setState({windowWidth: window.innerWidth});
    }


    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    render() {
        const {windowWidth} = this.state;

        return (
            <div className={windowWidth > 720 ? "login-outer": ""}>

                <Header/>

                <Modal
                    title={"Good bye"}
                    type={"logout"}
                />
            </div>
        );
    }

}


export default withRouter(Logout);
