import React, {Component} from 'react';
import {Spinner} from "react-bootstrap";
import queryString from 'query-string';

import FormInput from "../../FormInput/container/FormInput";
import skype from '../../../assets/Skype.png';
import webEx from '../../../assets/webex.png';
import hangouts from '../../../assets/Hangouts.png';
import goToMeeting from '../../../assets/Gotomeeting.png';
import blueJeans from '../../../assets/Bluejeans.png';
import whereBy from '../../../assets/Whereby.png';
import zoom from '../../../assets/ZoomLogo.png';
import other from '../../../assets/other.png';
import lifeSize from '../../../assets/lifesize.png';
import msTeams from '../../../assets/MSTeamsLogo.png';

import * as Constants from "../../Common";
import '../style/Modal.css';

// ************************************* //

// icsp => is calendar sync page
// gcsd => get current sync details
// g_suite => Google synced mail id
// outlook => Outlook synced mail id
// caud => current authenticated user details
// csr_target => calendar sync redirection target
// 100 => To registration page redirection
// 200 => To Settings page redirection

// ************************************* //

let registrationPayloadObject = {};
let conferencePlatforms = [];
let googleLink = "https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&prompt=consent&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&response_type=code&client_id=" + Constants.client_id_google + "&redirect_uri=" + Constants.client_redirectUri_google;
let outlookLink = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=" + Constants.client_id_outlook + "&response_type=code&redirect_uri=" + Constants.client_redirectUri_outlook + "&response_mode=query&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fcalendars.read%20https%3A%2F%2Fgraph.microsoft.com%2Fuser.read&state=1" // id can be user_id for passing as a reference to access after oauth completed
let scope;

class Modal extends Component {
    constructor() {
        super();
        scope = this;
        this.state = {
            loginEmail: "",
            loginEmailError: "",
            loginPassword: "",
            loginPasswordError: "",
            loginFormLoading: false,
            firstName: "",
            firstNameError: "",
            lastName: "",
            lastNameError: "",
            registerEmail: "",
            registerEmailError: "",
            registerPassword: "",
            registerPasswordError: "",
            registerPasswordRepeat: "",
            registerPasswordRepeatError: "",
            companyName: "",
            companyNameError: "",
            companySize: "",
            companySizeError: "",
            jobTitle: "",
            jobTitleError: "",
            department: "",
            departmentError: "",
            meetingType: "",
            meetingTypeError: "",
            meetingsPerWeek: "",
            meetingsPerWeekError: "",
            forgotPasswordEmail: "",
            forgotPasswordEmailError: "",
            resetPassword: "",
            resetPasswordConfirm: "",
            resetPasswordError: "",
            resetPasswordConfirmError: "",
            lifeSize: false,
            msTeams: false,
            zoom: false,
            other: false,
            blueJeans: false,
            goToMeeting: false,
            hangouts: false,
            webEx: false,
            skype: false,
            uber: false,
            whereBy: false,
            onHold: 0,
            noPlatformSelected: false,
            platformSelectionError: "",
            pageOne: true,
            pageTwo: false,
            pageThree: false,
            pageFour: false
        };
    }

    componentDidMount() {
        this.props.getUserCalendarInfoRegister();
        this.getSyncsRegister();
        let isSyncPage = localStorage.getItem("icsp");
        if (isSyncPage) {
            this.setState({
                pageOne: false,
                pageTwo: false,
                pageThree: false,
                pageFour: true
            });
        }
        //    console.log("props", this.props)
        if (isSyncPage && this.props && this.props.location && this.props.location.search) {
            //    console.log("his.props.location",this.props.location)
            let value = queryString.parse(this.props && this.props.location.search);
            let calenderTokenGenCode = value.code;  /*calander query string manage here*/
            let calenderState = value.state;
            if (!Constants.isEmpty(calenderTokenGenCode)) {
                let platform = 0;
                if (!Constants.isEmpty(calenderState)) { //outlook calender have state value 1
                    platform = 1;
                }
                if (platform === 0) {
                    let payload = {
                        googleCalenderConnected: false,
                        googleSyncLoading: true,
                        googleCalenderEmail: ''
                    };
                    this.props.googleSyncActionRegister(payload);
                } else {
                    let payload = {
                        outLookCalenderConnected: false,
                        outlookSyncLoading: true,
                        outlookCalenderEmail: ''
                    };
                    this.props.outlookSyncActionRegister(payload);
                }
                let user_details = localStorage.getItem("caud");
                if (user_details) {
                    let data = {
                        code: calenderTokenGenCode,
                        platform: platform,
                        userID: user_details.split(",")[0],
                        emailID: user_details.split(",")[1]
                    }
                    this.props.calendarSyncRequestActionRegister(data);
                }
            } else {
                this.clearLocalStorage();
            }
        } else {
            this.setState({
                pageOne: true,
                pageTwo: false,
                pageThree: false,
                pageFour: false
            });
            this.clearLocalStorage();
        }
        window.addEventListener("resize", this.handleResize);
    }

    getSyncsRegister = () => {
        let currentSync = localStorage.getItem("gcsd");
        if (currentSync && currentSync === "0") {
            let payload = {
                googleCalenderConnected: true,
                googleSyncLoading: false,
                googleCalenderEmail: localStorage.getItem("g_suite")
            };
            this.props.googleSyncActionRegister(payload);
        } else if (currentSync && currentSync === "1") {
            let payload = {
                outLookCalenderConnected: true,
                outlookSyncLoading: false,
                outlookCalenderEmail: localStorage.getItem("outlook")
            };
            this.props.outlookSyncActionRegister(payload);
        } else if (currentSync && currentSync === "2") {
            localStorage.removeItem("gcsd");
            let outlookPayload = {
                outLookCalenderConnected: true,
                outlookSyncLoading: false,
                outlookCalenderEmail: localStorage.getItem("outlook")
            }
            let gsuitePayload = {
                googleCalenderConnected: true,
                googleSyncLoading: false,
                googleCalenderEmail: localStorage.getItem("g_suite")
            }
            this.props.googleSyncActionRegister(gsuitePayload);
            this.props.outlookSyncActionRegister(outlookPayload);
        } else {
            localStorage.removeItem("gcsd");
        }
    }

    clearLocalStorage = () => {
        setTimeout(() => {
            localStorage.removeItem("icsp");
            localStorage.removeItem("g_suite");
            localStorage.removeItem("outlook");
            localStorage.removeItem("gcsd");
            localStorage.removeItem("caud");
        }, 2000);
    }

    loginModal() {
        const {auth, title} = this.props;

        return (
            <div className="modal-body-outer">

                <h3
                    className="modal-title">
                    {title}
                </h3>

                {!auth.loginFailed ?
                    <p className="login-modal-subtitle">
                        Log In to your Zoi Meet Account
                    </p>
                    :
                    <p className="login-modal-subtitle-failure-message">
                        {auth.responseMessage}
                    </p>
                }

                <div className="form-input-outer">

                    <p
                        className={this.state.loginEmailError.length > 0 ? "form-input-title-error" : "form-input-title"}
                    >
                        {this.state.loginEmailError.length > 0 ? this.state.loginEmailError : "Email"}
                    </p>

                    <input
                        className="form-input login-register"
                        type="email"
                        placeholder="Enter your Email"
                        name="loginEmail"
                        value={this.state.loginEmail}
                        onChange={this.update}
                    />
                </div>

                <div className="form-input-outer">

                    <p
                        className={this.state.loginPasswordError.length > 0 ? "form-input-title-error" : "form-input-title"}
                    >
                        {this.state.loginPasswordError.length > 0 ? this.state.loginPasswordError : "Password"}
                    </p>

                    <input
                        className="form-input login-register"
                        type="password"
                        placeholder="Enter your Password"
                        name="loginPassword"
                        value={this.state.loginPassword}
                        onChange={this.update}
                    />
                    <a href={Constants.forgot_url}
                       className="modal-forgot-password-link">
                        Forgot password?
                    </a>
                </div>

                <button
                    className="modal-button"
                    onClick={() => this.loginUser()}
                >
                    {!auth.loginLoading ?
                        "Log In" :
                        <Spinner
                            className="button-loading-spinner"
                            animation="border"
                            variant="light"/>
                    }
                </button>

                <p
                    className="login-modal-registration-link"
                >
                    Don't have an account? <a
                    className="modal-register-link"
                    href={Constants.register_url}>Register</a>
                </p>
            </div>
        );
    }

    registrationPage1() {
        return (
            <div className="modal-body-outer">

                <h3
                    className="modal-title">
                    Create an Account
                </h3>

                <div className="form-input-outer">

                    <p
                        className={this.state.firstNameError.length > 0 ? "form-input-title-error" : "form-input-title"}
                    >
                        {this.state.firstNameError.length > 0 ? this.state.firstNameError : "First Name *"}
                    </p>

                    <input
                        className="form-input login-register"
                        type="text"
                        placeholder="Enter your First Name"
                        name="firstName"
                        value={this.state.firstName}
                        onChange={this.update}
                    />
                </div>

                <div className="form-input-outer">

                    <p
                        className={this.state.lastNameError.length > 0 ? "form-input-title-error" : "form-input-title"}
                    >
                        {this.state.lastNameError.length > 0 ? this.state.lastNameError : "Last Name *"}
                    </p>

                    <input
                        className="form-input login-register"
                        type="text"
                        placeholder="Enter your Last Name"
                        name="lastName"
                        value={this.state.lastName}
                        onChange={this.update}
                    />
                </div>

                <div className="form-input-outer">

                    <p
                        className={this.state.registerEmailError.length > 0 ? "form-input-title-error" : "form-input-title"}
                    >
                        {this.state.registerEmailError.length > 0 ? this.state.registerEmailError : "Your Email *"}
                    </p>

                    <input
                        className="form-input login-register"
                        type="email"
                        placeholder="Enter your Email"
                        name="registerEmail"
                        value={this.state.registerEmail}
                        onChange={this.update}
                    />
                </div>

                <div className="form-input-outer">

                    <p
                        className={this.state.registerPasswordError.length > 0 ? "form-input-title-error" : "form-input-title"}
                    >
                        {this.state.registerPasswordError.length > 0 ? this.state.registerPasswordError : "Password *"}
                    </p>

                    <input
                        className="form-input login-register"
                        type="password"
                        placeholder="Enter your Password (8 or more characters)"
                        name="registerPassword"
                        value={this.state.registerPassword}
                        onChange={this.update}
                    />
                </div>

                <div className="form-input-outer">

                    <p
                        className={this.state.registerPasswordRepeatError.length > 0 ? "form-input-title-error" : "form-input-title"}
                    >
                        {this.state.registerPasswordRepeatError.length > 0 ? this.state.registerPasswordRepeatError : "Confirm Password *"}
                    </p>

                    <input
                        className="form-input login-register"
                        type="password"
                        placeholder="Confirm your Password"
                        name="registerPasswordRepeat"
                        value={this.state.registerPasswordRepeat}
                        onChange={this.update}
                    />
                </div>

                <p className="registration-tnc-hint">
                    By clicking "Next" you agree to the&nbsp;
                    <a href="https://www.zoimeet.com/termsandagreements/">
                        Terms and Conditions
                    </a>
                </p>

                <button
                    className="modal-button"
                    onClick={() => this.validatePageOneInput()}
                >
                    NEXT
                </button>

                <p
                    className="login-modal-registration-link"
                >
                    Already have an account? <a
                    className="modal-log-in-link"
                    href={Constants.login_url}>
                    Log In
                </a>
                </p>
            </div>
        );
    }

    validatePageOneInput = () => {
        if (this.state.registerEmail.trim() === "") this.setState({registerEmailError: "Please enter your Email"});
        if (!Constants.validEmail(this.state.registerEmail.trim())) this.setState({registerEmailError: "Please enter a valid Email"});
        if (this.state.registerPassword.trim() === "") this.setState({registerPasswordError: "Please enter a Password"});
        if (this.state.registerPassword.trim().length < 8) this.setState({registerPasswordError: "Password must be 8 or more characters"});
        if (this.state.firstName.trim() === "") this.setState({firstNameError: "Please enter your First Name"});
        if (this.state.lastName.trim() === "") this.setState({lastNameError: "Please enter your Last Name"});
        if ((this.state.registerPassword.trim() !== this.state.registerPasswordRepeat.trim()) ||
            (this.state.registerPassword.trim() === "" && this.state.registerPasswordRepeat.trim() === "")) this.setState({registerPasswordRepeatError: "Your Passwords do not match"});
        else if (this.pageOneEntriesValid() && this.passwordsValid()) {
            registrationPayloadObject.userName = this.state.registerEmail;
            registrationPayloadObject.email = this.state.registerEmail;
            registrationPayloadObject.password = this.state.registerPassword;
            registrationPayloadObject.firstName = this.state.firstName;
            registrationPayloadObject.lastName = this.state.lastName;
            this.setState({pageOne: false, pageTwo: true, pageThree: false, pageFour: false})
        }
    };

    pageOneEntriesValid = () => {
        return (this.state.registerEmail.length > 0 &&
            Constants.validEmail(this.state.registerEmail) &&
            this.state.firstName.length > 0 &&
            this.state.lastName.length > 0)
    };

    passwordsValid = () => {
        return (
            this.state.registerPassword.length > 7 &&
            this.state.registerPassword === this.state.registerPasswordRepeat
        )
    };

    registrationPage2() {
        return (
            <div className="modal-body-outer">

                <h3
                    className="modal-title">
                    Welcome {this.state.firstName}
                </h3>

                <div className="form-input-outer">

                    <p
                        className={this.state.companyNameError.length > 0 ? "form-input-title-error" : "form-input-title"}
                    >
                        {this.state.companyNameError.length > 0 ? this.state.companyNameError : "Company Name *"}
                    </p>

                    <input
                        className="form-input login-register"
                        type="text"
                        placeholder="Enter your Company Name"
                        name="companyName"
                        value={this.state.companyName}
                        onChange={this.update}
                    />
                </div>

                <div className="form-input-outer">

                    <p
                        className={this.state.companySizeError.length > 0 ? "form-input-title-error" : "form-input-title"}
                    >
                        {this.state.companySizeError.length > 0 ? this.state.companySizeError : "Company Size *"}
                    </p>

                    <select ref="companySize"
                            onChange={(e) => this.setState({companySize: e.target.value})}
                            className="form-input login-register">
                        <option value=""></option>
                        <option value="1-10">Below 10 people</option>
                        <option value="10-50">10-50</option>
                        <option value="50-250">50-250</option>
                        <option value="250-500">250-500</option>
                        <option value="500-1000">500-1000</option>
                        <option value="1000plus">Above 1000</option>
                    </select>
                </div>

                <div className="form-input-outer">

                    <p
                        className={this.state.jobTitleError.length > 0 ? "form-input-title-error" : "form-input-title"}
                    >
                        {this.state.jobTitleError.length > 0 ? this.state.jobTitleError : "Job Title *"}
                    </p>

                    <input
                        className="form-input login-register"
                        type="text"
                        placeholder="Enter your Job Title"
                        name="jobTitle"
                        value={this.state.jobTitle}
                        onChange={this.update}
                    />
                </div>

                <div className="form-input-outer">

                    <p
                        className={this.state.departmentError.length > 0 ? "form-input-title-error" : "form-input-title"}
                    >
                        {this.state.departmentError.length > 0 ? this.state.departmentError : "Department *"}
                    </p>

                    <input
                        className="form-input login-register"
                        type="text"
                        placeholder="Enter your Department"
                        name="department"
                        value={this.state.department}
                        onChange={this.update}
                    />
                </div>

                <div className="form-input-outer">

                    <p
                        className={this.state.meetingTypeError.length > 0 ? "form-input-title-error" : "form-input-title"}
                    >
                        {this.state.meetingTypeError.length > 0 ? this.state.meetingTypeError : "What type of meetings will Zoi Meet Support? *"}
                    </p>

                    <select ref="meetingType"
                            onChange={(e) => this.setState({meetingType: e.target.value})}
                            className="form-input login-register">
                        <option value=""></option>
                        <option value="Sales Meeting">Sales Meeting</option>
                        <option value="Customer Care Meetings">Customer Care Meetings</option>
                        <option value="Management Meetings">Management Meetings</option>
                        <option value="Team Meetings">Team Meetings</option>
                        <option value="Research Meetings">Research Meetings</option>
                        <option value="Other">Other</option>
                    </select>
                </div>

                <div className="form-input-outer">

                    <p
                        className={this.state.meetingsPerWeekError.length > 0 ? "form-input-title-error" : "form-input-title"}
                    >
                        {this.state.meetingsPerWeekError.length > 0 ? this.state.meetingsPerWeekError : "Number of meetings per week *"}
                    </p>

                    <select ref="numberOfMeetings"
                            onChange={(e) => this.setState({meetingsPerWeek: e.target.value})}
                            className="form-input login-register">
                        <option value=""></option>
                        <option value="Less than 5">Less than 5</option>
                        <option value="5-10">5-10</option>
                        <option value="10-20">10-20</option>
                        <option value="20-30">20-30</option>
                        <option value="30-40">30-40</option>
                        <option value="More than 50">More than 50</option>
                    </select>
                </div>

                <div className="register-button-div">
                    <button
                        className="modal-button"
                        onClick={() => this.setState({
                            pageOne: true,
                            pageTwo: false,
                            pageThree: false,
                            pageFour: false
                        })}
                    >
                        Previous
                    </button>
                    <button
                        className="modal-button"
                        onClick={() => this.validatePageTwoInput()}
                    >
                        Next
                    </button>
                </div>
            </div>
        );
    }

    validatePageTwoInput = () => {
        if (this.state.companyName.trim() === "") this.setState({companyNameError: "Please enter your Company Name"});
        if (this.state.companySize.trim() === "") this.setState({companySizeError: "Please select your Company Size"});
        if (this.state.jobTitle.trim() === "") this.setState({jobTitleError: "Please enter your Job Title"});
        if (this.state.department.trim() === "") this.setState({departmentError: "Please enter your Department"});
        if (this.state.meetingType.trim() === "") this.setState({meetingTypeError: "Please select the Type of Meeting"});
        if (this.state.meetingsPerWeek.trim() === "") this.setState({meetingsPerWeekError: "Please select the Number of Meetings"});
        else if (this.pageTwoEntriesValid()) {
            registrationPayloadObject.company = this.state.companyName;
            registrationPayloadObject.companySize = this.state.companySize;
            registrationPayloadObject.role = this.state.jobTitle;
            registrationPayloadObject.department = this.state.department;
            registrationPayloadObject.meetingTypes = this.state.meetingType;
            registrationPayloadObject.noOfMeetings = this.state.meetingsPerWeek;
            this.setState({pageOne: false, pageTwo: false, pageThree: true, pageFour: false});
        }
    };

    pageTwoEntriesValid = () => {
        return (this.state.companyName.trim().length > 0 &&
            this.state.companySize.trim().length > 0 &&
            this.state.jobTitle.trim().length > 0 &&
            this.state.department.trim().length > 0 &&
            this.state.meetingType.trim().length > 0 &&
            this.state.meetingsPerWeek.trim().length > 0)
    };

    registrationPage3() {
        const {auth} = this.props;

        return (
            <div className="modal-body-outer">

                <h3
                    className="modal-title">
                    Meeting Platforms
                </h3>

                {this.state.platformSelectionError.length > 0 ?
                    <p className="login-modal-subtitle" style={{color: 'red'}}>
                        {this.state.platformSelectionError}
                    </p>
                    :
                    <p className="login-modal-subtitle">
                        Please select all the solutions you use for your meetings.
                    </p>
                }

                <div style={{
                    display: "flex",
                    flexDirection: "row"
                }}>
                    <div
                        onClick={() => this.changePlatformSelection("lifesize")}
                        className={!this.state.lifeSize ? "conference-platform-btn" : "conference-platform-btn-selected"}
                    >

                        <img
                            alt="Lifesize"
                            className="conference-platform-img"
                            src={lifeSize}/>
                    </div>

                    <div
                        onClick={() => this.changePlatformSelection("msTeams")}
                        className={!this.state.msTeams ? "conference-platform-btn" : "conference-platform-btn-selected"}
                    >

                        <img
                            alt="MS Teams"
                            className="conference-platform-img"
                            src={msTeams}/>
                    </div>
                </div>

                <div style={{
                    display: "flex",
                    flexDirection: "row"
                }}>
                    <div
                        onClick={() => this.changePlatformSelection("hangouts")}
                        className={!this.state.hangouts ? "conference-platform-btn" : "conference-platform-btn-selected"}
                    >

                        <img
                            alt="Meet"
                            className="conference-platform-img"
                            src={hangouts}/>
                    </div>

                    <div
                        onClick={() => this.changePlatformSelection("zoom")}
                        className={!this.state.zoom ? "conference-platform-btn" : "conference-platform-btn-selected"}
                    >

                        <img
                            alt="Zoom"
                            className="conference-platform-img"
                            src={zoom}/>
                    </div>
                </div>

                <div style={{
                    display: "flex",
                    flexDirection: "row"
                }}>
                    <div
                        onClick={() => this.changePlatformSelection("goToMeeting")}
                        className={!this.state.goToMeeting ? "conference-platform-btn-go-to-meeting" : "conference-platform-btn-selected-go-to-meeting"}
                    >

                        <img
                            alt="Go To Meeting"
                            className="conference-platform-img go-to-meeting"
                            src={goToMeeting}/>
                    </div>

                    <div
                        onClick={() => this.changePlatformSelection("webEx")}
                        className={!this.state.webEx ? "conference-platform-btn-other" : "conference-platform-btn-selected-other"}
                    >

                        <img
                            alt="Cisco WebEx"
                            className="conference-platform-img other"
                            src={webEx}/>
                    </div>
                </div>

                <div style={{
                    display: "flex",
                    flexDirection: "row"
                }}>
                    <div
                        onClick={() => this.changePlatformSelection("skype")}
                        className={!this.state.skype ? "conference-platform-btn" : "conference-platform-btn-selected"}
                    >

                        <img
                            alt="Skype"
                            className="conference-platform-img"
                            src={skype}/>
                    </div>

                    <div
                        onClick={() => this.changePlatformSelection("blueJeans")}
                        className={!this.state.blueJeans ? "conference-platform-btn" : "conference-platform-btn-selected"}
                    >

                        <img
                            alt="Blue Jeans"
                            className="conference-platform-img"
                            src={blueJeans}/>
                    </div>
                </div>

                <div style={{
                    display: "flex",
                    flexDirection: "row"
                }}>
                    <div
                        onClick={() => this.changePlatformSelection("whereBy")}
                        className={!this.state.whereBy ? "conference-platform-btn" : "conference-platform-btn-selected"}
                    >

                        <img
                            alt="WhereBy"
                            className="conference-platform-img"
                            src={whereBy}/>
                    </div>

                    <div
                        onClick={() => this.changePlatformSelection("other")}
                        className={!this.state.other ? "conference-platform-btn" : "conference-platform-btn-selected"}
                    >

                        <img
                            alt="Other"
                            className="conference-platform-img"
                            src={other}/>
                    </div>
                </div>

                <div className="register-button-div page-three">
                    <button
                        className="modal-button"
                        onClick={() => this.setState({
                            pageOne: false,
                            pageTwo: true,
                            pageThree: false,
                            pageFour: false
                        })}
                    >
                        Previous
                    </button>
                    <button
                        className="modal-button"
                        onClick={() => this.validatePageThreeInput()}
                    >
                        {!auth.loginLoading ?
                            "Next" :
                            <Spinner
                                className="button-loading-spinner"
                                animation="border"
                                variant="light"/>
                        }
                    </button>
                </div>
            </div>
        );
    }

    changePlatformSelection = (platform) => {
        if (platform === "lifesize") {
            if (this.state.lifeSize) {
                this.setState({lifeSize: false});
                conferencePlatforms.splice(conferencePlatforms.indexOf("lifesize"), 1);
            } else {
                this.setState({lifeSize: true, noPlatformSelected: false, platformSelectionError: ''});
                conferencePlatforms.push("lifesize");
            }
        }

        if (platform === "zoom") {
            if (this.state.zoom) {
                this.setState({zoom: false});
                conferencePlatforms.splice(conferencePlatforms.indexOf("zoom"), 1);
            } else {
                this.setState({zoom: true, noPlatformSelected: false, platformSelectionError: ''});
                conferencePlatforms.push("zoom");
            }
        }

        if (platform === "msTeams") {
            if (this.state.msTeams) {
                this.setState({msTeams: false});
                conferencePlatforms.splice(conferencePlatforms.indexOf("msTeams"), 1);
            } else {
                this.setState({msTeams: true, noPlatformSelected: false, platformSelectionError: ''});
                conferencePlatforms.push("msTeams");
            }
        }

        if (platform === "hangouts") {
            if (this.state.hangouts) {
                this.setState({hangouts: false});
                conferencePlatforms.splice(conferencePlatforms.indexOf("hangouts"), 1);
            } else {
                this.setState({hangouts: true, noPlatformSelected: false, platformSelectionError: ''});
                conferencePlatforms.push("hangouts");
            }
        }

        if (platform === "goToMeeting") {
            if (this.state.goToMeeting) {
                this.setState({goToMeeting: false});
                conferencePlatforms.splice(conferencePlatforms.indexOf("goToMeeting"), 1);
            } else {
                this.setState({goToMeeting: true, noPlatformSelected: false, platformSelectionError: ''});
                conferencePlatforms.push("goToMeeting");
            }
        }

        if (platform === "webEx") {
            if (this.state.webEx) {
                this.setState({webEx: false});
                conferencePlatforms.splice(conferencePlatforms.indexOf("webEx"), 1);
            } else {
                this.setState({webEx: true, noPlatformSelected: false, platformSelectionError: ''});
                conferencePlatforms.push("webEx");
            }
        }

        if (platform === "skype") {
            if (this.state.skype) {
                this.setState({skype: false});
                conferencePlatforms.splice(conferencePlatforms.indexOf("skype"), 1);
            } else {
                this.setState({skype: true, noPlatformSelected: false, platformSelectionError: ''});
                conferencePlatforms.push("skype");
            }
        }

        if (platform === "blueJeans") {
            if (this.state.blueJeans) {
                this.setState({blueJeans: false});
                conferencePlatforms.splice(conferencePlatforms.indexOf("blueJeans"), 1);
            } else {
                this.setState({blueJeans: true, noPlatformSelected: false, platformSelectionError: ''});
                conferencePlatforms.push("blueJeans");
            }
        }

        if (platform === "whereBy") {
            if (this.state.whereBy) {
                this.setState({whereBy: false});
                conferencePlatforms.splice(conferencePlatforms.indexOf("whereBy"), 1);
            } else {
                this.setState({whereBy: true, noPlatformSelected: false, platformSelectionError: ''});
                conferencePlatforms.push("whereBy");
            }
        }

        if (platform === "other") {
            if (this.state.other) {
                this.setState({other: false});
                conferencePlatforms.splice(conferencePlatforms.indexOf("other"), 1);
            } else {
                this.setState({other: true, noPlatformSelected: false, platformSelectionError: ''});
                conferencePlatforms.push("other");
            }
        }
    }

    validatePageThreeInput = () => {
        if (conferencePlatforms.length === 0) {
            this.setState({
                noPlatformSelected: true,
                platformSelectionError: 'Please select at least one platform to proceed'
            });
        } else {

            if (this.state.lifeSize) {
                const stringData = conferencePlatforms.reduce((result, item) => {
                    return `${result} ${item},`
                }, "");

                registrationPayloadObject.preferredConferencePlatforms = stringData.trim().slice(0, -1);
                registrationPayloadObject.on_hold = 0;
                console.log(registrationPayloadObject);
                this.props.sendRegisterPayload(registrationPayloadObject);
                //setTimeout(() => window.location.href = (Constants.home_url), 3000);
            }

            if (!this.state.lifeSize) {
                const stringData = conferencePlatforms.reduce((result, item) => {
                    return `${result} ${item},`
                }, "");

                registrationPayloadObject.preferredConferencePlatforms = stringData.trim().slice(0, -1);
                registrationPayloadObject.on_hold = 1;
                console.log(registrationPayloadObject);
                this.props.sendRegisterPayload(registrationPayloadObject);
                setTimeout(() => window.location.href = (Constants.thank_you), 3000);
            }
        }

        if (this.state.lifeSize && !this.state.noPlatformSelected) {
            this.setState({
                pageOne: false,
                pageTwo: false,
                pageThree: false,
                pageFour: true
            });
            const stringData = conferencePlatforms.reduce((result, item) => {
                return `${result} ${item},`
            }, "");

            registrationPayloadObject.preferredConferencePlatforms = stringData.trim().slice(0, -1);
            registrationPayloadObject.on_hold = 0;
            //this.props.sendRegisterPayload(registrationPayloadObject);
        }

        if (this.state.noPlatformSelected) {
            this.setState({
                platformSelectionError: 'Please choose at least one platform to proceed'
            })
        } else {
            const stringData = conferencePlatforms.reduce((result, item) => {
                return `${result} ${item},`
            }, "");

            registrationPayloadObject.preferredConferencePlatforms = stringData.trim().slice(0, -1);
            registrationPayloadObject.on_hold = 1;

            //this.props.sendRegisterPayload(registrationPayloadObject);
        }
    };

    initiateCalendarSync = () => {
        localStorage.setItem("icsp", true);
        localStorage.setItem("csr_target", "100");
    }

    registrationPage4() {
        return (
            <div className="modal-body-outer">

                <h3
                    className="modal-title">
                    Connect Your Calendar
                </h3>

                <p className="login-modal-subtitle">
                    Zoi Meet will sync with meetings on your work calendar. Please connect at least one calendar to
                    continue.
                </p>
                <div className="calendar-button-outer">
                    <img
                        alt="Google Calendar"
                        style={{height: '2.4rem'}}
                        src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>

                    <div className="calendar-button-inner">

                        {this.props.calendars.googleCalendarConnected ?
                            <p>Google Calendar connected with {this.props.calendars.googleCalendarEmail}</p>
                            :
                            <a
                                href={googleLink}
                                onClick={() => this.initiateCalendarSync()}
                                style={{color: "#000", textDecoration: "none"}}
                            ><p>Connect your Google Calendar</p>
                            </a>
                        }
                    </div>
                </div>

                <div className="calendar-button-outer">
                    <img
                        alt="Outlook Calendar"
                        style={{height: '2.4rem'}}
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/df/Microsoft_Office_Outlook_%282018%E2%80%93present%29.svg/1024px-Microsoft_Office_Outlook_%282018%E2%80%93present%29.svg.png"/>

                    <div className="calendar-button-inner">
                        {
                            this.props.calendars.outlookCalendarConnected ?
                                <p>Outlook Calendar connected with {this.props.calendars.outlookCalendarEmail}</p> :
                                <a
                                    href={outlookLink}
                                    onClick={() => this.initiateCalendarSync()}
                                    style={{color: "#000", textDecoration: "none"}}
                                ><p>Connect your Outlook Calendar</p>
                                </a>
                        }
                    </div>
                </div>

                <div className="register-button-div">
                    <button
                        className="modal-button"
                        onClick={() => this.setState({
                            pageOne: false,
                            pageTwo: false,
                            pageThree: true,
                            pageFour: false
                        })}
                    >
                        Previous
                    </button>
                    <button
                        disabled={this.props.calendars.calendarsLoading}
                        className="modal-button"
                        // call clearLocalStorage to clear all localstorage items while redirecting to home page
                        onClick={() => this.validatePageFourInput()}
                    >
                        Start
                    </button>
                </div>
            </div>
        );
    }

    validatePageFourInput = () => {
        if (localStorage.getItem("authToken") !== null &&
            (this.props.calendars.outlookCalendarConnected ||
                this.props.calendars.googleCalendarConnected))
            window.location.href = (Constants.home_url);
    };

    forgotPasswordModal() {

        return (
            <div className="modal-body-outer">

                <h3
                    className="modal-title">
                    {this.props.title}
                </h3>

                <p className="forgot-password-modal-subtitle">
                    Please enter the email address associated with your Zoi Meet account.<br/>
                    We’ll send you an email with next steps and get you logged-in in no time.
                </p>

                <div className="form-input-outer">

                    <p
                        className={this.state.forgotPasswordEmailError.length > 0 ? "form-input-title-error" : "form-input-title"}
                    >
                        {this.state.forgotPasswordEmailError.length > 0 ? this.state.forgotPasswordEmailError : "Your Email"}
                    </p>

                    <input
                        className="form-input login-register"
                        type="email"
                        placeholder="Enter your Email"
                        name="forgotPasswordEmail"
                        value={this.state.forgotPasswordEmail}
                        onChange={this.update}
                    />
                </div>

                <button
                    className="modal-button"
                    onClick={() => this.forgotPassword()}
                >
                    {!this.props.auth.forgotPasswordRequestLoading ?
                        "Proceed"
                        :
                        <Spinner
                            className="button-loading-spinner"
                            animation="border"
                            variant="light"/>
                    }
                </button>

                <p
                    className="login-modal-registration-link"
                >
                    Go Back to <a
                    className="modal-log-in-link"
                    href={Constants.login_url}>
                    Log In
                </a>
                </p>
            </div>
        );
    }

    forgotPassword = () => {
        if (this.state.forgotPasswordEmail.length < 1) this.setState({forgotPasswordEmailError: 'Please Enter Your Email'});
        else this.props.resetPassword(this.state.forgotPasswordEmail);
    };

    thankYouModal() {
        return (
            <div className="modal-body-outer-thank-you">

                <h3
                    className="modal-title">
                    Thank You
                </h3>

                <p className="thank-you-modal-subtitle">
                    Congratulations! Your registration has been received by our team <br/>
                    and you are on now on the Zoi Meet access list. We are currently onboarding
                    new users selectively, which means we will contact you as soon as a new user spot opens
                    up.<br/> <br/>
                    A confirmation email will arrive in your inbox shortly (please check your spam too)
                    including a link to schedule a demo call with one of our team members to
                    demonstrate Zoi Meet and learn more about your goals.
                </p>

                <button className="modal-button-thank-you" onClick={() => window.location.href = (Constants.login_url)}>
                    Go to Homepage
                </button>
            </div>
        );
    }

    update = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        scope.setState({[name]: value});
        if (this.state.loginEmail.length > 0) this.setState({loginEmailError: ""});
        if (this.state.loginPassword.length > 0) this.setState({loginPasswordError: ""});
        if (this.state.registerEmail.length > 0) this.setState({registerEmailError: ""});
        if (this.state.registerPassword.length > 0) this.setState({registerPasswordError: ""});
        if (this.state.firstName.length > 0) this.setState({firstNameError: ""});
        if (this.state.lastName.length > 0) this.setState({lastNameError: ""});
        if (this.state.registerPasswordRepeat.length > 0) this.setState({registerPasswordRepeatError: ""});
        if (this.state.companyName.length > 0) this.setState({companyNameError: ""});
        if (this.state.jobTitle.length > 0) this.setState({jobTitleError: ""});
        if (this.state.department.length > 0) this.setState({departmentError: ""});
        if (this.state.companySize.length > 0) this.setState({companySizeError: ""});
        if (this.state.meetingType.length > 0) this.setState({meetingTypeError: ""});
        if (this.state.meetingsPerWeek.length > 0) this.setState({meetingsPerWeekError: ""});
        if (this.state.forgotPasswordEmail.length > 0) this.setState({forgotPasswordEmailError: ""});
        if (this.state.resetPassword.length > 0) this.setState({resetPasswordError: ""});
        if (this.state.resetPasswordConfirm.length > 0) this.setState({resetPasswordConfirmError: ""});
    };

    loginUser = () => {
        if (this.state.loginEmail === "") this.setState({loginEmailError: "Please enter your Email"});
        else if (!Constants.validEmail(this.state.loginEmail)) this.setState({loginEmailError: "Please enter a valid Email"});
        if (this.state.loginPassword === "") this.setState({loginPasswordError: "Please enter your Password"});

        if ((this.state.loginEmail.length > 0 && Constants.validEmail(this.state.loginEmail)) && this.state.loginPassword.length > 0) {
            this.props.sendLoginPayload(this.state.loginEmail, this.state.loginPassword);
        }
    };

    registerUser = () => {
        this.props.sendRegisterPayload(registrationPayloadObject);
    };

    resetPasswordModal() {

        return (
            <div className="modal-body-outer">

                <h3
                    className="modal-title">
                    {this.props.title}
                </h3>

                <p className="forgot-password-modal-subtitle">
                    Please enter a new password for your account.
                </p>

                <div className="form-input-outer">

                    <p
                        className={this.state.resetPasswordError.length > 0 ? "form-input-title-error" : "form-input-title"}
                    >
                        {this.state.resetPasswordError.length > 0 ? this.state.resetPasswordError : "New Password *"}
                    </p>

                    <input
                        className="form-input login-register"
                        type="password"
                        placeholder="Enter your Password (8 or more characters)"
                        name="resetPassword"
                        value={this.state.resetPassword}
                        onChange={this.update}
                    />
                </div>

                <div className="form-input-outer">

                    <p
                        className={this.state.resetPasswordConfirmError.length > 0 ? "form-input-title-error" : "form-input-title"}
                    >
                        {this.state.resetPasswordConfirmError.length > 0 ? this.state.resetPasswordConfirmError : "Confirm New Password *"}
                    </p>

                    <input
                        className="form-input login-register"
                        type="password"
                        placeholder="Confirm your Password"
                        name="resetPasswordConfirm"
                        value={this.state.resetPasswordConfirm}
                        onChange={this.update}
                    />
                </div>
                <button
                    className={(this.state.resetPassword.length < 8 || this.state.resetPasswordConfirm.length < 8) ? "modal-button disabled" : "modal-button"}
                    disabled={(this.state.resetPassword.length < 8 || this.state.resetPasswordConfirm.length < 8)}
                    onClick={() => this.resetUserPassword()}
                >
                    {!this.props.auth.forgotPasswordRequestLoading ?
                        "Proceed"
                        :
                        <Spinner
                            className="button-loading-spinner"
                            animation="border"
                            variant="light"/>
                    }
                </button>

                <p
                    className="login-modal-registration-link"
                >
                    Go Back to <a
                    className="modal-log-in-link"
                    href={Constants.login_url}>
                    Log In
                </a>
                </p>
            </div>
        );
    }

    resetUserPassword() {
        if (this.state.resetPassword !== this.state.resetPasswordConfirm)
            this.setState({resetPasswordConfirmError: "Passwords do not match"});

        if (this.state.resetPassword.trim() === this.state.resetPasswordConfirm.trim()) {
            const queryString = window.location.search;
            // console.log(queryString);
            const urlParams = new URLSearchParams(queryString);
            const token = urlParams.get('t');
            this.props.resetPasswordA(token, this.state.resetPassword.trim());
        }
    }

    render() {
        const {pageOne, pageTwo, pageThree, pageFour} = this.state;
        const {type} = this.props;
        return (
            <div>
                {type === "login" && this.loginModal()}
                {(type === "register" && pageOne) && this.registrationPage1()}
                {(type === "register" && pageTwo) && this.registrationPage2()}
                {(type === "register" && pageThree) && this.registrationPage3()}
                {(type === "register" && pageFour) && this.registrationPage4()}
                {(type === "thankyou") && this.thankYouModal()}
                {(type === "forgotPassword") && this.forgotPasswordModal()}
                {(type === "resetPassword") && this.resetPasswordModal()}
            </div>
        );
    }
}

export default Modal;