import {connect} from "react-redux";
import Tab from "../component/Tab";
import {handleActiveAction} from "../../../actions/meetingActions";

const mapStateToProps = (state) => ({
   widget: state.widget
});

const mapDispatchToProps = (dispatch) => ({
    handleActive: (activeTab) => dispatch(handleActiveAction(activeTab))
});

export default connect(mapStateToProps, mapDispatchToProps)(Tab);