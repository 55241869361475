import React from 'react';
import {
  VolumeUpOutlined,
  VolumeDownOutlined,
  VolumeOffOutlined,
} from '@material-ui/icons';

export default function Volume(props) {
  const { volume, setVolume } = props;
  return (
    <div style={{ display: 'flex' }}>
      <button
          className='controls__button'>
        {volume < 6 ? (
          volume < 1 ? (
            <VolumeOffOutlined
                onClick={() => {if (volume < 1) setVolume(9); else setVolume(0)}}
            />
          ) : (
            <VolumeDownOutlined
                onClick={() => {if (volume < 1) setVolume(9); else setVolume(0)}}
            />
          )
        ) : (
          <VolumeUpOutlined
              onClick={() => {if (volume < 1) setVolume(9); else setVolume(0)}}
          />
        )}
      </button>
      <input
        type='range'
        id='volume-bar'
        title='volume'
        min='0'
        max='10'
        step='1'
        value={volume}
        onChange={(e) => setVolume(e.target.value)}
      ></input>
    </div>
  );
}
