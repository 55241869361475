import React from 'react';
import { SystemUpdateAltOutlined } from '@material-ui/icons';

export default function Download() {
  return (
    <button className='controls__button'>
      <SystemUpdateAltOutlined />
    </button>
  );
}
