import React from 'react';
import {withRouter} from "react-router-dom";
import Header from '../../components/Header/container/Header';
import * as Constants from '../../components/Common';

import Modal from "../../components/Modal/container/Modal";

import './Register.css';

/** Handling current scope.*/

class Register extends React.Component {

    constructor() {
        super();
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            repeatPassword: '',
            tncCheckbox: false,
            tncChecked: false,
            showTncHint: false,
            firstNameError: '',
            lastNameError: '',
            emailError: '',
            passwordError: '',
            repeatPasswordError: '',
            companyName: '',
            companyNameError: '',
            companySize: '',
            jobTitle: '',
            jobTitleError: '',
            department: '',
            departmentError: '',
            meetingType: '',
            meetingTypeError: '',
            meetingsPerWeek: '',
            meetingsPerWeekError: '',
            responseMsg: '',
            googleCalenderConnected: false,
            outLookCalenderConnected: false,
            googleCalenderEmail: '',
            outlookCalenderEmail: '',
            lifesize: false,
            zoom: false,
            msteams: false,
            other: false,
            isZoomLiveEnabled: Constants.getZoomLiveEnabled(),
            windowWidth: window.innerWidth,
            page1Open: true,
            page2Open: false,
            page3Open: false,
            page4Open: false,
            googleSync_loading: false,
            outlookSync_loading: false
        };

       

        // this.handleRegisterClick = this.handleRegisterClick.bind(this);
    }

    handleResize = (e) => {
        this.setState({windowWidth: window.innerWidth})
    }
    componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    }
    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }


    /**
     * checking if already logged in user
     * if true the redirect to home directly without login credentails
     */
    checkOnceLoggedIn() {
        if (sessionStorage.getItem('status') != null) {
            //redirect to page
            this.props.history.push(Constants.login_url);
        } else {
            //show validation message}
        }
    }

    render() {

        const {windowWidth} = this.state;

        return (

            <div className={windowWidth > 720 ? "register-outer": ""}>

                <Header/>

                <Modal
                    type={"register"}
                    {...this.props}
                />
            </div>
        );
    }
}

export default withRouter(Register)