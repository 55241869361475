import React, {Component} from "react";
import Sidebar from "../../components/Sidebar/container/Sidebar";
import Header from "../../components/Header/container/Header";
import * as Constants from "../../components/Common";
import {checkSessionValidityAction} from "../../actions/authActions";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import SearchIcon from '@material-ui/icons/Search';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import GetAppIcon from '@material-ui/icons/GetApp';
import {connect} from "react-redux";
import {
    fetchFinishedTranscriptCommentsAction,
    flushContentResultAction,
    getAllMeetingDetailsAction,
    getArchivedMeetingsAction,
    getContentFromSearchAction,
    getMeetingDetailsAction, getMeetingHighlightCommentsAction,
    getWordCloudAction,
    openOptionsModalAction,
    triggerDownloadAction
} from "../../actions/meetingActions";

import Transcript from "../../components/Transcript/container/Transcript";
import DownloadModal from "../../components/DownloadModal/container/DownloadModal";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import WordCloud from "../../components/WordCloud";
import EditTranscriptModal from "../../components/EditTranscriptModal/container/EditTranscriptModal";

import ProfilePictureModal from "../../components/ProfilePictureModal/container/ProfilePictureModal";
import AudioPlayer from "../../components/AudioPlayer/components/AudioPlayer";

import "./Dashboard.css";

let categories = [
    "",
    "None",
    "Action",
    "Decision",
    "Note",
    "Reminder",
    "Question"
];

let timeLineClassNames = [
    "",
    "",
    "tags tag-success",
    "tags tag-danger",
    "tags tag-warning"
]

class Dashboard extends Component {
    constructor() {
        super();

        this.state = {
            windowWidth: window.innerWidth,
            elapsedTime: 0,
            searchTranscriptInput: "",
            playFile: true,
            transcripts: true,
            keywords: false,
        };

        this.update = this.update.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        const queryString = window.location.search;
        // console.log(queryString);
        const urlParams = new URLSearchParams(queryString);
        const id = urlParams.get('id');
        console.log(id);
        window.addEventListener("resize", this.handleResize);
        if (localStorage.getItem("authToken") !== null) {
            this.props.checkSessionValidity();
            this.props.getAllMeetingDetails(id);
            this.props.fetchFinishedTranscriptComments(id);
            if(!this.props.meeting.wordCloudReceived) this.props.getWordCloud();
        } else
            window.location.href = (Constants.login_url);
    }

    handleResize(e) {
        this.setState({windowWidth: window.innerWidth})
    };

    playAudioFile() {
        this.interval = setInterval(this.tick, 1000);
        const audioEl = document.getElementsByClassName("audio-element")[0];
        audioEl.play();
    }

    pauseAudioFile() {
        clearInterval(this.interval);
        const audioEl = document.getElementsByClassName("audio-element")[0];
        audioEl.pause();
    }

    tick = () => {
        this.setState({elapsedTime: this.state.elapsedTime + 1});
    };

    convertSecondstoTime = (seconds) => {

        let hours = Math.floor(seconds / 3600);
        let minutes = Math.floor((seconds - (hours * 3600)) / 60);
        let seconds2 = seconds - (hours * 3600) - (minutes * 60);

        let timeString = "";

        timeString = hours.toString().padStart(2, '0') + ':' +
            minutes.toString().padStart(2, '0') + ':' +
            seconds2.toString().padStart(2, '0');

        return timeString;
    };

    jumpToTime = (pinStartTime) => {
        console.log(pinStartTime);
    };

    update(e) {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({[name]: value});
        //if (this.state.searchTranscriptInput.length < 1) this.props.flushContentResult();
    };

    searchMeetingContent = () => {
        const queryString = window.location.search;
        // console.log(queryString);
        const urlParams = new URLSearchParams(queryString);
        const id = urlParams.get('id');
        if (this.state.searchTranscriptInput.trim() === "") this.props.flushContentResult();
        this.props.getContentFromSearch(id, this.state.searchTranscriptInput);
    };

    componentWillUpdate(prevProps, prevState, snapshot) {
        let highlightedIDs = [];
        if (!prevProps.meeting.meetingDataLoading && prevProps.meeting.archivedMeetingTranscripts.length > 0 && !prevProps.meeting.meetingCommentsLoaded) {

            let highlightedTranscripts = prevProps.meeting.archivedMeetingTranscripts;

            for (let i = 0; i < highlightedTranscripts.length; i++) {

                if (highlightedTranscripts[i]["category"] === 2 ||
                    highlightedTranscripts[i]["category"] === 3 ||
                    highlightedTranscripts[i]["category"] === 4)

                    highlightedIDs.push(highlightedTranscripts[i]["id"]);
            }
        }

        if(highlightedIDs.length > 0) this.props.getMeetingHighlightComments(highlightedIDs);
    }

    render() {
        const {auth, meeting} = this.props;
        const {windowWidth} = this.state;

        return (
            <div className="main-container">

                <Sidebar
                    page={"dashboard"}
                />

                <Header
                    page={"dashboard"}
                />

                {auth.showProfilePictureModal &&
                <ProfilePictureModal/>
                }

                {(meeting.showOptionsModal && meeting.downloadModalType === "transcript") &&
                <DownloadModal
                    type={"transcript"}
                />
                }

                {(meeting.showOptionsModal && meeting.downloadModalType === "highlights") &&
                <DownloadModal
                    type={"highlights"}
                />
                }

                {(meeting.showEditModal) &&
                <EditTranscriptModal
                    type={"dashboard"}
                />
                }

                <div className="main-container-body with-player w-100 px-3 pt-3 overflow-hidden">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-7 mb-4 mb-sm-4 mb-md-4 mb-lg-0">
                            <div className="card w-100 box-shadow border-0">
                                <div className="w-100 h-100 highlights-timeline pt-3">
                                    <div
                                        className="d-flex align-items-center justify-content-between mb-4 border-bottom pb-2 add-hightlight-block">
                                        <h2 className="heading-1 fw-b mb-0 f-12">Meeting Highlights</h2>
                                        <div className="d-flex">
                                            {
                                                false &&
                                                <button type="button"
                                                        className="btn btn-orange2 btn-xs w-140 me-3 f-11 ">Add
                                                    Highlight
                                                </button>
                                            }

                                            <OverlayTrigger
                                                placement={"bottom"}
                                                overlay={
                                                    <Tooltip style={{
                                                        fontSize: ".6rem",
                                                        fontFamily: "CeraPRO-Regular"
                                                    }}
                                                             id={`tooltip-bottom`}>
                                                        Highlights Download Options
                                                    </Tooltip>
                                                }
                                            >
                                                <CloudDownloadIcon
                                                    onClick={() => this.props.openOptionsModal("highlights")}
                                                    style={{width: "16px", cursor: "pointer"}}/>
                                            </OverlayTrigger>
                                        </div>
                                    </div>

                                    <div className="px-0 set-tabs-card-height">

                                        <div className="w-100" style={{overFlow: "auto"}}>
                                            {(!meeting.meetingDataLoading) &&
                                            meeting.archivedMeetingTranscripts.map((key, i) => {

                                                return (key["category"] !== null && key["category"] !== 1
                                                    && key["category"] !== 5
                                                    && key["category"] !== 6
                                                ) ?
                                                    <Transcript
                                                        searchTerm={""}
                                                        isSearch={false}
                                                        active={"highlights"}
                                                        category={key["category"]}
                                                        msgID={key["id"]}
                                                        createdTime={key["created_time"]}
                                                        startTime={key["start_time"]}
                                                        meetingID={key["fk_meeting"]}
                                                        speakerLabel={key["speaker_label"]}
                                                        data={key["data"]}
                                                    />
                                                    :
                                                    null
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-5 ">
                            <div className="card w-100 box-shadow border-0 ">
                                <div className="px-3">
                                    <ul className="nav meeting-tabs mb-3 " id="pills-tab" role="tablist">
                                        <li className="nav-item w-auto me-3" role="presentation">
                                            <a
                                                onClick={() => this.setState({transcripts: true, keywords: false})}
                                                className={this.state.transcripts ? "active" : ""} data-bs-toggle="pill"
                                                role="tab">
                                                <div
                                                    className="f-11 pb-0 pointer">
                                                    Transcript
                                                </div>
                                            </a>
                                        </li>
                                        <li className="nav-item w-auto" role="presentation">
                                            <a
                                                onClick={() => this.setState({transcripts: false, keywords: true})}
                                                className={this.state.keywords ? "active" : ""} data-bs-toggle="pill"
                                                role="tab">
                                                <div
                                                    className="f-11 pb-0 pointer">
                                                    Keywords
                                                </div>
                                            </a>
                                        </li>
                                        <li className="ms-auto">

                                            <OverlayTrigger
                                                placement={"bottom"}
                                                overlay={
                                                    <Tooltip style={{
                                                        fontSize: ".6rem",
                                                        fontFamily: "CeraPRO-Regular"
                                                    }}
                                                             id={`tooltip-bottom`}>
                                                        Transcript Download Options
                                                    </Tooltip>
                                                }
                                            >
                                                <button className="btn px-1 py-0 mt-3">
                                                    <MoreHorizIcon
                                                        onClick={() => this.props.openOptionsModal("transcript")}
                                                    />
                                                </button>
                                            </OverlayTrigger>
                                        </li>
                                    </ul>
                                </div>

                                <div className="tab-content" id="pills-tabContent">
                                    <div className="transcript-box tab-pane fade show active" id="pills-home"
                                         role="tabpanel"
                                         aria-labelledby="pills-home-tab">
                                        <div className="px-3 set-tabs-card-height">

                                            {this.state.transcripts &&
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                <div className="search-transcript-div input-group mb-3">

                                                    <span className="input-group-text bg-white border-end-0"
                                                    ><SearchIcon
                                                        style={{height: "1rem"}}
                                                    /></span>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        className="form-input-search-transcript f-12"
                                                        name="searchTranscriptInput"
                                                        value={this.state.searchTranscriptInput}
                                                        onKeyUp={() => this.searchMeetingContent()}
                                                        onChange={this.update}
                                                        placeholder="Search Transcript"
                                                    />
                                                </div>
                                            </div>
                                            }

                                            {this.state.transcripts &&
                                            <div className="w-100 px-0 px-md-4">
                                                {meeting.meetingContentSearchReturnedResultsFetched.length < 1 &&
                                                meeting.archivedMeetingTranscripts.map((key, i) =>

                                                    <Transcript
                                                        searchTerm={""}
                                                        isSearch={false}
                                                        active={"transcript"}
                                                        category={key["category"]}
                                                        msgID={key["id"]}
                                                        createdTime={key["created_time"]}
                                                        meetingID={key["fk_meeting"]}
                                                        speakerLabel={key["speaker_label"]}
                                                        data={key["data"]}
                                                    />
                                                )}

                                                {meeting.meetingContentSearchReturnedResultsFetched.length > 0 &&
                                                meeting.meetingContentSearchReturnedResultsFetched.map((key, i) =>

                                                    <Transcript
                                                        searchTerm={this.state.searchTranscriptInput.trim()}
                                                        isSearch={true}
                                                        active={"transcript"}
                                                        category={key["category"]}
                                                        msgID={key["msgId"]}
                                                        createdTime={key["created_time"]}
                                                        meetingID={key["fk_meeting"]}
                                                        speakerLabel={key["speaker_label"]}
                                                        data={key["data"]}
                                                    />
                                                )}
                                            </div>
                                            }
                                            {this.state.keywords &&
                                            (meeting.wordCloudReceived &&
                                                !meeting.wordCloudLoading &&
                                                meeting.wordCloudArr.length > 0 &&
                                                !meeting.wordCloudEmpty) &&
                                            <WordCloud
                                                words={meeting.wordCloudArr}
                                            />
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="player-main-block">

                                    <div className="label-tags w-100 position-relative bg-white">

                                        {meeting.archivedMeetingTranscripts.length > 0 &&
                                        <AudioPlayer
                                            highlights={meeting.archivedMeetingTranscripts}
                                        />
                                        }
                                        {false &&
                                            meeting.archivedMeetingTranscripts.map((key, i) => {
                                                return (
                                                    (key["category"] === 2 || key["category"] === 3 || key["category"] === 4) ?
                                                        <OverlayTrigger
                                                            placement={"top"}
                                                            overlay={
                                                                <Tooltip style={{
                                                                    fontSize: ".6rem",
                                                                    fontFamily: "CeraPRO-Regular"
                                                                }}
                                                                         id={`tooltip-top`}>
                                                                    {categories[key["category"]]
                                                                    + " at " +
                                                                    this.convertSecondstoTime((key["start_time"]))
                                                                    }
                                                                </Tooltip>
                                                            }
                                                        >
                                                            {windowWidth > 1024 ?
                                                                <div
                                                                    onClick={() => this.jumpToTime(key["start_time"])}
                                                                    className={timeLineClassNames[key["category"]]}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        left:
                                                                            `${(key["start_time"] * windowWidth /
                                                                                ((localStorage.getItem("currentMeetingDuration")) * 20))}%`
                                                                    }}
                                                                >
                                                                    <span/>
                                                                </div>
                                                                :
                                                                <div
                                                                    onClick={() => this.jumpToTime(key["start_time"])}
                                                                    className={timeLineClassNames[key["category"]]}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        left:
                                                                            `${(key["start_time"] * windowWidth /
                                                                                ((localStorage.getItem("currentMeetingDuration")) * 8))}%`
                                                                    }}
                                                                >
                                                                    <span/>
                                                                </div>

                                                            }
                                                        </OverlayTrigger>
                                                        : null
                                                )
                                            })
                                        }
                                        {false &&
                                        <div className="tags tag-success" style={{left: "10%"}}><span></span></div>}
                                        {false &&
                                        <div className="tags tag-danger" style={{left: "20%"}}><span></span></div>}
                                        {false &&
                                        <div className="tags tag-danger" style={{left: "30%"}}><span></span></div>}
                                        {false &&
                                        <div className="tags tag-warning" style={{left: "50%"}}><span></span></div>}
                                    </div>


                                    {false &&
                                    <div
                                        className="player-body w-100 align-items-center justify-content-between d-flex">
                                        <div>

                                            <OverlayTrigger
                                                placement={"top"}
                                                overlay={
                                                    <Tooltip style={{fontSize: ".6rem", fontFamily: "CeraPRO-Regular"}}
                                                             id={`tooltip-top`}>
                                                        Download Meeting Audio
                                                    </Tooltip>
                                                }
                                            >
                                                <GetAppIcon
                                                    onClick={() => this.props.triggerDownload("audio", "")}
                                                    style={{margin: "0", cursor: "pointer"}}
                                                />
                                            </OverlayTrigger>

                                            {!this.state.playFile &&
                                            <OverlayTrigger
                                                placement={"top"}
                                                overlay={
                                                    <Tooltip style={{fontSize: ".6rem", fontFamily: "CeraPRO-Regular"}}
                                                             id={`tooltip-top`}>
                                                        Pause Audio
                                                    </Tooltip>
                                                }
                                            >
                                                <PauseIcon
                                                    onClick={() => {
                                                        this.setState({playFile: true},
                                                            () => this.pauseAudioFile())
                                                    }}
                                                    style={{margin: "1.5rem", cursor: "pointer"}}
                                                />
                                            </OverlayTrigger>
                                            }

                                            {this.state.playFile &&
                                            <OverlayTrigger
                                                placement={"top"}
                                                overlay={
                                                    <Tooltip style={{fontSize: ".6rem", fontFamily: "CeraPRO-Regular"}}
                                                             id={`tooltip-top`}>
                                                        Play Audio
                                                    </Tooltip>
                                                }
                                            >
                                                <PlayArrowIcon
                                                    onClick={() => {
                                                        this.setState({playFile: false},
                                                            () => this.playAudioFile())
                                                    }}
                                                    style={{margin: "1.5rem", cursor: "pointer"}}
                                                />
                                            </OverlayTrigger>
                                            }

                                            <audio className="audio-element">
                                                <source src={localStorage.getItem("dashboardMeetingAudio")}/>
                                            </audio>

                                            <span

                                                className="time f-12">
                                                {this.convertSecondstoTime(this.state.elapsedTime)}
                                                &nbsp;/&nbsp;
                                                {this.convertSecondstoTime(localStorage.getItem("currentMeetingDuration"))}</span>
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    dropDown: state.dropDown,
    meeting: state.meeting
});

const mapDispatchToProps = (dispatch) => ({
    checkSessionValidity: () => dispatch(checkSessionValidityAction()),
    flushContentResult: () => dispatch(flushContentResultAction()),
    getAllMeetingDetails: (meetingID) => dispatch(getAllMeetingDetailsAction(meetingID)),
    getMeetingDetails: (meetingID) => dispatch(getMeetingDetailsAction(meetingID)),
    fetchFinishedTranscriptComments: (meetingID) => dispatch(fetchFinishedTranscriptCommentsAction(meetingID)),
    getArchivedMeetings: () => dispatch(getArchivedMeetingsAction()),
    openOptionsModal: (type) => dispatch(openOptionsModalAction(type)),
    triggerDownload: (type, format) => dispatch(triggerDownloadAction(type, format)),
    getWordCloud: () => dispatch(getWordCloudAction()),
    getContentFromSearch: (meetingID, meetingContent) => dispatch(getContentFromSearchAction(meetingID, meetingContent)),
    getMeetingHighlightComments:(highlightedIDs) => dispatch(getMeetingHighlightCommentsAction(highlightedIDs)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);