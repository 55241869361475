import {connect} from "react-redux";
import Transcript from "../component/Transcript";
import {
    fetchTranscriptCommentsAction, flushContentResultAction,
    handleCategoryAction,
    handleHighlightAction, openEditModalAction,
    saveCommentAction, saveHighlightCommentAction,
    transcriptHandleCategoryAction,
} from "../../../actions/meetingActions";

const mapStateToProps = (state) => ({
    widget: state.widget,
    commentReducer: state.commentReducer,
});

const mapDispatchToProps = (dispatch) => ({
    fetchTranscriptComments: (msgID) => dispatch(fetchTranscriptCommentsAction(msgID)),
    handleCategory: (category, msgID, meetingID) => dispatch(handleCategoryAction(category, msgID, meetingID)),
    transcriptHandleCategory: (category, msgID, meetingID) => dispatch(transcriptHandleCategoryAction(category, msgID, meetingID)),
    handleHighlight: (meetingID, msgID, highlight) => dispatch(handleHighlightAction(meetingID, msgID, highlight)),
    saveComment: (commentInput, msgID) => dispatch(saveCommentAction(commentInput, msgID)),
    saveHighlightComment: (commentInput, msgID) => dispatch(saveHighlightCommentAction(commentInput, msgID)),
    openEditModal: (transcriptID, meetingID, transcript) => dispatch(openEditModalAction(transcriptID, meetingID, transcript)),
    flushContentResult: () => dispatch(flushContentResultAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Transcript);