import React, {Component} from "react";
import {Modal} from "react-bootstrap";
import axios from 'axios';

class ProfilePictureModal extends Component {
    constructor() {
        super();

        this.state = {
            selectedFile: null
        };
    }

    upload() {
        let img = document.querySelector("#image");
        const authToken = 'Bearer ' + localStorage.getItem('authToken');
        let formData = new FormData();
        formData.append("profile", img.files[0]);
        //   console.log(formData)
        axios.post('https://utilities.staging.zoimeet.com/profile/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: authToken
            }
        }).then(d => {if(d.data.status) this.props.closeProfilePicture()}).catch(e => console.log(e))
    }

    onFileChange = (event) => {
        this.setState({
            selectedFile: event.target.files[0]
        });
    };

    onFileUpload = () => {

        const formData = new FormData();

        formData.append(
            "myFile",
            this.state.selectedFile,
        );

        console.log(formData);

        // Details of the uploaded file
        console.log(this.state.selectedFile);

        this.props.profilePictureUpload(formData);
    };


    render() {

        return (
            <Modal show={true}
                   onHide={() => {
                       this.props.closeProfilePicture()
                   }}>
                <Modal.Header closeButton>

                    <Modal.Title>

                        New Profile Picture
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body
                    style={{display: "flex", justifyContent: "center"}}
                >

                    <input id="image" type="file" accept="image/x-png,image/gif,image/jpeg" onChange={() => this.upload()}/>
                </Modal.Body>
            </Modal>
        );
    }
}

export default ProfilePictureModal;