import {connect} from "react-redux";
import DeleteModal from "../component/DeleteModal";
import {deleteAccountAction} from "../../../actions/authActions";
import {closeDeleteModalAction} from "../../../actions/meetingActions";

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
    deleteAccount: () => dispatch(deleteAccountAction()),
    closeDeleteModal: () => dispatch(closeDeleteModalAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal);