import React, {Component} from "react";
import Header from "../../components/Header/container/Header";
import Sidebar from "../../components/Sidebar/container/Sidebar";
import {connect} from "react-redux";
import * as Constants from "../../components/Common";
import TriggerActionItem from "../../components/TriggerActionItem";
import {checkSessionValidityAction} from "../../actions/authActions";
import {
    addTriggerAction,
    getTriggersAction,
    hideDuplicateTriggerAction,
    showDuplicateTriggerAction
} from "../../actions/meetingActions";

import Loader from "react-loader-spinner";

import ProfilePictureModal from "../../components/ProfilePictureModal/container/ProfilePictureModal";

import "./Triggers.css";
import {toast, ToastContainer} from "react-toastify";

let box_style = ["#19b5aa", "#fe5d49", "#faa236", "#b780b9", "#4c8797"];
let scope;

const notifyFailure = () =>
    toast.error('This trigger already exists in your collection', {
        position: "top-right",
        onClose: () => hideDuplicateTriggerAction(),
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

class Triggers extends Component {
    constructor() {
        super();
        scope = this;

        this.state = {
            dropDownMenuVisible: true,
            windowWidth: window.innerWidth,
            actionTriggerInput: '',
            decisionTriggerInput: '',
            notesTriggerInput: '',
        };
    }

    handleResize = (e) => {
        this.setState({windowWidth: window.innerWidth})
    };

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        if (localStorage.getItem("authToken") !== null) {
            this.props.checkSessionValidity();
            this.props.getTriggers();
        } else
            window.location.href = (Constants.login_url);
    }

    update = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        scope.setState({[name]: value});
        if (e.key === 'Enter') {
            console.log('value', e.target.value);
        }
    };

    render() {
        const {auth, meeting} = this.props;
        const {windowWidth} = this.state;

        if (meeting.showToast) notifyFailure();

        return (
            <div>

                <Sidebar
                    page={"trigger"}
                />

                <Header
                    page={"trigger"}
                />

                {auth.showProfilePictureModal &&
                <ProfilePictureModal/>
                }

                {meeting.showToast &&
                <ToastContainer
                    onClose={() => this.props.hideDuplicateTrigger()}
                    onClick={() => this.props.hideDuplicateTrigger()}
                    position="top-right"
                    hideProgressBar
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover/>
                }

                <div
                    className="main-container-body w-100 pt-2">

                    <div
                        className={windowWidth > 1024 ? "w-50 triggers-widget-outer row" : "triggers-widget-outer-mobile row mb-0"}>

                        <p className="triggers-widget-outer-title">
                            Zoi Meet listens to the following Highlight Triggers
                        </p>

                        <p className="triggers-widget-outer-subtitle">
                            For your convenience we compiled a collection of most common highlight triggers.<br/>
                            You can always add and remove your highlight triggers below.
                        </p>

                        {!meeting.triggersLoading && meeting.triggersReceived ?
                            <div
                                className="triggers-div"
                            >

                                <p
                                    className="trigger-type-title"
                                >
                                    Action
                                </p>

                                <div className="trigger-pill-div">
                                    {meeting.triggers.map((key, i) => (
                                            key["fk_category"] === 2 ?
                                                <TriggerActionItem
                                                    triggerID={key["id"]}
                                                    title={key["actiontrigger"]}
                                                    type={"action-btn green"}
                                                    bgColor={box_style[0]}
                                                />
                                                :
                                                null
                                        )
                                    )}

                                </div>

                                <input
                                    autoComplete="off"
                                    className="add-trigger-input action"
                                    type="text"
                                    placeholder="Add action trigger"
                                    name="actionTriggerInput"
                                    value={this.state.actionTriggerInput}
                                    onChange={this.update}
                                    onKeyPress={(ev) => {
                                        if (ev.key === 'Enter' && this.state.actionTriggerInput.trim().length > 0) {

                                            let index = meeting.triggers.filter(e => e.actiontrigger === this.state.actionTriggerInput.trim());
                                            if (index.length < 1) {
                                                this.props.addTrigger(2, this.state.actionTriggerInput.trim());
                                                this.setState({
                                                    actionTriggerInput: '',
                                                    decisionTriggerInput: '',
                                                    notesTriggerInput: '',
                                                });
                                            } else {
                                                this.props.showDuplicateTrigger()
                                            }
                                        }
                                    }
                                    }
                                />

                                <p
                                    className="trigger-type-title"
                                >
                                    Decision
                                </p>

                                <div>
                                    {meeting.triggers.map((key, i) => (
                                            key["fk_category"] === 3 ?
                                                <TriggerActionItem
                                                    triggerID={key["id"]}
                                                    title={key["actiontrigger"]}
                                                    type={"action-btn green"}
                                                    bgColor={box_style[1]}
                                                />
                                                :
                                                null
                                        )
                                    )}
                                </div>

                                <input
                                    autoComplete="off"
                                    className="add-trigger-input decision"
                                    type="text"
                                    placeholder="Add decision trigger"
                                    name="decisionTriggerInput"
                                    value={this.state.decisionTriggerInput}
                                    onChange={this.update}
                                    onKeyPress={(ev) => {
                                        if (ev.key === 'Enter' && this.state.decisionTriggerInput.trim().length > 0) {

                                            let index = meeting.triggers.filter(e => e.actiontrigger === this.state.decisionTriggerInput.trim());
                                            if (index.length < 1) {
                                                this.props.addTrigger(3, this.state.decisionTriggerInput.trim());
                                                this.setState({
                                                    actionTriggerInput: '',
                                                    decisionTriggerInput: '',
                                                    notesTriggerInput: '',
                                                });
                                            } else {
                                                this.props.showDuplicateTrigger()
                                            }
                                        }
                                    }
                                    }
                                />

                                <p
                                    className="trigger-type-title"
                                >
                                    Notes
                                </p>

                                <div>
                                    {meeting.triggers.map((key, i) => (
                                            key["fk_category"] === 4 ?
                                                <TriggerActionItem
                                                    triggerID={key["id"]}
                                                    title={key["actiontrigger"]}
                                                    type={"action-btn green"}
                                                    bgColor={box_style[2]}
                                                />
                                                :
                                                null
                                        )
                                    )}
                                </div>

                                <input
                                    autoComplete="off"
                                    className="add-trigger-input notes"
                                    type="text"
                                    placeholder="Add notes trigger"
                                    name="notesTriggerInput"
                                    value={this.state.notesTriggerInput}
                                    onChange={this.update}
                                    onKeyPress={(ev) => {
                                        if (ev.key === 'Enter' && this.state.notesTriggerInput.trim().length > 0) {

                                            let index = meeting.triggers.filter(e => e.actiontrigger === this.state.notesTriggerInput.trim());
                                            if (index.length < 1) {
                                                this.props.addTrigger(4, this.state.notesTriggerInput.trim());
                                                this.setState({
                                                    actionTriggerInput: '',
                                                    decisionTriggerInput: '',
                                                    notesTriggerInput: '',
                                                });
                                            } else {
                                                this.props.showDuplicateTrigger()
                                            }
                                        }
                                    }
                                    }
                                />
                            </div>
                            :
                            <Loader
                                style={{margin: "auto"}}
                                type="Oval"
                                color="#ccc"
                                height={40}
                                width={40}
                            />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    dropDown: state.dropDown,
    meeting: state.meeting
});

const mapDispatchToProps = (dispatch) => ({
    checkSessionValidity: () => dispatch(checkSessionValidityAction()),
    getTriggers: () => dispatch(getTriggersAction()),
    addTrigger: (triggerType, inputText) => dispatch(addTriggerAction(triggerType, inputText)),
    showDuplicateTrigger: () => dispatch(showDuplicateTriggerAction()),
    hideDuplicateTrigger: () => dispatch(hideDuplicateTriggerAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(Triggers);