import React, {Component} from 'react'
import {connect} from "react-redux";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import * as Constants from '../../components/Common';
import Header from "../../components/Header/container/Header";
import Sidebar from "../../components/Sidebar/container/Sidebar";
import InviteWidget from "../../components/InviteWidget/container/InviteWidget";
import ArchivesComponent from "../../components/ArchivesComponent/container/ArchivesComponent";
import UpcomingMeetingsWidget from "../../components/UpcomingMeetingsWidget/container/UpcomingMeetingsWidget";
import ProfilePictureModal from "../../components/ProfilePictureModal/container/ProfilePictureModal";
import {checkSessionValidityAction} from "../../actions/authActions";
import {getUserScheduledMeetingsAction} from "../../actions/userActions";

import "./Home.css";
import Loader from "react-loader-spinner";

const notifySuccess = () =>
    toast.success('Your meeting transcripts will show up shortly', {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

const notifyFailure = () =>
    toast.error('Something went wrong, please try again', {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

class Home extends Component {
    constructor() {
        super();

        this.state = {
            dropDownMenuVisible: true,
            windowWidth: window.innerWidth,
            toastHidden: true,
        }
    }

    handleResize = (e) => {
        this.setState({windowWidth: window.innerWidth})
    };

    handeClick = () => {
        if (!this.state.dropDownMenuVisible) {
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            dropDownMenuVisible: !prevState.dropDownMenuVisible
        }));
    };

    handleOutsideClick = (e) => {
        if (this.node.contains(e.target)) {
            return;
        }

        this.handeClick();
    };

    update(e) {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({[name]: value});
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        if (localStorage.getItem("authToken") !== null) {
            this.props.checkSessionValidity();
            this.props.getUserScheduledMeetings(1);
        } else
            window.location.href = (Constants.login_url);
    }

    render() {
        const {windowWidth} = this.state;
        const {meeting, auth} = this.props;

        if (!meeting.liveMeetingLoading && meeting.meetingCreated && meeting.showToast) notifySuccess();
        if (!meeting.liveMeetingLoading && !meeting.meetingCreated && meeting.showToast) notifyFailure();

        return (

            <div>

                <Sidebar
                    page={"home"}
                />

                <Header
                    firstName={auth.user.firstname}
                    page={"home"}
                />

                {auth.showProfilePictureModal &&
                <ProfilePictureModal/>
                }

                {meeting.showToast &&
                <ToastContainer
                    position="top-right"
                    autoClose={10000}
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover/>
                }

                <div
                    className={
                        windowWidth > 1024 ?
                            "main-container-body w-100 px-0 pt-2 overflow-hidden" :
                            "main-container-body w-100 px-0 pt-2"
                    }>
                    {!auth.userLoading ?
                        <div className={windowWidth > 1024 ? "main-content row" : "main-content-mobile row"}>

                            <div
                                className="col-12 col-sm-12 col-md-12 col-lg-7 mb-4 mb-sm-4 mb-md-4 mb-lg-0 pe-lg-2"
                            >

                                <InviteWidget/>

                                <ArchivesComponent/>

                            </div>

                            <UpcomingMeetingsWidget/>
                        </div>
                        :
                        <Loader
                            className="homepage-loader"
                            type="Oval"
                            color="#ccc"
                            height={40}
                            width={40}
                        />
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    dropDown: state.dropDown,
    meeting: state.meeting
});

const mapDispatchToProps = (dispatch) => ({
    checkSessionValidity: () => dispatch(checkSessionValidityAction()),
    getUserScheduledMeetings: (pageNo) => dispatch(getUserScheduledMeetingsAction(pageNo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);