import React from 'react';

let timeLineClassNames0 = [
    "",
    "",
    "bar__progress__highlight__2",
    "bar__progress__highlight__3",
    "bar__progress__highlight__4",
];

let timeLineClassNames1 = [
    "",
    "",
    "bar__progress__highlight__circle__2",
    "bar__progress__highlight__circle__3",
    "bar__progress__highlight__circle__4",
];

let timeLineClassNames2 = [
    "",
    "",
    "bar__progress__highlight__pin__2",
    "bar__progress__highlight__pin__3",
    "bar__progress__highlight__pin__4",
];

export default function HighlightBar(props) {
  const { highlight, duration, onHighlightClick, setPlaying } = props;

  const formatStartDuration = (highlight) => {
    return (highlight["start_time"] / localStorage.getItem("currentMeetingDuration")) * 100;
  };

  const pinClicked = (highlight) => {
    onHighlightClick(highlight["start_time"], highlight.duration);
    setPlaying(true);
  };

  return (
    <>
      <span
        className={timeLineClassNames0[highlight["category"]]}
        style={{
          left: `${formatStartDuration(highlight, duration)}%`,
          width: `1%`,
        }}
      />
      <span
          className={timeLineClassNames1[highlight["category"]]}
        style={{
          left: `calc(${formatStartDuration(highlight, duration)}%)`,
        }}
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => {
          pinClicked(highlight);
        }}
      />
      <span
          className={timeLineClassNames2[highlight["category"]]}
        style={{
          left: `calc(${formatStartDuration(highlight, duration)}%)`,
        }}
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => {
          pinClicked(highlight);
        }}
      />
    </>
  );
}
