import {
    CALENDARS_LOADING, GET_UPCOMING_USER_EVENTS, GET_UPCOMING_USER_EVENTS_FAILURE, GET_UPCOMING_USER_EVENTS_SUCCESS,
    GOOGLE_CALENDAR_SYNCED, NO_CALENDAR_SYNCED,
    OUTLOOK_CALENDAR_SYNCED
} from "../actions/types";

const initialState = {
    calendarsLoading: true,
    googleCalendarConnected: false,
    outlookCalendarConnected: false,
    googleCalendarEmail:'',
    outlookCalendarEmail: '',
    upcomingEventsLoading: true,
    hasUpcomingEvents: false,
    upcomingEvents: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CALENDARS_LOADING:
            return {
                ...state,
                calendarsLoading: true
            };

        case GOOGLE_CALENDAR_SYNCED:
            return {
                ...state,
                calendarsLoading: false,
                googleCalendarConnected: true,
                googleCalendarEmail: action.data
            };

        case OUTLOOK_CALENDAR_SYNCED:
            return {
                ...state,
                calendarsLoading: false,
                outlookCalendarConnected: true,
                outlookCalendarEmail: action.data
            };

        case GET_UPCOMING_USER_EVENTS:
            return {
                ...state,
                upcomingEventsLoading: true,
            };

        case GET_UPCOMING_USER_EVENTS_SUCCESS:
            return {
                ...state,
                upcomingEventsLoading: false,
                hasUpcomingEvents: true,
                upcomingEvents: action.data
            };

        case GET_UPCOMING_USER_EVENTS_FAILURE:
            return {
                ...state,
                upcomingEventsLoading: false,
                hasUpcomingEvents: false,
                upcomingEvents: []
            };

        case NO_CALENDAR_SYNCED:
            return {
                ...state,
                calendarsLoading: false
            };

        default:
            return state;
    }
}