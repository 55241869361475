import {
    GET_ALL_LIVE_MEETING_DETAILS_REQUEST, GET_ALL_LIVE_MEETING_DETAILS_REQUEST_SUCCESS,
    GET_LIVE_MEETING_DETAILS_REQUEST,
    GET_LIVE_MEETING_DETAILS_REQUEST_SUCCESS, HIDE_EDIT_MODAL_LIVE, LIVE_MESSAGE_CATEGORY_REQUEST_SUCCESS,
    MESSAGE_CATEGORY_REQUEST,
    MESSAGE_CATEGORY_REQUEST_SUCCESS,
    MESSAGE_HIGHLIGHT_REQUEST,
    MESSAGE_HIGHLIGHT_REQUEST_SUCCESS, SHOW_EDIT_MODAL_LIVE,
    STOP_TRIGGER,
} from "../actions/types";

const initialState = {
    liveMeetingLoading: false,
    liveMeetingTranscripts: [],
    updateTriggered: false,
    updatedObj: {},
    showEditModalLive: false,
    highlightRequest: false,
    noHighlights: true,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case  GET_LIVE_MEETING_DETAILS_REQUEST:
            return {
                ...state,
                liveMeetingLoading: true,
            };

        case GET_ALL_LIVE_MEETING_DETAILS_REQUEST:
            return {
                ...state,
                liveMeetingLoading: true,
            };

        case GET_ALL_LIVE_MEETING_DETAILS_REQUEST_SUCCESS:
            let highlightsTranscripts = action.data;
            let highlightsEmpty;
            for(let i=0; i<action.data.length; i++) {

                let twoCat = highlightsTranscripts.findIndex(e => e.category === 2);
                let threeCat = highlightsTranscripts.findIndex(e => e.category === 3);
                let fourCat = highlightsTranscripts.findIndex(e => e.category === 4);
                console.log(twoCat, threeCat, fourCat);
                highlightsEmpty = (twoCat < 0 && threeCat < 0 && fourCat < 0);
            }
            return {
                ...state,
                liveMeetingLoading: false,
                liveMeetingTranscripts: action.data,
                noHighlights: highlightsEmpty
            };

        case GET_LIVE_MEETING_DETAILS_REQUEST_SUCCESS:
            let tSC = state.liveMeetingTranscripts;

            for(let i=0; i<action.data.length; i++) {

                let indextSC = tSC.findIndex(e => e.id === action.data[i].id);
                if(indextSC === -1) {
                    console.log("not found, adding");
                    //console.log(action.data[i]);
                    tSC.push(action.data[i]);
                    console.log(tSC);
                }

                else if(indextSC > -1) {
                    console.log("found, replacing");
                    //console.log(action.data[i]);
                    tSC[indextSC] = action.data[i];
                    console.log(tSC);
                }
            }

            tSC.sort(function(a,b) {
               return a.start_time - b.start_time;
            });

            return {
                ...state,
                liveMeetingLoading: false,
                liveMeetingTranscripts: tSC
            };

        case SHOW_EDIT_MODAL_LIVE:
            return {
                ...state,
                showEditModalLive: true,
            };

        case HIDE_EDIT_MODAL_LIVE:
            return {
                ...state,
                showEditModalLive: false,
            }

        case MESSAGE_CATEGORY_REQUEST:
            return {
                ...state,
                liveMeetingLoading: true,
                updateTriggered: false,
                updatedObj: {}
            };

        case MESSAGE_CATEGORY_REQUEST_SUCCESS:
            let transcriptsShallowCopy = state.liveMeetingTranscripts;
            console.log(transcriptsShallowCopy);
            let index1 = transcriptsShallowCopy.findIndex(e => e.id === action.data.id);
            if(index1 === -1) {
                console.log("not found, adding");
                //console.log(action.data[i]);
                transcriptsShallowCopy.push(action.data);
                console.log(transcriptsShallowCopy);
            }

            else if(index1 > -1) {
                console.log("found, replacing");
                //console.log(action.data[i]);
                transcriptsShallowCopy[index1] = action.data;
                console.log(transcriptsShallowCopy);
            }
            return {
                ...state,
                liveMeetingLoading: false,
                updateTriggered: true,
                liveMeetingTranscripts: transcriptsShallowCopy
            };

            case LIVE_MESSAGE_CATEGORY_REQUEST_SUCCESS:
            let liveTranscriptsShallowCopy = state.liveMeetingTranscripts;
            console.log(liveTranscriptsShallowCopy);
            let liveTranscriptIndex = liveTranscriptsShallowCopy.findIndex(e => e.id === action.data.id);
            liveTranscriptsShallowCopy[liveTranscriptIndex] = action.data;
            return {
                ...state,
                liveMeetingLoading: false,
                updateTriggered: true,
                noHighlights: false,
                liveMeetingTranscripts: liveTranscriptsShallowCopy
            };

        case MESSAGE_HIGHLIGHT_REQUEST:
            return {
                ...state,
                highlightRequest: true,
                liveMeetingLoading: true,
                updateTriggered: false,
                updatedObj: {}
            };

        case MESSAGE_HIGHLIGHT_REQUEST_SUCCESS:
            let transcriptsShallowCopy2 = state.liveMeetingTranscripts;
            console.log(transcriptsShallowCopy2);
            let index = transcriptsShallowCopy2.findIndex(e => e.id === action.data.id);
            if(index === -1) {
                console.log("not found, adding");
                //console.log(action.data[i]);
                transcriptsShallowCopy2.push(action.data);
                console.log(transcriptsShallowCopy2);
            }

            else if(index > -1) {
                console.log("found, replacing");
                //console.log(action.data[i]);
                transcriptsShallowCopy2[index] = action.data;
                console.log(transcriptsShallowCopy2);
            }
            // let transcriptsShallowCopy2 = state.liveMeetingTranscripts;
            // console.log(transcriptsShallowCopy2);
            // let index = transcriptsShallowCopy2.findIndex(e => e.id === action.data.id);
            // if(index === -1) transcriptsShallowCopy2 = [...transcriptsShallowCopy2, action.data];
            // else transcriptsShallowCopy2[index] = action.data;
            // console.log(transcriptsShallowCopy2);
            return {
                ...state,
                highlightRequest: false,
                liveMeetingLoading: false,
                updateTriggered: true,
                noHighlights: false,
                updatedObj: action.data,
                liveMeetingTranscripts: transcriptsShallowCopy2
            };

        case STOP_TRIGGER:
            return {
                ...state,
                updateTriggered: false
            };

        default:
            return state;
    }
}