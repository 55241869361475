import {connect} from "react-redux";
import LiveTranscript from "../component/LiveTranscript";
import {
    fetchTranscriptCommentsAction,
    handleCategoryAction,
    handleHighlightAction,
    handleLiveCategoryAction,
    openEditModalLiveAction,
    saveCommentAction
} from "../../../actions/meetingActions";

const mapStateToProps = (state) => ({
    widget: state.widget,
    commentReducer: state.commentReducer,
});

const mapDispatchToProps = (dispatch) => ({
    fetchTranscriptComments: (msgID) => dispatch(fetchTranscriptCommentsAction(msgID)),
    handleLiveCategory: (category, msgID, meetingID) => dispatch(handleLiveCategoryAction(category, msgID, meetingID)),
    handleCategory: (category, msgID, meetingID) => dispatch(handleCategoryAction(category, msgID, meetingID)),
    handleHighlight: (meetingID, msgID, highlight) => dispatch(handleHighlightAction(meetingID, msgID, highlight)),
    saveComment: (commentInput, msgID) => dispatch(saveCommentAction(commentInput, msgID)),
    openEditModalLive: (transcriptID, meetingID, transcript) => dispatch(openEditModalLiveAction(transcriptID, meetingID, transcript)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveTranscript);