import React, {Component} from "react";
import dateFormat from 'dateformat';
import moment from 'moment';

import "../style/MeetingItem.css";

String.prototype.trunc = function (n) {
    return this.substr(0, n - 1) + (this.length > n ? '...' : '');
};

class MeetingItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            switchChecked: props.makeCall,
            seeMore: false,
            checkingMDT: this.props.meetingDateAndTime,
        }
    }

    render() {
        const {meetingID, meetingName, meetingDateAndTime} = this.props;
        const {switchChecked} = this.state;

        return (
            <div className="d-flex justify-content-between border-top py-3">
                <div className="d-flex">
                    <div><img src="images/google-icon.png" alt=""/></div>
                    <div className="ms-3">
                        <h3 className="f-11 fw-b mb-1">
                            {meetingName}
                        </h3>
                        <p className="f-9 mb-0">
                            {dateFormat(
                                moment(
                                    moment.utc(
                                        new Date(meetingDateAndTime).toString()
                                    )
                                ).add(2, 'minutes')
                                    .local(),
                                'dd mmm, HH:MM', true)
                            }
                        </p>
                    </div>
                </div>
                <div className="me-lg-5">
                    <label className="switch">

                        {switchChecked === 1 &&
                        <input
                            onClick={() => this.setState({switchChecked: 0}, () => this.props.changeMakeCall(meetingID, 0))}
                            type="checkbox" checked/>
                        }

                        {switchChecked === 0 &&
                        <input
                            onClick={() => this.setState({switchChecked: 1}, () => this.props.changeMakeCall(meetingID, 1))}
                            type="checkbox"/>
                        }

                        <span className="slider round"/>
                    </label>
                </div>
            </div>
        );
    }
}

export default MeetingItem;