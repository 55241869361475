import React from "react";

import * as Constants from "../../Common";
import queryString from 'query-string';
import Loader from "react-loader-spinner";

import "../style/SettingsWidget.css";

let googleLink = "https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&prompt=consent&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&response_type=code&client_id=" + Constants.client_id_google + "&redirect_uri=" + Constants.client_redirectUri_google;
let outlookLink = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=" + Constants.client_id_outlook + "&response_type=code&redirect_uri=" + Constants.client_redirectUri_outlook + "&response_mode=query&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fcalendars.read%20https%3A%2F%2Fgraph.microsoft.com%2Fuser.read&state=1" // id can be user_id for passing as a reference to access after oauth completed

class SettingsWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            callOpt: this.props.auth.user.call_option,
            settingsFirstName: this.props.auth.user.firstname !== null ? this.props.auth.user.firstname : "",
            settingsFirstNameError: '',
            settingsLastName: this.props.auth.user.lastname !== null ? this.props.auth.user.lastname : "",
            settingsLastNameError: '',
            settingsCompany: this.props.auth.user.company !== null ? this.props.auth.user.company : "",
            settingsCompanyError: '',
            settingsJobTitle: this.props.auth.user.role !== null ? this.props.auth.user.role : "",
            settingsJobTitleError: '',
            settingsPassword: '',
            settingsPasswordError: '',
            settingsNewPassword: '',
            settingsNewPasswordError: '',
            settingsNewPasswordConfirm: '',
            settingsNewPasswordConfirmError: '',
            googleCalendarEmail: '',
            googleCalendarSynced: false,
            outlookCalendarEmail: '',
            outlookCalendarSynced: false,
            switchChecked: false,
            windowWidth: window.innerWidth,
        };
    }

    handleResize = (e) => {
        this.setState({windowWidth: window.innerWidth})
    };

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        // Call get user calendar info api
        if (this.props.active === "calendar") {
            console.log(this.props.calendarCode);
            this.props.getUserCalendarInfo();
            this.getSyncs();
            if (this.props.calendarCode !== "") {
                let value = queryString.parse(this.props.calendarCode);
                let calenderTokenGenCode = value.code;  /*calander query string manage here*/
                let calenderState = value.state;
                if (!Constants.isEmpty(calenderTokenGenCode)) {
                    let platform = 0;
                    if (!Constants.isEmpty(calenderState)) { //outlook calender have state value 1
                        platform = 1;
                    }
                    if (platform === 0) {
                        let payload = {
                            googleCalenderConnected: false,
                            googleSyncLoading: true,
                            googleCalenderEmail: ''
                        };
                        this.props.googleSyncAction(payload);
                    } else {
                        let payload = {
                            outLookCalenderConnected: false,
                            outlookSyncLoading: true,
                            outlookCalenderEmail: ''
                        };
                        this.props.outlookSyncAction(payload);
                    }
                    //let user_details = localStorage.getItem("caud") || "1,abcd";
                    //if(user_details) {
                    let data = {
                        code: calenderTokenGenCode,
                        platform: platform,
                        userID: this.props.auth.user.id,
                        emailID: this.props.auth.user.username
                    };
                    //setTimeout(()=>{

                    this.props.calendarSyncRequestAction(data);
                    //localStorage.removeItem("calendarActive");
                    //}, 10000)
                    // }
                } else {
                    this.clearLocalStorage();
                }
            } else {
                this.clearLocalStorage();
            }
        }
        window.addEventListener("resize", this.handleResize);
    }

    getSyncs = () => {
        let currentSync = localStorage.getItem("gcsd");
        if (currentSync && currentSync === "0") {
            let payload = {
                googleCalenderConnected: true,
                googleSyncLoading: false,
                googleCalenderEmail: localStorage.getItem("g_suite")
            };
            this.props.googleSyncAction(payload);
        } else if (currentSync && currentSync === "1") {
            let payload = {
                outLookCalenderConnected: true,
                outlookSyncLoading: false,
                outlookCalenderEmail: localStorage.getItem("outlook")
            };
            this.props.outlookSyncAction(payload);
        } else if (currentSync && currentSync === "2") {
            localStorage.removeItem("gcsd");
            let outlookPayload = {
                outLookCalenderConnected: true,
                outlookSyncLoading: false,
                outlookCalenderEmail: localStorage.getItem("outlook")
            }
            let gsuitePayload = {
                googleCalenderConnected: true,
                googleSyncLoading: false,
                googleCalenderEmail: localStorage.getItem("g_suite")
            }
            this.props.googleSyncAction(gsuitePayload);
            this.props.outlookSyncAction(outlookPayload);
        } else {
            localStorage.removeItem("gcsd");
        }
    };

    clearLocalStorage = () => {
        setTimeout(() => {
            localStorage.removeItem("icsp");
            localStorage.removeItem("g_suite");
            localStorage.removeItem("outlook");
            localStorage.removeItem("gcsd");
            localStorage.removeItem("caud");
        }, 2000);
    };

    profileModal() {
        const {windowWidth} = this.state;

        return (
            <div className=
                     {windowWidth > 1024 ?
                         "card w-90 box-shadow border-0 mb-3 overflow-hidden" :
                         "card w-10 box-shadow border-0 mb-3 overflow-hidden"
                     }
            >
                <div className="p-3">
                    <h2 className="fw-b pb-4 f-14">Profile</h2>
                    <div className="row">
                        <div className="col-12 col-lg-9 col-lg-6">
                            <div className="row ps-4 mb-3">
                                <div className="col-12 col-lg-6">
                                    <div className="mb-2">
                                        <label
                                            htmlFor="exampleFormControlInput1"
                                            className={this.state.settingsFirstNameError.length > 0 ?
                                                "form-label f-11 fw-b color-red" :
                                                "form-label f-11 fw-b"
                                            }
                                        >
                                            {this.state.settingsFirstNameError.length > 0 ? this.state.settingsFirstNameError : "First Name"}
                                        </label
                                        >
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            className="form-control pt-0 f-11 border-start-0 border-top-0 border-end-0 rounded-0 ps-0"
                                            name="settingsFirstName"
                                            value={this.state.settingsFirstName}
                                            onChange={this.update}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="mb-2">
                                        <label
                                            htmlFor="exampleFormControlInput1"
                                            className={this.state.settingsLastNameError.length > 0 ?
                                                "form-label f-11 fw-b color-red" :
                                                "form-label f-11 fw-b"
                                            }
                                        >
                                            {this.state.settingsLastNameError.length > 0 ? this.state.settingsLastNameError : "Last Name"}
                                        </label
                                        >
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            className="form-control pt-0 f-11 border-start-0 border-top-0 border-end-0 rounded-0 ps-0"
                                            name="settingsLastName"
                                            value={this.state.settingsLastName}
                                            onChange={this.update}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row ps-4">
                                <div className="col-12 col-lg-6">
                                    <div className="mb-2">
                                        <label
                                            htmlFor="exampleFormControlInput1"
                                            className={this.state.settingsCompanyError.length > 0 ?
                                                "form-label f-11 fw-b color-red" :
                                                "form-label f-11 fw-b"
                                            }
                                        >
                                            {this.state.settingsCompanyError.length > 0 ? this.state.settingsCompanyError : "Company"}
                                        </label
                                        >
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            className="form-control pt-0 f-11 border-start-0 border-top-0 border-end-0 rounded-0 ps-0"
                                            name="settingsCompany"
                                            value={this.state.settingsCompany}
                                            onChange={this.update}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="mb-2">
                                        <label
                                            htmlFor="exampleFormControlInput1"
                                            className={this.state.settingsJobTitleError.length > 0 ?
                                                "form-label f-11 fw-b color-red" :
                                                "form-label f-11 fw-b"
                                            }
                                        >{this.state.settingsJobTitleError.length > 0 ? this.state.settingsJobTitleError : "Job Title"}
                                        </label>
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            className="form-control pt-0 f-11 border-start-0 border-top-0 border-end-0 rounded-0 ps-0"
                                            name="settingsJobTitle"
                                            value={this.state.settingsJobTitle}
                                            onChange={this.update}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-2 ps-4 col-12 col-md-6">
                                <button
                                    onClick={() => this.updateUserInfo()}
                                    disabled={this.props.auth.userInfoUpdating}
                                    className="btn btn-info w-100 f-12 btn-md-h fw-b rounded-1 mt-4"
                                >
                                    <span className="px-3">Save Changes</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    updateUserInfo = () => {
        if (this.state.settingsFirstName === "") this.setState({settingsFirstNameError: "Please Enter Your First Name"});
        if (this.state.settingsLastName === "") this.setState({settingsLastNameError: "Please Enter Your Last Name"});
        if (this.state.settingsCompany === "") this.setState({settingsCompanyError: "Please Enter Your Company Name"});
        if (this.state.settingsJobTitle === "") this.setState({settingsJobTitleError: "Please Enter Your Job Title"});
        if (this.state.settingsFirstName.length > 0 &&
            this.state.settingsLastName.length > 0 &&
            this.state.settingsCompany.length > 0 &&
            this.state.settingsJobTitle.length > 0)
            this.props.updateUser(
                this.props.auth.user.username,
                this.state.settingsFirstName,
                this.state.settingsLastName,
                this.state.settingsCompany,
                this.state.settingsJobTitle);
    };

    passwordModal() {
        const {windowWidth} = this.state;

        return (
            <div className={
                windowWidth > 1024 ?
                    "card w-90 box-shadow border-0 mb-3 overflow-hidden" :
                    "card w-100 box-shadow border-0 mb-3 overflow-hidden"
            }>
                <div className="p-3">
                    <h2 className="fw-b pb-4 f-14">Security</h2>
                    <div className="row ps-4">
                        <div className="col-12 col-lg-9 col-lg-6">
                            <div className="mb-1">
                                <label
                                    htmlFor="exampleFormControlInput1"
                                    className={this.state.settingsPasswordError.length > 0 ?
                                        "form-label f-11 fw-b color-red" :
                                        "form-label f-11 fw-b"
                                    }
                                >
                                    {this.state.settingsPasswordError.length > 0 ? this.state.settingsPasswordError : "Current Password"}</label
                                >
                                <input
                                    type="password"
                                    className="form-control pt-0 f-11 border-start-0 border-top-0 border-end-0 rounded-0 ps-0"
                                    id="exampleFormControlInput1"
                                    name="settingsPassword"
                                    value={this.state.settingsPassword}
                                    onChange={this.update}
                                    placeholder="Enter current password"
                                />
                            </div>
                            <div className="mb-1">
                                <label
                                    htmlFor="exampleFormControlInput1"
                                    className={this.state.settingsNewPasswordError.length > 0 ?
                                        "form-label f-11 fw-b color-red" :
                                        "form-label f-11 fw-b"
                                    }
                                >
                                    {this.state.settingsNewPasswordError.length > 0 ? this.state.settingsNewPasswordError : "New Password (8 characters or more)"}
                                </label
                                >
                                <input
                                    type="password"
                                    className="form-control pt-0 f-11 border-start-0 border-top-0 border-end-0 rounded-0 ps-0"
                                    id="exampleFormControlInput1"
                                    name="settingsNewPassword"
                                    value={this.state.settingsNewPassword}
                                    onChange={this.update}
                                    placeholder="Enter new password"
                                />
                            </div>
                            <div className="mb-1">
                                <label
                                    htmlFor="exampleFormControlInput1"
                                    className={this.state.settingsNewPasswordConfirmError.length > 0 ?
                                        "form-label f-11 fw-b color-red" :
                                        "form-label f-11 fw-b"
                                    }
                                >
                                    {this.state.settingsNewPasswordConfirmError.length > 0 ? this.state.settingsNewPasswordConfirmError : "Confirm New Password"}
                                </label
                                >
                                <input
                                    type="password"
                                    className="form-control pt-0 f-11 border-start-0 border-top-0 border-end-0 rounded-0 ps-0"
                                    id="exampleFormControlInput1"
                                    name="settingsNewPasswordConfirm"
                                    value={this.state.settingsNewPasswordConfirm}
                                    onChange={this.update}
                                    placeholder="Confirm new password"
                                />
                            </div>
                            <div className="mb-2 col-12 col-md-6 pe-md-2">
                                <button
                                    disabled={(this.state.settingsPassword.length < 8 ||
                                        this.state.settingsNewPassword.length < 8 ||
                                        this.state.settingsNewPasswordConfirm.length < 8
                                    )}
                                    onClick={() => this.updateUserPassword()}
                                    className=
                                        {(this.state.settingsPassword.length < 8 ||
                                            this.state.settingsNewPassword.length < 8 ||
                                            this.state.settingsNewPasswordConfirm.length < 8
                                        ) ?
                                            "btn btn-info w-100 f-12 btn-md-h fw-b rounded-1 mt-3 save-changes-disabled" :
                                            "btn btn-info w-100 f-12 btn-md-h fw-b rounded-1 mt-3"
                                        }
                                >
                                    <span className="px-3">Save Changes</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    validatePasswords = () => {
        return !(this.state.settingsPassword.length < 8 ||
            this.state.settingsNewPassword.length < 8 ||
            this.state.settingsNewPasswordConfirm.length < 8 ||
            this.state.settingsNewPasswordConfirm !== this.state.settingsNewPassword);
    };

    updateUserPassword = () => {
        if (this.state.settingsPassword === "") this.setState({settingsPasswordError: "Please enter your Current Password"});
        if (this.state.settingsNewPassword === "") this.setState({settingsNewPasswordError: "Please enter your New Password"});
        if (this.state.settingsNewPasswordConfirm === "") this.setState({settingsNewPasswordConfirmError: "Please confirm your New Password"});
        if (this.state.settingsNewPassword < 8) this.setState({settingsNewPasswordError: "Password should be at least 8 characters"});
        if (this.state.settingsNewPasswordConfirm < 8) this.setState({settingsNewPasswordConfirmError: "New Password should be at least 8 characters"});
        if (this.state.settingsNewPasswordConfirm !== this.state.settingsNewPassword) this.setState({settingsNewPasswordConfirmError: "Passwords do not match"});

        //console.log(this.validatePasswords);

        let valid = this.validatePasswords();

        console.log(valid);

        if (valid) {
            this.props.updatePassword(
                this.props.auth.user.username,
                this.state.settingsPassword,
                this.state.settingsNewPassword);

            this.setState({
                settingsPasswordError: '',
                settingsNewPasswordError: '',
                settingsNewPasswordConfirmError: ''
            })
        }
    };

    calendarWidget() {
        const {calendars} = this.props;
        const {windowWidth} = this.state;

        return (
            <div>
                <div className={
                    windowWidth > 1024 ?
                        "card w-90 box-shadow border-0 mb-3 overflow-hidden" :
                        "card w-100 box-shadow border-0 mb-3 overflow-hidden"
                }>
                    <div className="p-4">
                        <h2 className="fw-b mb-3 f-14">Calender Connections</h2>
                        <p className="f-11 mb-1">
                            At least one calendar connection is required in order for your AI
                            Assistant to join your meetings automatically.
                        </p>
                        <p className="f-9 mb-0">
                                    <span className="fw-b">
                                        Disclaimer:
                                    </span>
                            Zoi Meet does not capture or store
                            personal information when syncing with your google account or outlook account
                        </p>
                        <div className="row">
                            <div className="col-12 col-lg-12">

                                <button
                                    className="btn btn-connect me-3 f-12 d-flex pt-4 text-start rounded-1 mt-4 calender-connecion float-start mb-5 mb-md-3 mb-lg-2"
                                >
                                    <img
                                        src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                                        alt=""
                                        style={{height: "2rem", margin: "1rem"}}
                                        className="mt-n1 me-4"
                                    />

                                    {
                                        (calendars.calendarsLoading) &&
                                        <Loader
                                            type="ThreeDots"
                                            color="#000"
                                            height={40}
                                            width={40}
                                        />}

                                    {(!calendars.calendarsLoading && calendars.googleCalendarConnected) &&
                                    <p>Google Calendar connected with {calendars.googleCalendarEmail}</p>
                                    }

                                    {(!calendars.calendarsLoading && !calendars.googleCalendarConnected) &&
                                    <a
                                        href={googleLink}
                                        onClick={() => {
                                            localStorage.setItem("csr_target", "200");
                                        }}
                                        style={{color: "#000", textDecoration: "none"}}
                                    ><p>Connect your Google Calendar</p>
                                    </a>
                                    }
                                </button>

                                <button
                                    className="btn btn-connect f-12 d-flex pt-4 text-start rounded-1 mt-4 calender-connecion"
                                >
                                    <img
                                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/df/Microsoft_Office_Outlook_%282018%E2%80%93present%29.svg/1024px-Microsoft_Office_Outlook_%282018%E2%80%93present%29.svg.png"
                                        alt=""
                                        style={{height: "2rem", margin: "1rem"}}
                                        className="mt-n1 me-4"
                                    />

                                    {
                                        (calendars.calendarsLoading) &&
                                        <Loader
                                            type="ThreeDots"
                                            color="#000"
                                            height={40}
                                            width={40}
                                        />}
                                    {(calendars.outlookCalendarConnected && !calendars.calendarsLoading) &&
                                    <p>Outlook Calendar connected with {calendars.outlookCalendarEmail}</p>
                                    }

                                    {(!calendars.calendarsLoading && !calendars.outlookCalendarConnected) &&
                                    <a
                                        href={outlookLink}
                                        onClick={() => {
                                            localStorage.setItem("csr_target", "200");
                                        }}
                                        style={{color: "#000", textDecoration: "none"}}
                                    ><p>Connect your Outlook Calendar</p>
                                    </a>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={
                    windowWidth > 1024 ?
                        "card w-90 box-shadow border-0 mb-3 overflow-hidden" :
                        "card w-100 box-shadow border-0 mb-3 overflow-hidden"
                }>
                    <div className="p-4">
                        <h2 className="fw-b pb-3 f-14">Calender Settings</h2>
                        <div className="row">
                            <div className="col-12 col-md-10 col-lg-8">
                                <section className="w-100 d-inline-block">
                                    <div
                                        className="d-flex justify-content-between border-top border-bottom py-3"
                                    >
                                        <div className="d-flex">
                                            <div className="ms-0">
                                                <h3 className="f-12 fw-b mb-1">
                                                    Join all meetings automatically
                                                </h3>
                                                <p className="f-10 mb-0">
                                                    Your AI Assistant will join all meetings scheduled
                                                    on your connected calendar
                                                </p>
                                            </div>
                                        </div>

                                        {this.state.callOpt === 1 ?
                                            <div className="me-lg-5">
                                                <label className="switch">
                                                    <input onClick={() => {
                                                        this.setState(
                                                            {callOpt: 0},
                                                            () => this.props.updateDefaultCallOpt(0)
                                                        )
                                                    }}
                                                           type="checkbox" checked/>
                                                    <span className="slider round"/>
                                                </label>
                                            </div> :
                                            <div className="me-lg-5">
                                                <label className="switch">
                                                    <input
                                                        onClick={() => {
                                                            this.setState(
                                                                {callOpt: 1},
                                                                () => this.props.updateDefaultCallOpt(1)
                                                            )
                                                        }}
                                                        type="checkbox"/>
                                                    <span className="slider round"/>
                                                </label>
                                            </div>
                                        }
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    update = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({[name]: value});

        if (this.state.settingsFirstName.length > 0) this.setState({settingsFirstNameError: ''});
        if (this.state.settingsLastName.length > 0) this.setState({settingsLastNameError: ''});
        if (this.state.settingsCompany.length > 0) this.setState({settingsCompanyError: ''});
        if (this.state.settingsJobTitle.length > 0) this.setState({settingsJobTitleError: ''});
        // if (this.state.settingsPassword.length > 0) this.setState({settingsPasswordError: ''});
        // if (this.state.settingsNewPassword.length > 0) this.setState({settingsNewPasswordError: ''});
        // if (this.state.settingsNewPasswordConfirm.length > 0) this.setState({settingsNewPasswordConfirmError: ''});
    };

    render() {
        const {active} = this.props;

        return (

            <div>
                {(active === "profile")
                &&
                this.profileModal()
                }

                {(active === "password")
                &&
                this.passwordModal()
                }

                {(active === "calendar")
                &&
                this.calendarWidget()
                }
            </div>
        );
    }
}

export default SettingsWidget;
