import React, {Component} from 'react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import moment from 'moment';

import Bar from './Bar';
import Play from './Play';
import Pause from './Pause';
import Duration from './Duration';
import Volume from './Volume';
import Highlights from './Highlights';

import {connect} from "react-redux";

import "../styles.scss";
import {triggerDownloadAction} from "../../../actions/meetingActions";
import Download from "./Download";

class AudioPlayer extends Component {
    constructor(props) {
        super(props);
        this.audio = React.createRef();
    }

    state = {
        audioData: {},
        duration: '',
        playing: false,
        volume: 9,
        curTime: 0,
        pauseAt: 0,
        clickedTime: 0,
    };

    componentDidMount() {
        const audioData = {
            source:
                localStorage.getItem("dashboardMeetingAudio"),
            duration: this.convertSecondstoTime(localStorage.getItem("currentMeetingDuration")),
            highlights: this.props.highlights,
        };

        this.setState({audioData});

        // DOM listeners: update React state on DOM events
        this.audio.current.addEventListener('loadeddata', this.setAudioPlayerData);

        this.audio.current.addEventListener('timeupdate', this.setAudioTime);
    }

    setAudioPlayerData = () => {
        const {audioData} = this.state;
        const duration = moment.duration(audioData.duration).asSeconds();
        this.setDuration(duration);
        this.setPauseAt(duration);
        this.setCurTime(this.audio.current.currentTime);
    };

    setAudioTime = () => {
        const {pauseAt, duration} = this.state;
        if (this.audio.current.currentTime > pauseAt) {
            this.setPauseAt(duration);
            this.setPlaying(false);
        }
        this.setCurTime(this.audio.current.currentTime);
    };

    convertSecondstoTime = (seconds) => {

        let hours = Math.floor(seconds / 3600);
        let minutes = Math.floor((seconds - (hours * 3600)) / 60);
        let seconds2 = seconds - (hours * 3600) - (minutes * 60);

        let timeString = "";

        timeString = hours.toString().padStart(2, '0') + ':' +
            minutes.toString().padStart(2, '0') + ':' +
            seconds2.toString().padStart(2, '0');

        return timeString;
    }

    setPlaying = (playing) => {
        this.setState({playing});
    };

    setCurTime = (curTime) => {
        this.setState({curTime});
    };

    setDuration = (duration) => {
        this.setState({duration});
    };

    setVolume = (volume) => {
        this.setState({volume});
    };

    setClickedTime = (clickedTime) => {
        this.setState({clickedTime});
    };

    setPauseAt = (pauseAt) => {
        this.setState({pauseAt});
    };

    render() {
        const {
            audioData,
            volume,
            curTime,
            clickedTime,
            duration,
            playing,
        } = this.state;
        if (this.audio && this.audio.current) {
            playing ? this.audio.current.play() : this.audio.current.pause();
            this.audio.current.volume = volume / 10;

            if (clickedTime && clickedTime !== curTime) {
                this.audio.current.currentTime = clickedTime;
                this.setClickedTime(null);
            }
        }

        return audioData ? (
            <div className='player'>
                <audio id='audio' ref={this.audio}>
                    <source src={audioData.source}/>
                    Your browser does not support the <code>audio</code> element.
                </audio>
                <Bar
                    curTime={curTime}
                    duration={duration}
                    highlights={audioData.highlights}
                    onTimeUpdate={(time) => this.setClickedTime(time)}
                    onHighlightClick={(time, duration) => {
                        const start = moment.duration(time).asSeconds();
                        this.setClickedTime(start);
                        this.setPauseAt(start + duration);
                        this.setPlaying(true);
                    }}
                    setPlaying={this.setPlaying}
                />
                <div className='controls'>

                    <div
                        style={{margin: ".25rem 0 0 0"}}
                        onClick={() => this.props.triggerDownload("audio", "")}
                    >
                        <OverlayTrigger
                            placement={"top"}
                            overlay={
                                <Tooltip style={{fontSize: ".6rem", fontFamily: "CeraPRO-Regular"}}
                                         id={`tooltip-top`}>
                                    Download Meeting Audio
                                </Tooltip>
                            }
                        >
                            <Download
                            />
                        </OverlayTrigger>
                    </div>
                    {playing ? (
                        <Pause handleClick={() => this.setPlaying(false)}/>
                    ) : (
                        <Play handleClick={() => this.setPlaying(true)}/>
                    )}
                    <Volume volume={volume} setVolume={this.setVolume}/>
                    <Duration curTime={curTime} duration={duration}/>
                </div>
                {false &&
                <Highlights
                    highlights={audioData.highlights}
                    onTimeUpdate={(time, duration) => {
                        const start = moment.duration(time).asSeconds();
                        this.setClickedTime(start);
                        this.setPauseAt(start + duration);
                        this.setPlaying(true);
                    }}
                />}
            </div>
        ) : (
            <div className='player'/>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    dropDown: state.dropDown,
    meeting: state.meeting
});

const mapDispatchToProps = (dispatch) => ({
    triggerDownload: (type, format) => dispatch(triggerDownloadAction(type, format)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AudioPlayer);
