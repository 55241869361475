import React, {Component} from "react";
import {checkTokenValidityAction, closeResetPasswordModalAction} from "../../actions/authActions";
import {connect} from "react-redux";
import Modal from "../../components/Modal/container/Modal";
import {login_url} from "../../components/Common";
import Header from "../../components/Header/component/Header";

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth
        };
    }

    handleResize = (e) => {
        this.setState({windowWidth: window.innerWidth})
    };

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        const queryString = window.location.search;
        // console.log(queryString);
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get('t');
        console.log(token);
        if(token.length > 0)
            this.props.checkTokenValidity(token);
        else
            window.location.href = (login_url);
        // if (localStorage.getItem("authToken") !== null) {
        //     this.props.checkSessionValidity();
        //     if (this.props.location.search !== null) {
        //         this.pin = pin;
        //         this.props.getAllLiveMeetingData(id);
        //         this.handleStart(id);
        //     }
        // } else
        //     window.location.href = (Constants.login_url);
    }

    render() {
        const {windowWidth} = this.state;
        const {auth} = this.props;

        return (
            <div className={windowWidth > 720 ? "login-outer": ""}>

                <Header/>

                {(!auth.resetPasswordRequestLoading &&
                    auth.resetPasswordSuccessful &&
                    auth.showResetPasswordStatusToast) &&
                <div className="toast-outer-home reset-password-toast info-success">
                    <section>
                        {auth.resetPasswordMessage}
                        <button
                            onClick={() => this.closeResetPasswordModal()}
                            className="close-success">
                            x</button>
                    </section>
                </div>
                }

                {(!auth.resetPasswordRequestLoading &&
                    !auth.resetPasswordSuccessful &&
                    auth.showResetPasswordStatusToast) &&
                <div className="toast-outer-home reset-password-toast info-failure">
                    <section>
                        {auth.resetPasswordMessage}
                        <button
                            onClick={() => this.props.closeResetPasswordModal()}
                            className="close-failure">
                            x</button>
                    </section>
                </div>
                }

                <Modal
                    title={"Reset Password"}
                    type={"resetPassword"}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
    checkTokenValidity: (token) => dispatch(checkTokenValidityAction(token)),
    closeResetPasswordModal: () => dispatch(closeResetPasswordModalAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);