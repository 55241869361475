import React, {Component} from "react";
import {Modal} from "react-bootstrap";

import "../style/DeleteModal.css";

class DeleteModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deleteAccountInput: ""
        };

        this.update = this.update.bind(this);
    }

    update(e){
        let name = e.target.name;
        let value = e.target.value;
        this.setState({[name]: value});
    };

    render() {

        return (
            <Modal show={true}
                   onHide={() => this.props.closeDeleteModal()}>
                <Modal.Header closeButton>

                    <Modal.Title>
                        Delete Account
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div style={{display: "flex", flexDirection: "column"}}>

                        <p>
                            <span style={{color: "red"}}>Danger Zone</span>: Once you delete your account, there is no
                            going back. Please be certain. Type "delete" to confirm.
                        </p>

                        <input
                            autoComplete="off"
                            type="text"
                            name="deleteAccountInput"
                            className="delete-account-input"
                            value={this.state.deleteAccountInput}
                            placeholder="Type here..."
                            onChange={this.update}
                        />

                        <button
                            disabled={this.state.deleteAccountInput.trim() !== "delete"}
                            className={this.state.deleteAccountInput.trim() !== "delete" ? "delete-account-button disabled": "delete-account-button"}
                            onClick={() => this.props.deleteAccount()}
                        >
                            Delete My Account
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default DeleteModal;