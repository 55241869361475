import React from 'react';

export default function Duration(props) {
  const { duration, curTime } = props;

  function convertSecondstoTime0(seconds) {

    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds - (hours * 3600)) / 60);
    let seconds2 = seconds - (hours * 3600) - (minutes * 60);

    let timeString = "";

    timeString = hours.toString().padStart(2, '0') + ':' +
        minutes.toString().padStart(2, '0') + ':' +
        seconds2.toString().padStart(2, '0').split(".")[0];

    return timeString;
  }

  function convertSecondstoTime(seconds) {

    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds - (hours * 3600)) / 60);
    let seconds2 = seconds - (hours * 3600) - (minutes * 60);

    let timeString = "";

    timeString = hours.toString().padStart(2, '0') + ':' +
        minutes.toString().padStart(2, '0') + ':' +
        seconds2.toString().padStart(2, '0');

    return timeString;
  }

  return (
    <div className='controls__duration'>
      {convertSecondstoTime0(curTime)} / {convertSecondstoTime(duration)}
    </div>
  );
}
