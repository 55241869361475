import {connect} from "react-redux";
import {toggleDropdownMenuAction} from "../../../actions/dropdownActions";

import Header from '../component/Header';
import {updateMeetingNameAction} from "../../../actions/meetingActions";

const mapStateToProps = (state) => ({
    auth: state.auth,
    meeting: state.meeting
});

const mapDispatchToProps = (dispatch) => ({
    toggleDropDownMenu: () => dispatch(toggleDropdownMenuAction()),
    updateMeetingNameA: (meetingID, meetingName) => dispatch(updateMeetingNameAction(meetingID, meetingName))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);