import {connect} from "react-redux";
import ProfilePictureModal from "../component/ProfilePictureModal";
import {
    closeProfilePictureAction,
    profilePictureUploadAction
} from "../../../actions/authActions";


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    closeProfilePicture: () => dispatch(closeProfilePictureAction()),
    profilePictureUpload: (formData) => dispatch(profilePictureUploadAction(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePictureModal);