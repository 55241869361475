import {connect} from "react-redux";
import MeetingItem from "../component/MeetingItem";
import {
    getMeetingDetailsAction,
    setMeetingNameAndDateAction,
    changeMakeCallAction,
    flushContentResultAction
} from "../../../actions/meetingActions";

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
    getMeetingDetails:(meetingID) => dispatch(getMeetingDetailsAction(meetingID)),
    setMeetingNameAndDate:(meetingName, localDate, recordURL) => dispatch(setMeetingNameAndDateAction(meetingName, localDate, recordURL)),
    changeMakeCall: (meetingID, switchStatus) => dispatch(changeMakeCallAction(meetingID, switchStatus)),
    flushContentResult: () => dispatch(flushContentResultAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MeetingItem);