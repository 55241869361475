import {connect} from "react-redux";
import CommentBox from "../component/CommentBox";
import {deleteCommentAction, deleteHighlightCommentAction} from "../../../actions/meetingActions";

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
    deleteHighlightComment:(commentID) => dispatch(deleteHighlightCommentAction(commentID)),
    deleteComment:(commentID) => dispatch(deleteCommentAction(commentID))
});

export default connect(mapStateToProps, mapDispatchToProps)(CommentBox);