import React, {Component} from 'react';

import '../style/FormInput.css';

class FormInput extends Component {


    update = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({[name]: value});
    }

    render() {
        const {
            title,
            type,
            placeholder
        } = this.props;

        return (
            <div className="form-input-outer">

                <p
                    className="form-input-title"
                >
                    {title}
                </p>

                <input
                    className="form-input"
                    type={type}
                    placeholder={placeholder}
                />
            </div>
        );
    }
}

export default FormInput;