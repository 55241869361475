import React, {Component} from 'react';
import Header from '../../components/Header/component/Header';
import * as Constants from '../../components/Common';

import Modal from "../../components/Modal/container/Modal";
import {connect} from "react-redux";

import {closeToastAction} from "../../actions/modalActions";

import {toast, ToastContainer} from "react-toastify";

import "./ForgotPassword.css";
/** Handling current scope.*/

const notifySuccess = () =>
    toast.success('Details are sent to the requested email address.', {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

class ForgotPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            windowWidth: window.innerWidth
        };
    }

    handleResize = (e) => {
        this.setState({windowWidth: window.innerWidth})
    };

    componentDidMount() {
        //this.props.checkSessionValidity("ninaad@gmail.com");
        window.addEventListener("resize", this.handleResize);
        if(localStorage.getItem("authToken") !== null)
            window.location.href = (Constants.home_url);
    }

    render() {

        const {windowWidth} = this.state;
        const {auth} = this.props;

        if(auth.showToast && !auth.forgotPasswordRequestLoading && auth.forgotPasswordSuccess) notifySuccess();

        return (

            <div className={windowWidth > 720 ? "login-outer": ""}>

                <Header/>


                {auth.showToast && !auth.forgotPasswordRequestLoading && auth.forgotPasswordSuccess &&
                <ToastContainer
                    position="top-right"
                    hideProgressBar
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover/>
                }

                <Modal
                    title={"Reset Password"}
                    type={"forgotPassword"}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = (dispatch) => ({
    closeToast: () => dispatch(closeToastAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);


