import {connect} from "react-redux";
import SettingsWidget from "../component/SettingsWidget";
import {
    updateUserInfoRequestAction,
    updateUserPasswordRequestAction,
    getUserCalendarInfoAction,
    updateDefaultCallOptAction
} from "../../../actions/userActions";
import {
    googleSyncAction,
    outlookSyncAction,
    calendarSyncRequestAction
} from "../../../actions/calendarSyncActions";

const mapStateToProps = (state) => ({
    auth: state.auth,
    widget: state.widget,
    calendarSync: state.calendarSync,
    calendars: state.calendarReducer
})

const mapDispatchToProps = (dispatch) => ({
    updateUser: (email, firstName, lastName, companyName, jobTitle) => dispatch(updateUserInfoRequestAction(email, firstName, lastName, companyName, jobTitle)),
    updatePassword: (email, password, newPassword) => dispatch(updateUserPasswordRequestAction(email, password, newPassword)),
    googleSyncAction: (syncPayload) =>  dispatch(googleSyncAction(syncPayload)),
    outlookSyncAction: (syncPayload) =>  dispatch(outlookSyncAction(syncPayload)),
    calendarSyncRequestAction: (data) =>  dispatch(calendarSyncRequestAction(data)),
    getUserCalendarInfo: () => dispatch(getUserCalendarInfoAction()),
    updateDefaultCallOpt:(opt) => dispatch(updateDefaultCallOptAction(opt))
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsWidget);