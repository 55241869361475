import React, { Component } from 'react';
import {Clear} from "@material-ui/icons";
import {deleteTriggerAction} from "../actions/meetingActions";
import {connect} from "react-redux";

class TriggerActionItem extends Component {
    /**
     * Render each TriggerActionItem Items
     */
    render() {

        return (

            <button
                style={{
                    textAlign:"center",
                    border: "0",
                    margin: ".1rem",
                    backgroundColor: this.props.bgColor,
                    borderRadius: "16px",
                    color: "#fff",
                    width: "auto",
                    fontSize: ".9rem",
                    height: "auto",
                    padding: "8px",
                    outline: "none"
                }}
            >

                {this.props.title}

                <Clear
                    onClick={() => this.props.deleteTrigger(this.props.triggerID)}
                    style={{
                        cursor: "pointer",
                        height: "1rem",
                        width: "1rem",
                        margin: "0 0 0 .5rem"}}/>
            </button>
        );
    }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
    deleteTrigger: (triggerID) => dispatch(deleteTriggerAction(triggerID))
});

export default connect(mapStateToProps, mapDispatchToProps)(TriggerActionItem);