import {all, takeLatest, put} from "redux-saga/effects";
import * as Constants from '../components/Common';
import fileDownload from 'js-file-download';
import axios from 'axios';

import {
    LOGIN_REQUEST,
    REGISTRATION_REQUEST,
    SESSION_VALIDITY_REQUEST,
    SESSION_USER_DATA_RECEIVED,
    LOGOUT_REQUEST,
    LOGIN_FAILED,
    LOGIN_SUCCESSFUL,
    UPDATE_USER_INFO_REQUEST,
    UPDATE_USER_INFO_REQUEST_SUCCESSFUL,
    UPDATE_USER_INFO_REQUEST_FAILED,
    MEETING_CALLING_IN_REQUEST,
    MEETING_CALLING_IN_REQUEST_SUCCESS,
    MEETING_CALLING_IN_REQUEST_FAILURE,
    ARCHIVED_MEETINGS_REQUEST,
    ARCHIVED_MEETINGS_REQUEST_SUCCESS,
    ARCHIVED_MEETINGS_REQUEST_FAILURE,
    GET_MEETING_DETAILS_REQUEST,
    GET_MEETING_DETAILS_REQUEST_SUCCESS,
    GET_MEETING_DETAILS_REQUEST_FAILURE,
    SET_MEETING_NAME_AND_DATE,
    SET_MEETING_NAME_AND_DATE_SUCCESS,
    UPDATE_USER_PASSWORD_REQUEST,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_REQUEST_SUCCESS,
    FORGOT_PASSWORD_REQUEST_FAILED,
    CALENDAR_SYNC_REQUEST,
    GET_USER_CALENDAR_INFO_REQUEST,
    GOOGLE_CALENDAR_SYNCED,
    OUTLOOK_CALENDAR_SYNCED,
    GET_UPCOMING_USER_EVENTS,
    NO_CALENDAR_SYNCED,
    GET_MEETINGS_FROM_SEARCH_REQUEST,
    GET_MEETINGS_FROM_SEARCH_FOUND,
    GET_MEETINGS_FROM_SEARCH_NOT_FOUND,
    GET_UPCOMING_USER_EVENTS_SUCCESS,
    GET_UPCOMING_USER_EVENTS_FAILURE,
    CHANGE_MAKE_CALL_STATUS_REQUEST,
    GET_TRIGGERS_REQUEST,
    GET_TRIGGERS_REQUEST_SUCCESS,
    GET_TRIGGERS_REQUEST_FAILURE,
    DELETE_TRIGGER_REQUEST,
    ADD_TRIGGER_REQUEST,
    LOAD_TRIGGERS,
    OPEN_EDIT_TRANSCRIPT_MODAL,
    SHOW_EDIT_MODAL,
    CLOSE_EDIT_TRANSCRIPT_MODAL,
    HIDE_EDIT_MODAL,
    UPDATE_TRANSCRIPT_REQUEST,
    TRANSCRIPTS_REQUEST_LOADING,
    SEARCH_IN_MEETING_REQUEST,
    SEARCH_IN_MEETING_REQUEST_FOUND,
    SEARCH_IN_MEETING_REQUEST_NOT_FOUND,
    TRIGGER_DOWNLOAD,
    GET_WORD_CLOUD_REQUEST,
    GET_WORD_CLOUD_REQUEST_SUCCESS,
    GET_WORD_CLOUD_REQUEST_FAILURE,
    AUDIO_AVAILABLE,
    GET_LIVE_MEETING_DETAILS_REQUEST_SUCCESS,
    GET_LIVE_MEETING_DETAILS_REQUEST_FAILURE,
    GET_LIVE_MEETING_DETAILS_REQUEST,
    MESSAGE_CATEGORY_REQUEST,
    MESSAGE_HIGHLIGHT_REQUEST,
    MESSAGE_CATEGORY_REQUEST_SUCCESS,
    MESSAGE_HIGHLIGHT_REQUEST_SUCCESS,
    SWITCH_TABS,
    ADD_COMMENT_REQUEST,
    FETCH_TRANSCRIPT_COMMENTS_REQUEST,
    FETCH_TRANSCRIPT_COMMENTS_SUCCESS,
    FETCH_TRANSCRIPT_COMMENTS_FAILURE,
    DELETE_TRANSCRIPT_COMMENT_REQUEST,
    ADD_CUSTOM_NOTE_REQUEST,
    UPDATE_COMMENTS,
    DELETE_MEETING_REQUEST,
    TRANSCRIPT_MESSAGE_CATEGORY_REQUEST,
    DELETE_MEETING_FROM_DASHBOARD_REQUEST,
    FETCH_FINISHED_TRANSCRIPT_COMMENTS_REQUEST,
    DELETE_ACCOUNT_REQUEST,
    GET_ALL_MEETING_DETAILS_REQUEST,
    GET_ALL_LIVE_MEETING_DETAILS_REQUEST,
    GET_ALL_LIVE_MEETING_DETAILS_REQUEST_SUCCESS,
    UPDATE_TRANSCRIPT_LIVE_REQUEST,
    OPEN_EDIT_TRANSCRIPT_MODAL_LIVE,
    SHOW_EDIT_MODAL_LIVE,
    UPDATE_DEFAULT_CALL_OPT_REQUEST,
    GET_MEETING_HIGHLIGHT_COMMENTS_REQUEST,
    GET_MEETING_HIGHLIGHT_COMMENTS_REQUEST_SUCCESS,
    DELETE_TRANSCRIPT_HIGHLIGHT_COMMENT_REQUEST,
    UPLOAD_PROFILE_PICTURE_REQUEST,
    CLOSE_PROFILE_PICTURE_MODAL,
    SHOW_UPDATED_SEARCH,
    ONGOING_ACTIVE_CALLS,
    NO_ONGOING_ACTIVE_CALLS,
    MEETING_HIGHLIGHT_COMMENTS_LOADED,
    USER_MEETING_OVERVIEW_RECEIVED,
    USER_MEETING_OVERVIEW_NOT_RECEIVED,
    LIVE_MESSAGE_CATEGORY_REQUEST,
    LIVE_MESSAGE_CATEGORY_REQUEST_SUCCESS,
    ADD_HIGHLIGHT_COMMENT_REQUEST,
    UPDATE_HIGHLIGHT_COMMENTS,
    UPDATE_MEETING_NAME_REQUEST,
    CHECK_RESET_TOKEN_VALIDITY_REQUEST,
    RESET_TOKEN_NOT_VALID,
    RESET_PASSWORD_REQUEST,
    SHOW_RESET_PASSWORD_SUCCESS,
    SHOW_RESET_PASSWORD_FAILURE,
    GET_USER_CALENDAR_INFO_REGISTER_REQUEST,
    CALENDAR_SYNC_REQUEST_REGISTER,
    SUBSCRIPTION_CANCELLATION_REQUEST,
    SUBSCRIPTION_CANCELLATION_REQUEST_SUCCESS, SUBSCRIPTION_CANCELLATION_REQUEST_FAILURE
} from "../actions/types";
import {login_url} from "../components/Common";
import {live_meeting_url} from "../components/Common";
import {archives_url} from "../components/Common";
import {forgot_url} from "../components/Common";
import {settings_url} from "../components/Common";

export function* registrationSaga(params) {
    const json = yield axios.post("https://api.staging.zoimeet.com/register", {
        "firstname": params.params.registrationPayloadObject.firstName,
        "lastname": params.params.registrationPayloadObject.lastName,
        "username": params.params.registrationPayloadObject.email,
        "emailid": params.params.registrationPayloadObject.email,
        "password": params.params.registrationPayloadObject.password,
        "company": params.params.registrationPayloadObject.company,
        "companySize": params.params.registrationPayloadObject.companySize,
        "role": params.params.registrationPayloadObject.role,
        "department": params.params.registrationPayloadObject.department,
        "meetingTypes": params.params.registrationPayloadObject.meetingTypes,
        "NoOfMeetings": params.params.registrationPayloadObject.noOfMeetings,
        "preferredConferencePlatforms": params.params.registrationPayloadObject.preferredConferencePlatforms,
        "onHold": params.params.registrationPayloadObject.on_hold,
    });

    if (json.data.status && json.data.authToken.length > 0) {
        localStorage.setItem("authToken", json.data.authToken);
        localStorage.setItem("caud", `${json.data.data.insertId},${params.params.registrationPayloadObject.email}`);
        localStorage.setItem("icsp", true);
    }
}

export function* forgotPasswordRequestSaga(params) {

    const json = yield axios.post("https://api.staging.zoimeet.com/forgotpassword", {
        "emailid": params.params.forgotPasswordEmail
    });

    json.data.status ? yield put({
        type: FORGOT_PASSWORD_REQUEST_SUCCESS,
        data: json.data
    }) : yield put({type: FORGOT_PASSWORD_REQUEST_FAILED, data: json.data})
}

export function* loginSaga(params) {
    let obj = {};
    obj.username = params.params.loginEmail;
    obj.password = params.params.loginPassword;
    obj.platform = 1;

    const json = yield axios.post("https://api.staging.zoimeet.com/login", {
        "username": params.params.loginEmail,
        "password": params.params.loginPassword,
        "platform": 1
    });

    if (json.data.status) {
        yield put({type: LOGIN_SUCCESSFUL});
        localStorage.setItem('authToken', json.data.authToken);
        window.location.assign(Constants.home_url);
    } else
        yield put({type: LOGIN_FAILED, data: json.data});
    //yield put({type: LOGIN_SUCCESSFUL});
}

export function* sessionValiditySaga() {
    // localStorage.removeItem('userEmail');
    // window.location.href = (Constants.login_url);
    const authToken = 'Bearer ' + localStorage.getItem('authToken');

    const json = yield axios.get("https://api.staging.zoimeet.com/user/get", {
        headers: {
            Authorization: authToken
        }
    });

    const activeCalls = yield axios.get("https://api.staging.zoimeet.com/meeting/getactivemeetings", {
        headers: {
            Authorization: authToken
        }
    });

    const meetingData = yield axios.get("https://api.staging.zoimeet.com/user/getoverview", {
        headers: {
            Authorization: authToken
        }
    });

    console.log(activeCalls);
    if (activeCalls.data.status) yield put({type: ONGOING_ACTIVE_CALLS, data: activeCalls.data.data});
    else yield put({type: NO_ONGOING_ACTIVE_CALLS});

    if (meetingData.status) {
        console.log(meetingData.data.overview);
        yield put({type: USER_MEETING_OVERVIEW_RECEIVED, data: meetingData.data.overview});
    } else
        yield put({type: USER_MEETING_OVERVIEW_NOT_RECEIVED});

    if (!json.data.status) {
        localStorage.removeItem('authToken');
        window.location.href = login_url;
    }
    //const session=Constants.getCookie(session);
    //localStorage.setItem('session2',session);
    yield put({type: SESSION_USER_DATA_RECEIVED, data: json.data.data});
}

export function* logoOutSaga(params) {
    //const json = yield axios.post("/user/logout", {});
    localStorage.removeItem('userEmail');
    window.location.href = (Constants.logout_url);
}

export function* updateUserInfoSaga(params) {
    const authToken = 'Bearer ' + localStorage.getItem('authToken');
    const json = yield axios.post(" https://api.staging.zoimeet.com/user/update", {
        "emailid": params.params.email,
        "firstname": params.params.firstName,
        "lastname": params.params.lastName,
        "company": params.params.companyName,
        "role": params.params.jobTitle,
    }, {
        headers: {
            Authorization: authToken
        }
    });

    if (json.data.status) {
        yield put({type: UPDATE_USER_INFO_REQUEST_SUCCESSFUL, data: json.data});
        setTimeout(() => window.location.href = settings_url, 5000);
    } else {
        yield put({type: UPDATE_USER_INFO_REQUEST_FAILED, data: json.data})
    }
}

export function* updateUserPasswordSaga(params) {
    const authToken = 'Bearer ' + localStorage.getItem('authToken');

    const json = yield axios.post("https://api.staging.zoimeet.com/user/changepassword", {
        "emailid": params.params.email,
        "oldpassword": params.params.password,
        "newpassword": params.params.newPassword
    }, {
        headers: {
            Authorization: authToken
        }
    });

    if (json.data.status) {
        yield put({type: UPDATE_USER_INFO_REQUEST_SUCCESSFUL, data: json.data});
        localStorage.removeItem("authToken");
        setTimeout(() => window.location.href = login_url, 5000);
    } else {
        yield put({type: UPDATE_USER_INFO_REQUEST_FAILED, data: json.data});
    }
}

export function* meetingCallingInSaga(params) {
    let zoomURLRegex = new RegExp("https://[A-Za-z0-9_.]*zoom[A-Za-z0-9_.]*\\.us", "ig");
    let msTeamURLRegex = new RegExp("https://[A-Za-z0-9_.]*teams.microsoft.com[A-Za-z0-9_.]*");
    let lifeFlowUrlRegex = new RegExp("https://[A-Za-z0-9_.]*lifesizecloud.com[A-Za-z0-9_.]*");
    let googleMeetUrlRegex = new RegExp("https://[A-Za-z0-9_.]*meet.google.com/[A-Za-z0-9_.-]*");
    let callType = "";
    if (lifeFlowUrlRegex.test(params.params.meetingURL)) callType = "lifesize";
    if (googleMeetUrlRegex.test(params.params.meetingURL)) callType = "meet";
    if (msTeamURLRegex.test(params.params.meetingURL)) callType = "msteams";
    if (zoomURLRegex.test(params.params.meetingURL)) callType = "zoom";

    const json = yield axios.post("https://transcribe.staging.zoimeet.com/newmeetingcallingin", {
        "userid": params.params.userID,
        "deviceId": params.params.userID,
        "admin": params.params.userID,
        "lang": 'en',
        "name": params.params.meetingAdminName,
        "meeting_name": params.params.meetingName,
        "offeredLanguages": ["en"],
        "type": callType,
        "conferenceUrl": params.params.meetingURL,
        "conferencePassword": params.params.meetingPasscode === "" ? null : params.params.meetingPasscode,
        "recording_limit": 1
    });

    console.log(json.data);

    if (json.data.success) {
        console.log(json.data);
        localStorage.setItem("liveMeetingID", json.data.tblMeeting.id);
        yield put({type: MEETING_CALLING_IN_REQUEST_SUCCESS, data: json.data});
        axios.post("https://transcribe.staging.zoimeet.com/transcribeFromStream", {
                "meeting_id": json.data.meetingData.meetings.meeting_id,
                "user_id": params.params.userID,
                "mpin": json.data.meetingData.meetings.meeting_id,
                "url": json.data.meetingData.meetings.url,
                "api_endpoint": json.data.meetingData.meetings.api_endpoint,
                "callback_url": "http://100.26.216.100:5000/transcriptFromGoogle",
                "api_status": "In_use",
                "meeting_name": json.data.meeting_name,
                "stream_url": json.data.meetingData.meetings.stream,
                "meeting_type": callType
            }
        );

        localStorage.setItem("liveMeetingId", json.data.tblMeeting.id);
        //window.open(`${live_meeting_url}?pin=${json.data.tblMeeting.pin}`);
        window.open(
            `${live_meeting_url}?pin=${json.data.tblMeeting.pin}&id=${json.data.tblMeeting.id}`,
            "",
            `width=${window.innerWidth / 2.35},height=${window.innerHeight * 1.2}, left=0,top=0`
        );
    } else {
        yield put({type: MEETING_CALLING_IN_REQUEST_FAILURE, data: json.data});
    }
}

export function* transcriptsRequestSaga() {

}

export function* archivedMeetingsRequestSaga(params) {
    console.log(params.params.pageNo.toString());
    const authToken = 'Bearer ' + localStorage.getItem('authToken');
    if (params.params.pageNo === 1) {

        const json = yield axios.get("https://api.staging.zoimeet.com/meeting/meetings",
            {
                headers: {
                    Authorization: authToken
                }
            });

        json.data.status ? yield put({
            type: ARCHIVED_MEETINGS_REQUEST_SUCCESS,
            data: json.data
        }) : yield put({type: ARCHIVED_MEETINGS_REQUEST_FAILURE})
    } else {
        const json = yield axios.get(`https://api.staging.zoimeet.com/meeting/meetings?page=${params.params.pageNo.toString()}`,
            {
                headers: {
                    Authorization: authToken
                }
            });

        json.data.status ? yield put({
            type: ARCHIVED_MEETINGS_REQUEST_SUCCESS,
            data: json.data
        }) : yield put({type: ARCHIVED_MEETINGS_REQUEST_FAILURE})
    }
}

export function* getAllMeetingDetailsRequestSaga(params) {
    const authToken = 'Bearer ' + localStorage.getItem('authToken');

    const json = yield axios.post("https://api.staging.zoimeet.com/meeting/meetingdata/all", {
        "meetingId": params.params.meetingID
    }, {
        headers: {
            Authorization: authToken
        }
    });

    console.log(json);

    json.data.status ? yield put({
        type: GET_MEETING_DETAILS_REQUEST_SUCCESS,
        data: json.data
    }) : yield put({type: GET_MEETING_DETAILS_REQUEST_FAILURE, data: json.data});
}

export function* getMeetingDetailsRequestSaga(params) {
    const authToken = 'Bearer ' + localStorage.getItem('authToken');

    const json = yield axios.post("https://api.staging.zoimeet.com/meeting/meetingdata", {
        "meetingId": params.params.meetingID
    }, {
        headers: {
            Authorization: authToken
        }
    });

    console.log(json);

    json.data.status ? yield put({
        type: GET_MEETING_DETAILS_REQUEST_SUCCESS,
        data: json.data
    }) : yield put({type: GET_MEETING_DETAILS_REQUEST_FAILURE, data: json.data});
}

export function* getLiveMeetingDetailsRequestSaga(params) {
    const authToken = 'Bearer ' + localStorage.getItem('authToken');

    const json = yield axios.post("https://api.staging.zoimeet.com/meeting/meetingdata/live", {
        "meetingId": params.params.meetingID
    }, {
        headers: {
            Authorization: authToken
        }
    });

    console.log(json.data.data);

    json.data.status ? yield put({
        type: GET_LIVE_MEETING_DETAILS_REQUEST_SUCCESS,
        data: json.data.data
    }) : yield put({type: GET_LIVE_MEETING_DETAILS_REQUEST_FAILURE, data: json.data});
}

export function* getAllLiveMeetingDetailsRequestSaga(params) {
    const authToken = 'Bearer ' + localStorage.getItem('authToken');

    const json = yield axios.post("https://api.staging.zoimeet.com/meeting/meetingdata/all", {
        "meetingId": params.params.meetingID
    }, {
        headers: {
            Authorization: authToken
        }
    });

    console.log(json.data.data);

    json.data.status ? yield put({
        type: GET_ALL_LIVE_MEETING_DETAILS_REQUEST_SUCCESS,
        data: json.data.data
    }) : yield put({type: GET_LIVE_MEETING_DETAILS_REQUEST_FAILURE, data: json.data});
}

export function* setMeetingNameAndDateSaga(params) {
    yield put({type: SET_MEETING_NAME_AND_DATE_SUCCESS, data: params.params});
}

export function* updateCalenderSyncSaga(data) {
    console.log(data);
    const json = yield axios.post("https://api.staging.zoimeet.com/calendar/sync", data.params);
    if (json && json.data && json.data.status) {
        if (data.params.platform === 0) {
            let dataParams = {
                googleCalendarConnected: true,
                googleCalendarEmail: json.data.calendarEmail,
                googleSyncLoading: false
            };
            yield put({type: GOOGLE_CALENDAR_SYNCED, data: dataParams.googleCalendarEmail});
            if (window.location.search !== "") window.location.search = "";
            this.props.history.push(settings_url);
            if (localStorage.getItem("gcsd") && localStorage.getItem("gcsd") === "1") {
                localStorage.setItem("gcsd", "2");
                localStorage.setItem("g_suite", json.data.calendarEmail);
            } else {
                localStorage.setItem("gcsd", "0");
                localStorage.setItem("g_suite", json.data.calendarEmail);
            }
        } else {
            let dataParams = {
                outlookCalendarConnected: true,
                outlookCalendarEmail: json.data.calendarEmail,
                outlookSyncLoading: false
            };
            yield put({type: OUTLOOK_CALENDAR_SYNCED, data: dataParams.outlookCalendarEmail});
            if (window.location.search !== "") window.location.search = "";
            this.props.history.push(settings_url);
            if (localStorage.getItem("gcsd") && localStorage.getItem("gcsd") === "0") {
                localStorage.setItem("gcsd", "2");
                localStorage.setItem("outlook", json.data.calendarEmail);
            } else {
                localStorage.setItem("gcsd", "1");
                localStorage.setItem("outlook", json.data.calendarEmail);
            }
        }
    } else {
        //alert(json.data && json.data.message);
        if (data.params.platform === 0) {
            let dataParams = {
                googleCalendarConnected: false,
                googleCalendarEmail: '',
                googleSyncLoading: false
            };
            //yield put({type: GOOGLE_SYNC, params: dataParams});
            //yield put({type: GOOGLE_CALENDAR_SYNCED, data: dataParams.googleCalendarEmail});
            if (window.location.search !== "") window.location.search = "";
            this.props.history.push(settings_url);
        } else {
            let dataParams = {
                outlookCalendarConnected: false,
                outlookCalendarEmail: '',
                outlookSyncLoading: false
            };
            //yield put({type: OUTLOOK_CALENDAR_SYNCED, data: dataParams.outlookCalendarEmail});
            if (window.location.search !== "") window.location.search = "";
            this.props.history.push(settings_url);
        }
    }
}

export function* updateCalenderRegisterSyncSaga(data) {
    console.log(data);
    const json = yield axios.post("https://api.staging.zoimeet.com/calendar/sync", data.params);
    if (json && json.data && json.data.status) {
        if (data.params.platform === 0) {
            let dataParams = {
                googleCalendarConnected: true,
                googleCalendarEmail: json.data.calendarEmail,
                googleSyncLoading: false
            };
            yield put({type: GOOGLE_CALENDAR_SYNCED, data: dataParams.googleCalendarEmail});
            //if(window.location.search !== "") window.location.search = "";
            //this.props.history.push(settings_url);
            if (localStorage.getItem("gcsd") && localStorage.getItem("gcsd") === "1") {
                localStorage.setItem("gcsd", "2");
                localStorage.setItem("g_suite", json.data.calendarEmail);
            } else {
                localStorage.setItem("gcsd", "0");
                localStorage.setItem("g_suite", json.data.calendarEmail);
            }
        } else {
            let dataParams = {
                outlookCalendarConnected: true,
                outlookCalendarEmail: json.data.calendarEmail,
                outlookSyncLoading: false
            };
            yield put({type: OUTLOOK_CALENDAR_SYNCED, data: dataParams.outlookCalendarEmail});
            //if(window.location.search !== "") window.location.search = "";
            //this.props.history.push(settings_url);
            if (localStorage.getItem("gcsd") && localStorage.getItem("gcsd") === "0") {
                localStorage.setItem("gcsd", "2");
                localStorage.setItem("outlook", json.data.calendarEmail);
            } else {
                localStorage.setItem("gcsd", "1");
                localStorage.setItem("outlook", json.data.calendarEmail);
            }
        }
    } else {
        //alert(json.data && json.data.message);
        if (data.params.platform === 0) {
            let dataParams = {
                googleCalendarConnected: false,
                googleCalendarEmail: '',
                googleSyncLoading: false
            };
            //yield put({type: GOOGLE_SYNC, params: dataParams});
            //yield put({type: GOOGLE_CALENDAR_SYNCED, data: dataParams.googleCalendarEmail});
            // if(window.location.search !== "") window.location.search = "";
            // this.props.history.push(settings_url);
        } else {
            let dataParams = {
                outlookCalendarConnected: false,
                outlookCalendarEmail: '',
                outlookSyncLoading: false
            };
            //yield put({type: OUTLOOK_CALENDAR_SYNCED, data: dataParams.outlookCalendarEmail});
            // if(window.location.search !== "") window.location.search = "";
            // this.props.history.push(settings_url);
        }
    }
}

export function* getUserCalendarInfoRequestSaga() {
    const authToken = 'Bearer ' + localStorage.getItem('authToken');

    const json = yield axios.get(" https://api.staging.zoimeet.com/user/calendarinfo",
        {
            headers: {
                Authorization: authToken
            }
        });

    if (json.data.status) {
        for (let i = 0; i < json.data.data.length; i++) {
            if (json.data.data[i].calendar_platform === 0) {
                yield put({type: GOOGLE_CALENDAR_SYNCED, data: json.data.data[i].calendar_email});
                if (window.location.search !== "") window.location.search = "";
                this.props.history.push(settings_url);
            }
            if (json.data.data[i].calendar_platform === 1) {
                yield put({type: OUTLOOK_CALENDAR_SYNCED, data: json.data.data[i].calendar_email});
                if (window.location.search !== "") window.location.search = "";
                this.props.history.push(settings_url);
            }
        }
    }

    if (!json.data.status) {
        yield put({type: NO_CALENDAR_SYNCED})
    }
}

export function* getUserCalendarInfoRegisterRequestSaga() {
    const authToken = 'Bearer ' + localStorage.getItem('authToken');

    const json = yield axios.get(" https://api.staging.zoimeet.com/user/calendarinfo",
        {
            headers: {
                Authorization: authToken
            }
        });

    if (json.data.status) {
        for (let i = 0; i < json.data.data.length; i++) {
            if (json.data.data[i].calendar_platform === 0) {
                yield put({type: GOOGLE_CALENDAR_SYNCED, data: json.data.data[i].calendar_email});
                // if(window.location.search !== "") window.location.search = "";
                // this.props.history.push(settings_url);
            }
            if (json.data.data[i].calendar_platform === 1) {
                yield put({type: OUTLOOK_CALENDAR_SYNCED, data: json.data.data[i].calendar_email});
                // if(window.location.search !== "") window.location.search = "";
                // this.props.history.push(settings_url);
            }
        }
    }

    if (!json.data.status) {
        yield put({type: NO_CALENDAR_SYNCED})
    }
}

export function* getUpcomingUserEventsSaga(params) {
    console.log(params);
    const authToken = 'Bearer ' + localStorage.getItem('authToken');

    if (params.params.pageNo < 2) {
        const json = yield axios.get("https://api.staging.zoimeet.com/meeting/upcoming",
            {
                headers: {
                    Authorization: authToken
                }
            });

        if (json.data.status)
            yield put({type: GET_UPCOMING_USER_EVENTS_SUCCESS, data: json.data.data});
        else
            yield put({type: GET_UPCOMING_USER_EVENTS_FAILURE, data: []});
    } else {
        const json = yield axios.get(
            `https://api.staging.zoimeet.com/meeting/upcoming?page=${params.params.pageNo}`,
            {
                headers: {
                    Authorization: authToken
                }
            });

        if (json.data.status)
            yield put({type: GET_UPCOMING_USER_EVENTS_SUCCESS, data: json.data.data});
        else
            yield put({type: GET_UPCOMING_USER_EVENTS_FAILURE, data: []});
    }
}

export function* getMeetingsFromSearchRequestSaga(params) {
    const authToken = 'Bearer ' + localStorage.getItem('authToken');

    const json = yield axios.get(`https://api.staging.zoimeet.com/meeting/search?meeting=${params.params.meetingName}&page=${params.params.pageNo}`,
        {
            headers: {
                Authorization: authToken
            }
        });

    console.log(json);
    if (json.data.status)
        yield put({type: GET_MEETINGS_FROM_SEARCH_FOUND, data: json.data.data});
    else
        yield put({type: GET_MEETINGS_FROM_SEARCH_NOT_FOUND});
}

export function* changeMakeCallStatusRequestSaga(params) {
    const authToken = 'Bearer ' + localStorage.getItem('authToken');

    const json = yield axios.post(
        `https://api.staging.zoimeet.com/meeting/update/makecall`, {
            "makeCall": params.params.switchStatus,
            "meetingId": params.params.meetingID
        },
        {
            headers: {
                Authorization: authToken
            }
        });

    console.log(json);
}

export function* getTriggersRequestSaga(params) {
    const authToken = 'Bearer ' + localStorage.getItem('authToken');
    const json = yield axios.get(
        `https://api.staging.zoimeet.com/user/actiontriggers/get`,
        {
            headers: {
                Authorization: authToken
            }
        });

    json.data.status ?
        yield put({type: GET_TRIGGERS_REQUEST_SUCCESS, data: json.data.actiontriggers}) :
        yield put({type: GET_TRIGGERS_REQUEST_FAILURE})
}

export function* deleteTriggerRequestSaga(params) {

    const authToken = 'Bearer ' + localStorage.getItem('authToken');
    const json = yield axios.post(
        `https://api.staging.zoimeet.com/user/actiontriggers/delete`,
        {
            "at_id": params.params.triggerID
        },
        {
            headers: {
                Authorization: authToken
            }
        }, yield put({type: LOAD_TRIGGERS}));

    if (json.data.status)
        yield put({type: GET_TRIGGERS_REQUEST});
}

export function* addTriggerRequestSaga(params) {

    const authToken = 'Bearer ' + localStorage.getItem('authToken');
    const json = yield axios.post(
        `https://api.staging.zoimeet.com/user/actiontriggers/add`,
        {
            "category": params.params.triggerType,
            'actiontrigger': params.params.triggerText
        },
        {
            headers: {
                Authorization: authToken
            }
        }, yield put({type: LOAD_TRIGGERS}));

    if (json.data.status)
        yield put({type: GET_TRIGGERS_REQUEST});
}

export function* openEditTranscriptModalSaga(params) {

    yield put({type: SHOW_EDIT_MODAL, data: params.params})
}

export function* openEditTranscriptLiveModalSaga(params) {

    yield put({type: SHOW_EDIT_MODAL_LIVE, data: params.params})
}

export function* closeEditTranscriptModalSaga() {

    yield put({type: HIDE_EDIT_MODAL})
}

export function* updateTranscriptRequestSaga(params) {
    const authToken = 'Bearer ' + localStorage.getItem('authToken');
    const json = yield axios.post(`https://api.staging.zoimeet.com/transcripts/edit`, {
        "transcript": params.params.transcript,
        "meetingId": params.params.meetingID,
        "transcriptId": params.params.transcriptID
    }, {
        headers: {
            Authorization: authToken
        }
    }, yield put({type: TRANSCRIPTS_REQUEST_LOADING}));

    console.log(json.data);

    if (json.data.status) {
        // yield put({
        //     type: GET_ALL_LIVE_MEETING_DETAILS_REQUEST,
        //     data: json.data.data
        // });
        yield put({type: GET_MEETING_DETAILS_REQUEST, params: {meetingID: params.params.meetingID}})
        yield put({type: HIDE_EDIT_MODAL});
    } else yield put({type: GET_LIVE_MEETING_DETAILS_REQUEST_FAILURE, data: json.data});

    // if (json.data.status) {
    //     yield put({type: HIDE_EDIT_MODAL});
    //     //yield put({type: GET_MEETING_DETAILS_REQUEST, meetingID: localStorage.getItem("currentMeeting")});
    // }
}

export function* updateTranscriptLiveRequestSaga(params) {
    const authToken = 'Bearer ' + localStorage.getItem('authToken');
    const json = yield axios.post(`https://api.staging.zoimeet.com/transcripts/edit`, {
        "transcript": params.params.transcript,
        "meetingId": params.params.meetingID,
        "transcriptId": params.params.transcriptID
    }, {
        headers: {
            Authorization: authToken
        }
    }, yield put({type: TRANSCRIPTS_REQUEST_LOADING}));

    console.log(json.data);

    if (json.data.status) {
        // yield put({
        //     type: GET_ALL_LIVE_MEETING_DETAILS_REQUEST,
        //     data: json.data.data
        // });
        yield put({type: GET_ALL_LIVE_MEETING_DETAILS_REQUEST, params: {meetingID: params.params.meetingID}});
        yield put({type: HIDE_EDIT_MODAL});
    } else yield put({type: GET_LIVE_MEETING_DETAILS_REQUEST_FAILURE, data: json.data});

    // if (json.data.status) {
    //     yield put({type: HIDE_EDIT_MODAL});
    //     //yield put({type: GET_MEETING_DETAILS_REQUEST, meetingID: localStorage.getItem("currentMeeting")});
    // }
}

export function* searchInMeetingRequestSaga(params) {
    const authToken = 'Bearer ' + localStorage.getItem('authToken');
    const json = yield axios.post(`https://api.staging.zoimeet.com/meeting/search/messages`, {
        "meetingId": params.params.meetingID,
        "message": params.params.meetingContent
    }, {
        headers: {
            Authorization: authToken
        }
    });

    console.log(json);
    if (json.data.status) yield put({type: SEARCH_IN_MEETING_REQUEST_FOUND, data: json.data.data});
    else yield put({type: SEARCH_IN_MEETING_REQUEST_NOT_FOUND})
}

export function* triggerDownloadSaga(params) {
    let fileType = params.params.type;
    let fileFormat = params.params.format;
    const authToken = 'Bearer ' + localStorage.getItem('authToken');
    let endPoint = "";

    if (fileType === "transcripts") {
        endPoint = "https://utilities.staging.zoimeet.com/transcripts/export/transcripts";

        const json = yield axios.post(`${endPoint}`, {
            "meetingId": localStorage.getItem("currentMeeting"),
            "meetingPin": localStorage.getItem("currentMeetingPin"),
            "fileType": fileFormat
        }, {
            headers: {
                Authorization: authToken
            }
        });

        if (json.data.status) {
            window.open(json.data.location);
            setTimeout(() => window.close(), 3000);
        }
    }

    if (fileType === "actionItems") {
        endPoint = "https://utilities.staging.zoimeet.com/transcripts/export/actionitems";
        const json = yield axios.post(`${endPoint}`, {
            "meetingId": localStorage.getItem("currentMeeting"),
            "meetingPin": localStorage.getItem("currentMeetingPin"),
            "fileType": fileFormat
        }, {
            headers: {
                Authorization: authToken
            }
        });

        if (json.data.status) {
            window.open(json.data.location);
            setTimeout(() => window.close(), 3000);
        }
    }

    if (params.params.type === "highlights") {
        endPoint = "https://utilities.staging.zoimeet.com/transcripts/export/actionitems";
        const json = yield axios.post(`${endPoint}`, {
            "meetingId": localStorage.getItem("currentMeeting"),
            "meetingPin": localStorage.getItem("currentMeetingPin"),
            "fileType": params.params.format
        }, {
            headers: {
                Authorization: authToken
            }
        });

        if (json.data.status) {
            window.open(json.data.location);
            setTimeout(() => window.close(), 3000);
        }
    }

    if (fileType === "audio") {
        endPoint = "https://utilities.staging.zoimeet.com/transcripts/export/audio";
        const json = yield axios.post(`${endPoint}`, {
            "meetingId": localStorage.getItem("currentMeeting"),
        }, {
            headers: {
                Authorization: authToken
            }
        });

        console.log(json);

        if (json.data.data.status) {
            yield put({type: AUDIO_AVAILABLE, data: json.data.data});

            fileDownload(json.data.data.url, `${json.data.data.meetingName}.mp3`);
        }

        // if (json.data.data.status) {
        //     yield put({type: AUDIO_AVAILABLE, data: json.data.data});
        //     axios.get(json.data.data.url, {
        //         responseType: 'blob',
        //     })
        //         .then((res) => {
        //             console.log(json.data.data.url);
        //             fileDownload(json.data.data.url, `${json.data.data.meetingName}.mp3`)
        //         })
        // }
    }
}

export function* getWordCloudRequestSaga() {
    const authToken = 'Bearer ' + localStorage.getItem('authToken');

    const json = yield axios.get(`https://utilities.staging.zoimeet.com/transcripts/keywords?meetingId=${localStorage.getItem("currentMeeting")}`,
        {
            headers: {
                Authorization: authToken
            }
        });

    console.log(json);

    if (json.data.status) yield put({type: GET_WORD_CLOUD_REQUEST_SUCCESS, data: json.data.data});
    else yield put({type: GET_WORD_CLOUD_REQUEST_FAILURE});
}

export function* messageCategoryRequestSaga(params) {
    const authToken = 'Bearer ' + localStorage.getItem('authToken');

    const json = yield axios.post(`https://api.staging.zoimeet.com/meeting/update/actiontriggeritems`, {
        "categoryId": params.params.category,
        "transcriptId": params.params.msgID,
        "meetingId": params.params.meetingID,
    }, {
        headers: {
            Authorization: authToken
        }
    });

    console.log(json);

    if (json.data.status) {
        let updatedObj = json.data.data;
        updatedObj.category = json.data.category;
        yield put({type: MESSAGE_CATEGORY_REQUEST_SUCCESS, data: updatedObj})
    }
}

export function* liveMessageCategoryRequestSaga(params) {
    const authToken = 'Bearer ' + localStorage.getItem('authToken');

    const json = yield axios.post(`https://api.staging.zoimeet.com/meeting/update/actiontriggeritems`, {
        "categoryId": params.params.category,
        "transcriptId": params.params.msgID,
        "meetingId": params.params.meetingID,
    }, {
        headers: {
            Authorization: authToken
        }
    });

    //console.log(json);

    if (json.data.status) {
        let updatedObj = json.data.data;
        updatedObj["category"] = json.data.category;
        console.log(updatedObj);
        yield put({type: LIVE_MESSAGE_CATEGORY_REQUEST_SUCCESS, data: updatedObj})
    }

    // if (json.data.status) {
    //     let updatedObj = json.data.data;
    //     updatedObj.category = json.data.category;
    //     yield put({type: MESSAGE_CATEGORY_REQUEST_SUCCESS, data: updatedObj})
    // }
}

export function* transcriptMessageCategoryRequestSaga(params) {
    const authToken = 'Bearer ' + localStorage.getItem('authToken');
    console.log(params.params);
    const json = yield axios.post(`https://api.staging.zoimeet.com/meeting/update/actiontriggeritems`, {
        "categoryId": params.params.category,
        "transcriptId": params.params.msgID,
        "meetingId": params.params.meetingID,
    }, {
        headers: {
            Authorization: authToken
        }
    });

    console.log(json);

    if (json.data.status) {
        yield put({type: GET_MEETING_DETAILS_REQUEST, params: {meetingID: params.params.meetingID}})
    }
}

export function* messageHighlightRequestSaga(params) {
    const authToken = 'Bearer ' + localStorage.getItem('authToken');
    if (params.params.highlight === 1) {
        const json = yield axios.post(`https://api.staging.zoimeet.com/meeting/highlights/add
`, {
            "meetingId": params.params.meetingID,
            "transcriptId": params.params.msgID
        }, {
            headers: {
                Authorization: authToken
            }
        });

        console.log(json.data);
        if (json.data.status) {
            let updatedObj = json.data.data;
            updatedObj.highlighted = json.data.highlighted;
            yield put({type: MESSAGE_HIGHLIGHT_REQUEST_SUCCESS, data: updatedObj});
        }
    } else {
        const json = yield axios.post(`https://api.staging.zoimeet.com/meeting/highlights/remove`, {
            "meetingId": params.params.meetingID,
            "transcriptId": params.params.msgID
        }, {
            headers: {
                Authorization: authToken
            }
        });
        if (json.data.status) {
            let updatedObj = json.data.data;
            updatedObj.highlighted = json.data.highlighted;
            yield put({type: MESSAGE_HIGHLIGHT_REQUEST_SUCCESS, data: updatedObj});
        }
    }
}

export function* switchTabsSaga(params) {
}

export function* addHighlightRequestSaga(params) {
    const authToken = 'Bearer ' + localStorage.getItem('authToken');
    const json = yield axios.post(`https://api.staging.zoimeet.com/meeting/notes/liveupdate`, {
        "meetingId": params.params.meetingID,
    }, {
        headers: {
            Authorization: authToken
        }
    });
    console.log(json);
    if (json.data.status) {
        let updatedObj = json.data.data;
        updatedObj.highlighted = json.data.data.highlighted;
        console.log(updatedObj);
        yield put({type: MESSAGE_HIGHLIGHT_REQUEST_SUCCESS, data: updatedObj});
    }
}

export function* fetchTranscriptCommentsRequestSaga(params) {
    const authToken = 'Bearer ' + localStorage.getItem('authToken');
    const json = yield axios.post(`https://api.staging.zoimeet.com/transcripts/comments/get`, {
        "transcriptId": params.params.transcriptID,
    }, {
        headers: {
            Authorization: authToken
        }
    });

    console.log(json.data);

    if (json.data.status) yield put({type: FETCH_TRANSCRIPT_COMMENTS_SUCCESS, data: json.data.data});

    if (!json.data.status) yield put({type: FETCH_TRANSCRIPT_COMMENTS_FAILURE, data: []});
}

export function* fetchFinishedTranscriptCommentsRequestSaga(params) {
    const authToken = 'Bearer ' + localStorage.getItem('authToken');
    const json = yield axios.post(`https://api.staging.zoimeet.com/meeting/comments/get`, {
        "meetingId": params.params.meetingID,
    }, {
        headers: {
            Authorization: authToken
        }
    });

    console.log(json.data);

    if (json.data.status) yield put({type: FETCH_TRANSCRIPT_COMMENTS_SUCCESS, data: json.data.data});

    if (!json.data.status) yield put({type: FETCH_TRANSCRIPT_COMMENTS_FAILURE, data: []});
}

export function* addCommentRequestSaga(params) {
    console.log(params.params);
    const authToken = 'Bearer ' + localStorage.getItem('authToken');
    const json = yield axios.post(`https://api.staging.zoimeet.com/transcripts/comments/add`, {
        "comment": params.params.comment.toString(),
        "transcriptId": params.params.transcriptID,
    }, {
        headers: {
            Authorization: authToken
        }
    });

    console.log(json.data.data);

    if (json.data.status) yield put({type: UPDATE_COMMENTS, data: json.data.data});

    if (!json.data.status) yield put({type: FETCH_TRANSCRIPT_COMMENTS_FAILURE, data: []});
}

export function* addHighlightCommentRequestSaga(params) {
    console.log(params.params);
    const authToken = 'Bearer ' + localStorage.getItem('authToken');
    const json = yield axios.post(`https://api.staging.zoimeet.com/transcripts/comments/add`, {
        "comment": params.params.comment.toString(),
        "transcriptId": params.params.transcriptID,
    }, {
        headers: {
            Authorization: authToken
        }
    });

    console.log(json.data);

    if (json.data.status) yield put({type: UPDATE_HIGHLIGHT_COMMENTS, data: json.data.data});
    //
    // if (!json.data.status) yield put({type: FETCH_TRANSCRIPT_COMMENTS_FAILURE, data: []});
}

export function* deleteTranscriptCommentRequestSaga(params) {
    const authToken = 'Bearer ' + localStorage.getItem('authToken');
    const json = yield axios.post(`https://api.staging.zoimeet.com/transcripts/comments/delete`, {
        "commentId": params.params.commentID,
    }, {
        headers: {
            Authorization: authToken
        }
    });

    console.log(json);
}

export function* addCustomNoteRequestSaga(params) {
    console.log(params.params);

    const authToken = 'Bearer ' + localStorage.getItem('authToken');
    const json = yield axios.post(`https://api.staging.zoimeet.com/meeting/comments/add`, {
        "meetingId": params.params.meetingID,
        "comment": params.params.note
    }, {
        headers: {
            Authorization: authToken
        }
    });

    console.log(json);
    if (json.data.status) {
        let updatedObj = json.data.data;
        updatedObj.highlighted = json.data.data.highlighted;
        yield put({type: MESSAGE_HIGHLIGHT_REQUEST_SUCCESS, data: updatedObj});
    }
}

export function* deleteMeetingRequestSaga(params) {

    const authToken = 'Bearer ' + localStorage.getItem('authToken');
    const json = yield axios.post(`https://api.staging.zoimeet.com/meeting/delete`, {
        "meetingId": params.params.meetingID,
    }, {
        headers: {
            Authorization: authToken
        }
    });

    if (params.params.isSearch) yield put({type: SHOW_UPDATED_SEARCH, data: params.params.meetingID});
    if (json.data.status) yield put({type: ARCHIVED_MEETINGS_REQUEST, params: {pageNo: params.params.currentPage}})
}

export function* deleteMeetingFromDashboardRequestSaga(params) {

    const authToken = 'Bearer ' + localStorage.getItem('authToken');
    const json = yield axios.post(`https://api.staging.zoimeet.com/meeting/delete`, {
        "meetingId": params.params.meetingID,
    }, {
        headers: {
            Authorization: authToken
        }
    });

    if (json.data.status) window.location.href = archives_url;
}

export function* deleteAccountRequestSaga() {
    const authToken = 'Bearer ' + localStorage.getItem('authToken');

    const json = yield axios.post(`https://api.staging.zoimeet.com/user/deleteaccount`, {},
        {
            headers: {
                Authorization: authToken
            }
        }
    );

    if (json.data.status) {
        localStorage.removeItem('authToken');
        setTimeout(() => window.location.href = login_url, 3000)
    }
}

export function* updateDefaultCallOptRequestSaga(params) {
    const authToken = 'Bearer ' + localStorage.getItem('authToken');

    const json = yield axios.post(`https://api.staging.zoimeet.com/user/update/calloption`, {
            "option": params.params.opt.toString()
        },
        {
            headers: {
                Authorization: authToken
            }
        }
    );

    console.log(json);
}

export function* uploadProfilePictureRequestSaga(params) {
    const authToken = 'Bearer ' + localStorage.getItem('authToken');
    console.log(params.params);

    const json = yield axios.post(`https://utilities.staging.zoimeet.com/profile/upload`, {
            "Profile": params.params.form
        },
        {
            headers: {
                Authorization: authToken,
            }
        }
    );

    console.log(json);
}

export function* closeProfilePictureModalSaga() {
    setTimeout(yield put({type: SESSION_VALIDITY_REQUEST}), 3000);
}

export function* getMeetingHiglightCommentsRequestSaga(params) {

    console.log(params.params);

    let commentsArr = [];

    const authToken = 'Bearer ' + localStorage.getItem('authToken');

    for (let i = 0; i < params.params.highlightedIDs.length; i++) {

        const json = yield axios.post(`https://api.staging.zoimeet.com/transcripts/comments/get`, {
                "transcriptId": params.params.highlightedIDs[i]
            },
            {
                headers: {
                    Authorization: authToken
                }
            }
        );

        if (json.data.data !== undefined) {

            for (let j = 0; j < json.data.data.length; j++) {
                commentsArr.push(json.data.data[j]);
            }
        }
    }

    if (commentsArr.length > 0)

        yield put({type: GET_MEETING_HIGHLIGHT_COMMENTS_REQUEST_SUCCESS, data: commentsArr});
    yield put({type: MEETING_HIGHLIGHT_COMMENTS_LOADED});
}

export function* updateMeetingNameRequestSaga(params) {
    console.log(params.params);
    const authToken = 'Bearer ' + localStorage.getItem('authToken');

    const json = yield axios.post(`https://api.staging.zoimeet.com/meeting/update/meetingname`, {
            "meetingId": params.params.meetingID,
            "meetingName": params.params.meetingName.trim()
        },
        {
            headers: {
                Authorization: authToken
            }
        }
    );

    console.log(json);
}

export function* checkResetTokenValidityRequestSaga(params) {
    const json = yield axios.post(`https://api.staging.zoimeet.com/checkresettoken`, {
            "token": params.params.token,
        }
    );

    if (!json.data.status) {
        yield put({type: RESET_TOKEN_NOT_VALID});
        window.location.href = forgot_url;
    }
}

export function* resetPasswordRequestSaga(params) {
    console.log(params.params);

    const json = yield axios.post(`https://api.staging.zoimeet.com/resetpassword`, {
            "token": params.params.token,
            "password": params.params.password,
        }
    );

    if (json.data.status)
        yield put({type: SHOW_RESET_PASSWORD_SUCCESS, data: json.data.message});
    else
        yield put({type: SHOW_RESET_PASSWORD_FAILURE, data: json.data.message});

}

export function* subscriptionCancellationRequestSaga() {
    const authToken = 'Bearer ' + localStorage.getItem('authToken');

    const json = yield axios.post(`https://api.staging.zoimeet.com/payment/cancelplan`, {
    },
        {
            headers: {
                Authorization: authToken
            }
        }
    );

    if(json.data.status) {
        yield put({type: SUBSCRIPTION_CANCELLATION_REQUEST_SUCCESS});
        setTimeout(() => window.location.href = settings_url, 5000);
    }

    else {
        yield put({type: SUBSCRIPTION_CANCELLATION_REQUEST_FAILURE});
    }
}

function* actionWatcher() {
    yield takeLatest(LOGIN_REQUEST, loginSaga);
    yield takeLatest(REGISTRATION_REQUEST, registrationSaga);
    yield takeLatest(FORGOT_PASSWORD_REQUEST, forgotPasswordRequestSaga);
    yield takeLatest(LOGOUT_REQUEST, logoOutSaga);
    yield takeLatest(SESSION_VALIDITY_REQUEST, sessionValiditySaga);
    yield takeLatest(UPDATE_USER_INFO_REQUEST, updateUserInfoSaga);
    yield takeLatest(UPDATE_USER_PASSWORD_REQUEST, updateUserPasswordSaga);
    yield takeLatest(MEETING_CALLING_IN_REQUEST, meetingCallingInSaga);
    yield takeLatest(ARCHIVED_MEETINGS_REQUEST, archivedMeetingsRequestSaga);
    yield takeLatest(GET_MEETING_DETAILS_REQUEST, getMeetingDetailsRequestSaga);
    yield takeLatest(GET_ALL_MEETING_DETAILS_REQUEST, getAllMeetingDetailsRequestSaga);
    yield takeLatest(GET_LIVE_MEETING_DETAILS_REQUEST, getLiveMeetingDetailsRequestSaga);
    yield takeLatest(GET_ALL_LIVE_MEETING_DETAILS_REQUEST, getAllLiveMeetingDetailsRequestSaga);
    yield takeLatest(SET_MEETING_NAME_AND_DATE, setMeetingNameAndDateSaga);
    yield takeLatest(CALENDAR_SYNC_REQUEST, updateCalenderSyncSaga);
    yield takeLatest(CALENDAR_SYNC_REQUEST_REGISTER, updateCalenderRegisterSyncSaga);
    yield takeLatest(GET_USER_CALENDAR_INFO_REQUEST, getUserCalendarInfoRequestSaga);
    yield takeLatest(GET_USER_CALENDAR_INFO_REGISTER_REQUEST, getUserCalendarInfoRegisterRequestSaga);
    yield takeLatest(GET_UPCOMING_USER_EVENTS, getUpcomingUserEventsSaga);
    yield takeLatest(GET_MEETINGS_FROM_SEARCH_REQUEST, getMeetingsFromSearchRequestSaga);
    yield takeLatest(CHANGE_MAKE_CALL_STATUS_REQUEST, changeMakeCallStatusRequestSaga);
    yield takeLatest(GET_TRIGGERS_REQUEST, getTriggersRequestSaga);
    yield takeLatest(DELETE_TRIGGER_REQUEST, deleteTriggerRequestSaga);
    yield takeLatest(ADD_TRIGGER_REQUEST, addTriggerRequestSaga);
    yield takeLatest(OPEN_EDIT_TRANSCRIPT_MODAL, openEditTranscriptModalSaga);
    yield takeLatest(OPEN_EDIT_TRANSCRIPT_MODAL_LIVE, openEditTranscriptLiveModalSaga);
    yield takeLatest(CLOSE_EDIT_TRANSCRIPT_MODAL, closeEditTranscriptModalSaga);
    yield takeLatest(UPDATE_TRANSCRIPT_REQUEST, updateTranscriptRequestSaga);
    yield takeLatest(UPDATE_TRANSCRIPT_LIVE_REQUEST, updateTranscriptLiveRequestSaga);
    yield takeLatest(SEARCH_IN_MEETING_REQUEST, searchInMeetingRequestSaga);
    yield takeLatest(TRIGGER_DOWNLOAD, triggerDownloadSaga);
    yield takeLatest(GET_WORD_CLOUD_REQUEST, getWordCloudRequestSaga);
    yield takeLatest(MESSAGE_CATEGORY_REQUEST, messageCategoryRequestSaga);
    yield takeLatest(LIVE_MESSAGE_CATEGORY_REQUEST, liveMessageCategoryRequestSaga);
    yield takeLatest(TRANSCRIPT_MESSAGE_CATEGORY_REQUEST, transcriptMessageCategoryRequestSaga);
    //yield takeLatest(MESSAGE_HIGHLIGHT_REQUEST, messageHighlightRequestSaga);
    yield takeLatest(SWITCH_TABS, switchTabsSaga);
    yield takeLatest(MESSAGE_HIGHLIGHT_REQUEST, addHighlightRequestSaga);
    yield takeLatest(FETCH_TRANSCRIPT_COMMENTS_REQUEST, fetchTranscriptCommentsRequestSaga);
    yield takeLatest(FETCH_FINISHED_TRANSCRIPT_COMMENTS_REQUEST, fetchFinishedTranscriptCommentsRequestSaga);
    yield takeLatest(ADD_COMMENT_REQUEST, addCommentRequestSaga);
    yield takeLatest(ADD_HIGHLIGHT_COMMENT_REQUEST, addHighlightCommentRequestSaga);
    yield takeLatest(DELETE_TRANSCRIPT_COMMENT_REQUEST, deleteTranscriptCommentRequestSaga);
    yield takeLatest(DELETE_TRANSCRIPT_HIGHLIGHT_COMMENT_REQUEST, deleteTranscriptCommentRequestSaga);
    yield takeLatest(ADD_CUSTOM_NOTE_REQUEST, addCustomNoteRequestSaga);
    yield takeLatest(DELETE_MEETING_REQUEST, deleteMeetingRequestSaga);
    yield takeLatest(DELETE_MEETING_FROM_DASHBOARD_REQUEST, deleteMeetingFromDashboardRequestSaga);
    yield takeLatest(DELETE_ACCOUNT_REQUEST, deleteAccountRequestSaga);
    yield takeLatest(UPDATE_DEFAULT_CALL_OPT_REQUEST, updateDefaultCallOptRequestSaga);
    yield takeLatest(GET_MEETING_HIGHLIGHT_COMMENTS_REQUEST, getMeetingHiglightCommentsRequestSaga);
    yield takeLatest(UPLOAD_PROFILE_PICTURE_REQUEST, uploadProfilePictureRequestSaga);
    yield takeLatest(CLOSE_PROFILE_PICTURE_MODAL, closeProfilePictureModalSaga);
    yield takeLatest(UPDATE_MEETING_NAME_REQUEST, updateMeetingNameRequestSaga);
    yield takeLatest(CHECK_RESET_TOKEN_VALIDITY_REQUEST, checkResetTokenValidityRequestSaga);
    yield takeLatest(RESET_PASSWORD_REQUEST, resetPasswordRequestSaga);
    yield takeLatest(SUBSCRIPTION_CANCELLATION_REQUEST, subscriptionCancellationRequestSaga);
}

export default function* rootSaga() {
    yield all([
        actionWatcher()
    ]);
}
