import {connect} from "react-redux";
import EditTranscriptModal from "../component/EditTranscriptModal";
import {
    closeEditTranscriptModalAction, closeEditTranscriptModalLiveAction, flushContentResultAction,
    updateTranscriptAction,
    updateTranscriptLiveAction
} from "../../../actions/meetingActions";

const mapStateToProps = (state) => ({
    meeting: state.meeting
});

const mapDispatchToProps = (dispatch) => ({
    closeEditTranscriptModal: () => dispatch(closeEditTranscriptModalAction()),
    closeEditTranscriptModalLive: () => dispatch(closeEditTranscriptModalLiveAction()),
    updateTranscript: (meetingID, transcriptID, transcript) => dispatch(updateTranscriptAction(meetingID, transcriptID, transcript)),
    updateTranscriptLive: (meetingID, transcriptID, transcript) => dispatch(updateTranscriptLiveAction(meetingID, transcriptID, transcript)),
    flushContentResult: () => dispatch(flushContentResultAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTranscriptModal);