import React, {Component} from 'react';
import menuImg from "../../../assets/menu.svg";
import zoiLogo from "../../../assets/zoimeet-logo.jpg";
import copyImg from "../../../assets/copy.svg";
import flagImg from "../../../assets/flag1.svg";
import settingsImg from "../../../assets/side-nav-icon-4.png";

import dateFormat from "dateformat";
import moment from "moment";

import '../style/Header.css';
import {OverlayTrigger, Tooltip} from "react-bootstrap";

class Header extends Component {

    constructor(props) {
        super(props);
        this.buttonText = props.buttonText;
        this.navigateTo = props.navigateTo;
        this.navText = props.navText;
        this.guestEnable = props.guestEnable;
        this.guestModalTarget = props.guestClickTarget;

        this.state = {
            windowWidth: window.innerWidth,
            updateMeetingName: false,
            meetingName: localStorage.getItem("dashboardMeetingName")
        };

        this.update = this.update.bind(this);
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    update(e) {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({[name]: value});
    }

    handleResize = (e) => {
        this.setState({windowWidth: window.innerWidth})
    };

    formatAMPM = (date) => {
        console.log(date);
        let hours = parseInt(date.split(":")[0]);
        let minutes = parseInt(date.split(":")[1]);
        let ampm = hours >= 12 ? 'AM' : 'PM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        let strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    };

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.state.updateMeetingName && this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({updateMeetingName: false});
            this.props.updateMeetingNameA(this.props.meeting.archivedMeetingTranscripts[0].fk_meeting, this.state.meetingName);
            localStorage.setItem("dashboardMeetingName", this.state.meetingName);
        }
    }

    render() {
        const {windowWidth, updateMeetingName, meetingName} = this.state;
        const {page, firstName} = this.props;

        return (
            <div
                className={windowWidth > 1024 ?
                    "header d-flex align-items-center justify-content-between w-100" :
                    "header-mobile d-flex align-items-center justify-content-between w-100"}>

                <div className="d-flex align-items-center">
                    {page === "home" &&
                    <img
                        src={menuImg}
                        alt=""
                        className="header-name-img"/>
                    }

                    {page === "dashboard" &&

                    <div className={windowWidth > 1024 ? "archive-header-div" : "archive-header-div-mobile"}>

                        {!updateMeetingName ?
                            <h2
                                onClick={() => this.setState({updateMeetingName: true})}
                            >
                                {meetingName}
                            </h2> :
                            <OverlayTrigger
                                placement={"bottom"}
                                overlay={
                                    <Tooltip style={{fontSize: ".75rem", fontFamily: "CeraPRO-Regular"}}
                                             id={`tooltip-bottom`}>
                                        Press ⏎ to Save
                                    </Tooltip>
                                }
                            >
                                <input
                                    ref={this.wrapperRef}
                                    autoComplete="off"
                                    type="text"
                                    name="meetingName"
                                    className="meeting-name-input-active"
                                    value={this.state.meetingName}
                                    onChange={this.update}
                                    onKeyPress={(ev) => {
                                        if (ev.key === 'Enter') {
                                            this.setState({updateMeetingName: false});
                                            this.props.updateMeetingNameA(this.props.meeting.archivedMeetingTranscripts[0].fk_meeting, this.state.meetingName);
                                            localStorage.setItem("dashboardMeetingName", this.state.meetingName);
                                        }
                                    }}
                                />
                            </OverlayTrigger>
                        }

                        <p>
                            {dateFormat(
                                moment(
                                    moment.utc(
                                        new Date(localStorage.getItem("dashboardMeetingDateAndTime")).toString()
                                    )
                                ).local(),
                                'ddd, dd-mm-yyyy', true) + ", " +
                            dateFormat(
                                moment(
                                    moment.utc(
                                        new Date(localStorage.getItem("dashboardMeetingDateAndTime")).toString()
                                    )
                                ).local(),
                                'HH:MM', true)
                                //this.formatAMPM(new Date(localStorage.getItem("dashboardMeetingDateAndTime")))
                            }
                        </p>
                    </div>
                    }

                    {page === "archives" &&
                    <img
                        src={copyImg}
                        alt=""
                        className={windowWidth > 1024 ?
                            "header-name-img margin-minus-five" :
                            "header-name-img margin-minus-three"
                        }
                    />
                    }

                    {page === "trigger" &&
                    <img
                        src={flagImg}
                        alt=""
                        style={{margin: "0"}}
                        className={windowWidth > 1024 ?
                            "header-name-img margin-minus-five" :
                            "header-name-img margin-minus-three"
                        }
                    />
                    }

                    {page === "settings" &&
                    <img
                        src={settingsImg}
                        alt=""
                        className="header-name-img"/>
                    }

                    {page === "home" &&
                    <p className="header-name fw-b f-16 mb-0">
                        Welcome {firstName}
                    </p>
                    }

                    {page === "archives" &&
                    <p className="header-name fw-b f-16 mb-0">
                        Meeting Archive
                    </p>
                    }

                    {page === "trigger" &&
                    <p
                        className="header-name fw-b f-16 mb-0">
                        Triggers
                    </p>
                    }

                    {page === "settings" &&
                    <p className="header-name fw-b f-16 mb-0">
                        Settings
                    </p>
                    }
                </div>

                <img
                    className="header-logo"
                    src={zoiLogo} alt=""/>
            </div>
        );
    }
}

export default Header;


