import {connect} from "react-redux";
import SettingsMenuWidget from "../component/SettingsMenuWidget";
import {handleSettingsWidgetAction} from "../../../actions/modalActions";

const mapStateToProps = (state) => ({
    auth: state.auth,
    widget: state.widget
});

const mapDispatchToProps = (dispatch) => ({
    handleSettingsWidget: (choice) => dispatch(handleSettingsWidgetAction(choice))
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsMenuWidget);