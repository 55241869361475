import {
    LOGOUT_REQUEST,
    SESSION_VALIDITY_REQUEST,
    DELETE_ACCOUNT_REQUEST,
    OPEN_DELETE_MODAL,
    OPEN_PROFILE_PICTURE_MODAL,
    CLOSE_PROFILE_PICTURE_MODAL,
    UPLOAD_PROFILE_PICTURE_REQUEST,
    CHECK_RESET_TOKEN_VALIDITY_REQUEST,
    CLOSE_RESET_PASSWORD_TOAST,
    SUBSCRIPTION_CANCELLATION_REQUEST
} from "./types";

export const checkSessionValidityAction = (emailID) => ({
    type: SESSION_VALIDITY_REQUEST,
    params: {emailID}
});

export const handleCancelSubAction = () => ({
    type: SUBSCRIPTION_CANCELLATION_REQUEST,
});

export const logOutRequestAction = () => ({
    type: LOGOUT_REQUEST
});

export const deleteAccountAction = () => ({
    type: DELETE_ACCOUNT_REQUEST,
});

export const openDeleteModalAction = () => ({
    type: OPEN_DELETE_MODAL
});

export const openProfilePictureAction = () => ({
    type: OPEN_PROFILE_PICTURE_MODAL
});

export const closeProfilePictureAction = () => ({
    type: CLOSE_PROFILE_PICTURE_MODAL
});

export const profilePictureUploadAction = (form) => ({
    type: UPLOAD_PROFILE_PICTURE_REQUEST,
    params: {form}
});

export const checkTokenValidityAction = (token) => ({
    type: CHECK_RESET_TOKEN_VALIDITY_REQUEST,
    params: {token}
});

export const closeResetPasswordModalAction = () => ({
   type: CLOSE_RESET_PASSWORD_TOAST
});
