import React, {Component} from "react";

import dateFormat from "dateformat";
import {archives_url, dashboard_url} from "../../Common";
import moment from "moment";

import "../style/ArchivesComponent.css";

class ArchivesComponent extends Component {
    constructor() {
        super();

        this.state = {
            meetingName: '',
        }
    }

    componentDidMount() {
        this.props.getArchivedMeetings(1);
    }

    convertSecondstoTime = (seconds) => {

        let hours = Math.floor(seconds / 3600);
        let minutes = Math.floor((seconds - (hours * 3600)) / 60);
        let seconds2 = seconds - (hours * 3600) - (minutes * 60);

        let timeString = "";
        timeString = hours.toString().padStart(2, '0') + ':' +
            minutes.toString().padStart(2, '0') + ':' +
            seconds2.toString().padStart(2, '0');

        if (hours < 1 && minutes > 9)
            timeString =
                timeString = hours.toString().padStart(2, '0') + ':' +
                    minutes.toString().padStart(2, '0') + ':' +
                    seconds2.toString().padStart(2, '0');

        return timeString;
    };

    render() {
        const {meetingName} = this.state;
        const {meeting} = this.props;

        return (

            <div
                style={{height: "120px", width: "100%", position: "relative"}}
            >

                <div className="card w-100 box-shadow border-0 mb-3">

                    <div className="p-1">

                        <div style={{display: "flex"}}>

                            <p className="latest-meetings-title fw-b f-12 f-blue">
                                Latest Meetings
                            </p>

                            {meeting.archivedMeetings.length > 0 &&
                            <p className="float-right fw-b f-12 f-blue"
                               onClick={() => window.location.href = archives_url}
                               style={{
                                   cursor: "pointer",
                                   textDecoration: "underline",
                                   float: "right",
                                   right: 0,
                                   margin: "1rem 1rem 0 auto"
                               }}
                            >
                                All meetings
                            </p>
                            }
                        </div>

                        {meeting.archivedMeetings.length > 0 ?
                            <div className="set-card-sm-height table f-medium">

                                <table className="table f-medium" style={{width: "100%", fontSize: "11px"}}>
                                    <tr className="meeting-table-header-row">
                                        <th
                                            scope="col"
                                            className="meeting-table-header border-bottom ps-0"
                                        >Meeting Title
                                        </th>
                                        <th
                                            scope="col"
                                            className="meeting-table-header border-bottom ps-0"
                                        >Duration
                                        </th>
                                        <th
                                            scope="col"
                                            className="meeting-table-header border-bottom ps-0"
                                        >Date
                                        </th>
                                    </tr>

                                    {(meetingName.length < 1 || (!meeting.showNothingFound && !meeting.archivedMeetingsLoading && meeting.archivedMeetingsFetched && meeting.meetingSearchResults.length < 1)) &&
                                    meeting.archivedMeetings.map((key, i) =>
                                        <tr
                                            style={{cursor: "pointer", margin: "0 0 0 -2rem"}}
                                            onClick={() => {
                                                window.location.href = `${dashboard_url}?id=${key.meeting_id}`;
                                                localStorage.setItem("dashboardMeetingName", key.meeting_name);
                                                localStorage.setItem("dashboardMeetingDateAndTime", key.created_time);
                                                localStorage.setItem("dashboardMeetingAudio", key.recordUrl);
                                                localStorage.setItem("currentMeeting", key.meeting_id);
                                                localStorage.setItem("currentMeetingPin", key.pin);
                                                localStorage.setItem("currentMeetingDuration", key.duration);
                                            }}
                                        >
                                            <td
                                                className="meeting-table-header"
                                            >{key.meeting_name}
                                            </td>
                                            <td
                                                className="meeting-table-header"
                                            >
                                                {this.convertSecondstoTime(key.duration)}
                                            </td>
                                            <td
                                                className="meeting-table-header"
                                            > {dateFormat(
                                                moment(
                                                    moment.utc(
                                                        new Date(key.created_time).toString()
                                                    )
                                                ).local(),
                                                'ddd, mmm dd, yyyy', true)
                                            }</td>
                                        </tr>
                                    )}
                                </table>
                            </div>
                            :
                            <p className="no-upcoming-meetings-text archived">
                                No archived meetings found
                            </p>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default ArchivesComponent;