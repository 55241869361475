import React, {Component} from 'react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {Spinner} from "react-bootstrap";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import menuImg from "../../../assets/menu.svg";
import copyImg from "../../../assets/copy.svg";
import flagImg from "../../../assets/flag1.svg";
import settingsImg from "../../../assets/side-nav-icon-4.png";
import activeCall from "../../../assets/activeCall.png";

import * as Constants from '../../Common';

import '../style/Sidebar.css';

class Sidebar extends Component {

    constructor() {
        super();

        this.state = {
            showSidebar: true,
            home: false,
            archivess: false,
            statistics: false,
            trigger: false,
            upgradeActive: false,
            settings: false,
            logOutOptOpen: false,
            windowWidth: window.innerWidth
        }
    }

    handleResize = () => {
        this.setState({windowWidth: window.innerWidth});
    }

    selectMenuItem = (arg) => {
        if (arg === "home") {
            this.setState({
                home: true,
                archives: false,
                calendar: false,
                statistics: false,
                trigger: false,
                upgradeActive: false,
                settings: false
            });

            window.location.href = (Constants.home_url);
        }
        if (arg === "archives") {
            this.setState({
                home: false,
                archives: true,
                calendar: false,
                statistics: false,
                trigger: false,
                upgradeActive: false,
                settings: false
            });
            window.location.href = (Constants.archives_url);
        }
        if (arg === "statistics") this.setState({
            home: false,
            archives: false,
            calendar: false,
            statistics: true,
            trigger: false,
            upgradeActive: false,
            settings: false
        });
        if (arg === "trigger") {
            this.setState({
                home: false,
                archives: false,
                calendar: false,
                statistics: false,
                trigger: true,
                upgradeActive: false,
                settings: false
            });

            window.location.href = (Constants.triggers_url);
        }
        if (arg === "upgrade") this.setState({
            home: false,
            archives: false,
            calendar: false,
            statistics: false,
            trigger: false,
            upgradeActive: true,
            settings: false
        });
        if (arg === "settings") {
            this.setState({
                home: false,
                archives: false,
                calendar: false,
                statistics: false,
                trigger: false,
                upgradeActive: false,
                settings: true
            });

            window.location.href = (Constants.settings_url);
        }
    };

    visitLiveCalls = () => {
        if (this.props.auth.userActiveCalls.length > 0) {

            for (let i = 0; i < this.props.auth.userActiveCalls.length; i++) {

                window.open(
                    `/live?pin=${this.props.auth.userActiveCalls[i].conference_pin}&id=${this.props.auth.userActiveCalls[i].id}`,
                    "",
                    `width=${window.innerWidth / 2.35},height=${window.innerHeight * 1.2}, left=0,top=0`
                );
            }
        }
    };

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    render() {
        const {
            windowWidth
        } = this.state;

        const {
            auth,
        } = this.props;

        return (

            <div className={windowWidth > 1024 ? "sidebar" : "sidebar-mobile"}>

                {false &&
                <div className="profile">
                    {!auth.userLoading ?
                        auth.user.fullname.split(' ').map(x => x[0]).join('')
                        :
                        <Spinner
                            className="user-avatar-loading-spinner"
                            animation="border"
                            variant="light"/>
                    }
                </div>
                }

                <ul className={windowWidth > 1024 ? "menu-list" : "menu-list-mobile"}>

                    {!auth.userLoading &&
                    <OverlayTrigger
                        placement={"right"}
                        overlay={
                            <Tooltip style={{fontSize: ".6rem", fontFamily: "CeraPRO-Regular"}}
                                     id={`tooltip-right`}>
                                New Profile Picture
                            </Tooltip>
                        }
                    >

                        {auth.user.image ?
                            <div
                                onClick={() => this.props.openProfilePicture()}
                            >
                                <LazyLoadImage
                                    alt={"Profile Photo"}
                                    effect="blur"
                                    className={windowWidth > 1024 ? "user-profile" : "user-profile-mobile"}
                                    src={auth.user.image}/>
                            </div>
                            :
                            <div
                                onClick={() => this.props.openProfilePicture()}
                                className={windowWidth > 1024 ? "user-avatar" : "user-avatar-mobile"}
                            >
                                {
                                    auth.user.fullname.split(' ').map(x => x[0]).join('')
                                }
                            </div>
                        }
                    </OverlayTrigger>
                    }

                    <div className={windowWidth > 1024 ? "sidebar-list" : "sidebar-list-mobile"}>

                        <OverlayTrigger
                            placement={"right"}
                            overlay={
                                <Tooltip style={{fontSize: ".6rem", fontFamily: "CeraPRO-Regular"}}
                                         id={`tooltip-right`}>
                                    Home
                                </Tooltip>
                            }
                        >
                            <li>
                                <img
                                    className={windowWidth > 1024 ? "list-img" : "list-img-mobile"}
                                    onClick={() => window.location.href = (Constants.home_url)}
                                    src={menuImg}
                                    alt=""/>
                            </li>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement={"right"}
                            overlay={
                                <Tooltip style={{fontSize: ".6rem", fontFamily: "CeraPRO-Regular"}}
                                         id={`tooltip-right`}>
                                    Archives
                                </Tooltip>
                            }
                        >
                            <li>
                                <img
                                    className={windowWidth > 1024 ? "list-img" : "list-img-mobile"}
                                    onClick={() => window.location.href = (Constants.archives_url)}
                                    src={copyImg}
                                    alt=""/>
                            </li>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement={"right"}
                            overlay={
                                <Tooltip style={{fontSize: ".6rem", fontFamily: "CeraPRO-Regular"}}
                                         id={`tooltip-right`}>
                                    Triggers
                                </Tooltip>
                            }
                        >
                            <li>
                                <img
                                    className={windowWidth > 1024 ? "list-img" : "list-img-mobile"}
                                    onClick={() => window.location.href = (Constants.triggers_url)}
                                    src={flagImg}
                                    alt=""/>
                            </li>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement={"right"}
                            overlay={
                                <Tooltip style={{fontSize: ".6rem", fontFamily: "CeraPRO-Regular"}}
                                         id={`tooltip-right`}>
                                    Settings
                                </Tooltip>
                            }
                        >
                            <li>
                                <img
                                    className={windowWidth > 1024 ? "list-img" : "list-img-mobile"}
                                    onClick={() => window.location.href = (Constants.settings_url)}
                                    src={settingsImg}
                                    alt=""/>
                            </li>
                        </OverlayTrigger>

                        {auth.userActiveCalls.length > 0 &&
                        <OverlayTrigger
                            placement={"right"}
                            overlay={
                                <Tooltip style={{fontSize: ".6rem", fontFamily: "CeraPRO-Regular"}}
                                         id={`tooltip-right`}>
                                    {auth.userActiveCalls.length === 1 ?

                                        `You have 1 ongoing active call, click to visit the page` : `You have ${auth.userActiveCalls.length} ongoing active calls, click to visit the pages`}
                                </Tooltip>
                            }
                        >
                            <li>
                                <img
                                    onClick={() => this.visitLiveCalls()}
                                    className={windowWidth > 1024 ? "active-call" : "active-call-mobile"}
                                    src={activeCall}
                                    alt=""/>
                            </li>
                        </OverlayTrigger>
                        }
                    </div>
                </ul>
            </div>
        );
    }
}

export default Sidebar;