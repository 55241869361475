import 'd3-transition';
import { select } from 'd3-selection';
import React, { Component } from 'react';
import ReactWordcloud from 'react-wordcloud';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
class WordCloud extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: null,
            words: this.props.words
        };
    }

    callbacks = {
        getWordTooltip: word =>
            `The word ${word.text} appears ${word.value} times`,
        onWordClick: this.getCallback('onWordClick'),
        onWordMouseOut: this.getCallback('onWordMouseOut'),
        onWordMouseOver: this.getCallback('onWordMouseOver'),
    };

    getCallback(callback) {
        return function (word, event) {
            const isActive = callback !== 'onWordMouseOut';
            const element = event.target;
            // const computedFontSize = window.getComputedStyle(element).fontSize;
            // console.log(isActive ? "Active" + computedFontSize + isActive + callback : "Passive" + computedFontSize + isActive + callback);
            const text = select(element);
            text
                // .on('click', () => {
                //     if (isActive) {
                //         // window.open(`https://duckduckgo.com/?q=${word.text}`, '_blank');
                //         scope.props.searchKeyWord(word.text)
                //     }
                // })
                .transition()
                .attr('background-color', 'black')
                // .attr('font-size', isActive ? '1.1em':'1em')
                .attr('text-decoration', isActive ? 'underline' : 'none');
        };
    }

    render() {

        if (this.state.words != null) {
            console.log("ReactWordcloud rendering");
            return (
                <ReactWordcloud options={{
                    colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
                    enableTooltip: true,
                    deterministic: false,
                    fontFamily: "impact",
                    fontSizes: [5, 60],
                    fontStyle: "normal",
                    fontWeight: "normal",
                    padding: 1,
                    rotations: 3,
                    rotationAngles: [0, 90],
                    scale: "sqrt",
                    spiral: "archimedean",
                    transitionDuration: 1000
                }} words={this.state.words} callbacks={this.callbacks} />
            );
        } else {
            console.log("ReactWordcloud not rendering");

            return (
                <div/>
            );
        }
    }
}


export default WordCloud;