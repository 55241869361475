import React, {Component} from 'react';
import Header from '../../components/Header/component/Header';
import * as Constants from '../../components/Common';

import Modal from "../../components/Modal/container/Modal";
import {connect} from "react-redux";

import './Login.css'

/** Handling current scope.*/

class Login extends Component {


    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            emailError: '',
            passwordError: '',
            loginError: '',
            windowWidth: window.innerWidth
        }
    }

    handleResize = (e) => {
        this.setState({windowWidth: window.innerWidth})
    }

    /**
     * invoked after render method
     * https://reactjs.org/docs/state-and-lifecycle.html
     */
    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        if(localStorage.getItem("authToken") !== null)
        window.location.href = (Constants.home_url);
    }

    /**
     * checking if already logged in user
     * if true the redirect to home directly without login credentails
     */

    render() {

        const {windowWidth} = this.state;

        return (

            <div className={windowWidth > 720 ? "login-outer": ""}>

                <Header/>

                <Modal
                    title={"Welcome Back"}
                    type={"login"}
                />
            </div>
        );
    }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Login);


