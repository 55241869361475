import React from 'react';

import HighlightBar from './HightLightBar';

export default function Bar(props) {
    const {
        duration,
        highlights,
        curTime,
        onTimeUpdate,
        onHighlightClick,
        setPlaying,
    } = props;

    const curPercentage = (curTime / duration) * 100;

    function calcClickedTime(e) {
        const clickPositionInPage = e.pageX;
        const bar = document.querySelector('.bar__progress');
        const barStart = bar.getBoundingClientRect().left + window.scrollX;
        const barWidth = bar.offsetWidth;
        const clickPositionInBar = clickPositionInPage - barStart;
        const timePerPixel = duration / barWidth;
        return timePerPixel * clickPositionInBar;
    }

    function handleTimeDrag(e) {
        onTimeUpdate(calcClickedTime(e));

        const updateTimeOnMove = (eMove) => {
            onTimeUpdate(calcClickedTime(eMove));
        };

        document.addEventListener('mousemove', updateTimeOnMove);

        document.addEventListener('mouseup', () => {
            document.removeEventListener('mousemove', updateTimeOnMove);
        });
    }

    return (
        <div className='bar'>
            <div
                className='bar__progress'
                style={{
                    background: `linear-gradient(to right, white ${curPercentage}%, white 0)`,
                }}
                onMouseDown={(e) => handleTimeDrag(e)}
            >
        <span
            className='bar__progress__knob'
            style={{left: `${curPercentage - 1}%`}}
        />
                {highlights &&
                highlights.map((highlight) => {
                        return (
                            (highlight["category"] === 2 || highlight["category"] === 3 || highlight["category"] === 4) &&
                            <HighlightBar
                                key={highlight["start_time"]}
                                highlight={highlight}
                                duration={highlight["start_time"]+5}
                                onHighlightClick={onHighlightClick}
                                setPlaying={setPlaying}
                            />
                        )
                    }
                )}
            </div>
        </div>
    );
}
