import React, {Component} from "react";

import inviteWidget from "../../../assets/invite_widget.png";

import Loader from "react-loader-spinner";

import "../style/InviteWidget.css";

let scope;

let zoomExpression = /https:\/\/?([\w+]*\.|)zoom\.us\/j\/(?<meeting_id>[0-9]+)(\?pwd=(?<meeting_pwd>\w+)|)/g;
let zoomRegex = new RegExp(zoomExpression);
let msTeamExpression = /https:\/\/?([\w+]*\.|)microsoft.com\/l\/?([\w\S+]*\.)?\/(([^"]+)|([^\s]+))/g;
let msRegex = new RegExp(msTeamExpression);
let lifesizeExpression = /https:\/\/?([\w+]*\.|)[0-9a-zA-Z]*.lifesizecloud.com\/[0-9a-zA-Z]*/g;
let lifesizeRegex = new RegExp(lifesizeExpression);
let meetExpression = /https:\/\/?([\w+]*\.|)[0-9a-zA-Z]*.meet.google.com\/[0-9a-zA-Z]*-[0-9a-zA-Z]*-[0-9a-zA-Z]*/g;
let meetRegex = new RegExp(meetExpression);

class InviteWidget extends Component {
    constructor() {
        super();
        scope = this;
        this.state = {
            meetingName: '',
            meetingNameError: '',
            meetingURL: '',
            meetingURLError: '',
            meetingPasscode: '',
            meetingPasscodeError: '',
            windowWidth: window.innerWidth
        }
    }

    extractURL = (entry) => {
        zoomRegex = new RegExp(zoomExpression);
        msRegex = new RegExp(msTeamExpression);
        lifesizeRegex = new RegExp(lifesizeExpression);
        meetRegex = new RegExp(meetExpression);
        let results = [];
        if (typeof entry != 'undefined') {
            let m;
            while ((m = zoomRegex.exec(entry)) !== null) {
                if (m.index === zoomRegex.lastIndex) {
                    zoomRegex.lastIndex++;
                }
                let result = {};
                result.url = m[0];
                result.meetingId = m[2];
                result.meetingPwd = m[4];
                results.push(result);
            }
            if (results.length <= 0) {
                let matchAll = msRegex.exec(entry);
                if (typeof matchAll !== 'undefined') {
                    try {
                        if (matchAll != null) {
                            if (matchAll.length > 0) {
                                results.push(matchAll[0]);
                            }
                        }
                    } catch (err) {
                        console.log("Array Convertion Error MSREGEX", err);
                    }
                }
            }
            if (results.length <= 0) {
                let matchAll = meetRegex.exec(entry);
                if (typeof matchAll !== 'undefined') {
                    try {
                        if (matchAll != null) {
                            if (matchAll.length > 0) {
                                results.push(matchAll[0]);
                            }
                        }
                    } catch (err) {
                        console.log("Array Convertion Error MSREGEX", err);
                    }
                }
            }
            if (results.length <= 0) {
                let matchAll = lifesizeRegex.exec(entry);
                if (typeof matchAll !== 'undefined') {
                    try {
                        if (matchAll != null) {
                            if (matchAll.length > 0) {
                                results.push(matchAll[0]);
                            }
                        }
                    } catch (err) {
                        console.log("Array Convertion Error MSREGEX", err);
                    }
                }
            }
        }
        return results;
    }

    update = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        scope.setState({[name]: value});
        if (this.state.meetingName.length > 0) this.setState({meetingNameError: ""});
        if (this.state.meetingURL.length > 0) this.setState({meetingURLError: ""});
        if (this.state.meetingPasscode.length > 0) this.setState({meetingPasscodeError: ""});
    };

    handleResize = (e) => {
        this.setState({windowWidth: window.innerWidth})
    };

    validateJoinMeetingData = () => {
        console.log(this.extractURL(this.state.meetingURL)[0]);
        let zoomURLRegex = new RegExp("https://[A-Za-z0-9_.]*zoom[A-Za-z0-9_.]*\\.us", "ig");
        let msTeamURLRegex = new RegExp("https://[A-Za-z0-9_.]*teams.microsoft.com[A-Za-z0-9_.]*");
        let lifeFlowUrlRegex = new RegExp("https://[A-Za-z0-9_.]*lifesizecloud.com[A-Za-z0-9_.]*");
        let googleMeetUrlRegex = new RegExp("https://[A-Za-z0-9_.]*meet.google.com/[A-Za-z0-9_.-]*");

        let isValidURL = googleMeetUrlRegex.test(this.extractURL(this.state.meetingURL)[0]) ||
            lifeFlowUrlRegex.test(this.extractURL(this.state.meetingURL)[0]) ||
            msTeamURLRegex.test(this.extractURL(this.state.meetingURL)[0]) ||
            zoomURLRegex.test(this.extractURL(this.state.meetingURL)[0]);

        if (this.state.meetingName.length < 1) this.setState({meetingNameError: "Please enter a Meeting Name"});
        if (this.state.meetingURL.length < 1) this.setState({meetingURLError: "Please enter a Meeting Link"});
        if(!isValidURL) this.setState({meetingURLError: "Please enter a valid Meeting Link"});
        if (this.state.meetingName.length > 0 && this.state.meetingURL.length > 0 && isValidURL) {
            this.props.inviteToMeeting(
                this.props.auth.user.id,
                this.props.auth.user.username,
                this.props.auth.user.fullname,
                this.state.meetingName,
                this.extractURL(this.state.meetingURL)[0],
                this.state.meetingPasscode);
        }
        //if(this.state.meetingPasscode.length < 1) this.setState({meetingPasscodeError: "Please enter a Meeting Password"});
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    render() {
        const {meeting} = this.props;
        const {windowWidth} = this.state;

        return (
            <div
                style={{margin: "0", width: "100%", position: "relative"}}
            >
                <div>

                    <div className="card w-100 box-shadow border-0 mb-3">
                        <div className="p-4">

                            <h2 className="fw-b f-12 f-blue">
                                {!meeting.liveMeetingLoading ?
                                    "Invite your AI Assistant" :
                                    "Your Live Meeting is Loading..."
                                }
                            </h2>

                            <p className="f-10">
                                Add your AI Assistant to an ongoing meeting within seconds by sharing the invitation
                                link.
                            </p>

                            <div className="mb-2">
                                <label
                                    htmlFor="meetingTitle"
                                    className={this.state.meetingNameError.length > 0 ?
                                        "form-input-title-error form-label f-11 fw-b" : "form-input-title form-label f-11 fw-b"}
                                >
                                    {this.state.meetingNameError.length > 0 ? this.state.meetingNameError : "Meeting Title"}
                                </label>
                                <input
                                    autoComplete="off"
                                    id="meetingTitle"
                                    name="meetingName"
                                    value={this.state.meetingName}
                                    onChange={this.update}
                                    className="form-control form-input invite-widget-input pt-0 f-9 border-start-0 border-top-0 border-end-0 rounded-0 ps-0"
                                    placeholder="Please enter the title of your meeting (this is how it will be saved in your archive)"
                                />
                            </div>

                            <div className={windowWidth > 1024 ? "row desktop-row" : "row"}>
                                <div className="col-12 col-lg-6">
                                    <div className="mb-2">
                                        <label
                                            className={this.state.meetingURLError.length > 0 ?
                                                "form-input-title-error form-label f-11 fw-b" : "form-input-title form-label f-11 fw-b"}
                                        >
                                            {this.state.meetingURLError.length > 0 ? this.state.meetingURLError : "Meeting Link"}
                                        </label>
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            name="meetingURL"
                                            value={this.state.meetingURL}
                                            onChange={this.update}
                                            className="form-control form-input invite-widget-input pt-0 f-9 border-start-0 border-top-0 border-end-0 rounded-0 ps-0"
                                            placeholder="Paste the link to your meeting here"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="mb-2">
                                        <label
                                            className={this.state.meetingPasscodeError.length > 0 ?
                                                "form-input-title-error form-label f-11 fw-b" : "form-input-title form-label f-11 fw-b"}
                                        >Password to enter meeting (only if required)</label
                                        >
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            className="form-control form-input invite-widget-input pt-0 f-9 border-start-0 border-top-0 border-end-0 rounded-0 ps-0"
                                            name="meetingPasscode"
                                            value={this.state.meetingPasscode}
                                            onChange={this.update}
                                            placeholder="Enter the password to your meeting here"
                                        />
                                    </div>
                                </div>
                            </div>

                            {false &&
                            <div className="invite-widget-title-div">
                                <img
                                    alt="Invite"
                                    src={inviteWidget}
                                    className="invite-widget-img"/>
                                <h5>
                                    {!meeting.liveMeetingLoading ?
                                        "Add Zoi Meet to your Meeting" :
                                        "Your Live Meeting is Loading..."
                                    }
                                </h5>
                            </div>
                            }

                            {false && !meeting.liveMeetingLoading &&
                            <section style={{display: "flex", flexDirection: "column"}}>
                                <p className="invite-widget-subtitle">
                                    Invite Zoi Meet to your conference to record and take actionable meeting
                                    notes.
                                </p>

                                <div className="form-input-outer">

                                    <p
                                        className={this.state.meetingNameError.length > 0 ? "form-input-title-error" : "form-input-title"}
                                    >
                                        {this.state.meetingNameError.length > 0 ? this.state.meetingNameError : "Meeting Name *"}
                                    </p>

                                    <input
                                        className="form-input"
                                        type="text"
                                        placeholder="Enter a Meeting Name (This is for your reference later)"
                                        name="meetingName"
                                        value={this.state.meetingName}
                                        onChange={this.update}
                                    />
                                </div>

                                <div className="form-input-outer">

                                    <p
                                        className={this.state.meetingURLError.length > 0 ? "form-input-title-error" : "form-input-title"}
                                    >
                                        {this.state.meetingURLError.length > 0 ? this.state.meetingURLError : "Meeting Link *"}
                                    </p>

                                    <input
                                        className="form-input"
                                        type="text"
                                        placeholder="Enter the Meeting Link"
                                        name="meetingURL"
                                        value={this.state.meetingURL}
                                        onChange={this.update}
                                    />
                                </div>

                                <div className="form-input-outer">

                                    <p
                                        className={this.state.meetingPasscodeError.length > 0 ? "form-input-title-error" : "form-input-title"}
                                    >
                                        {this.state.meetingPasscodeError.length > 0 ? this.state.meetingPasscodeError : "Password to Enter Meeting"}
                                    </p>

                                    <input
                                        className="form-input"
                                        type="text"
                                        placeholder="Enter Meeting Password"
                                        name="meetingPasscode"
                                        value={this.state.meetingPasscode}
                                        onChange={this.update}
                                    />
                                </div>

                                <button
                                    className="modal-button join-meeting"
                                    onClick={() => this.validateJoinMeetingData()}
                                >
                                    JOIN
                                </button>
                            </section>
                            }
                        </div>

                        <button
                            style={{width: "10rem"}}
                            className="invite-meeting-btn btn btn-orange f-12 btn-md-h fw-b rounded-4"
                            onClick={() => this.validateJoinMeetingData()}
                            disabled={meeting.liveMeetingLoading}
                        >
                            <span className="px-0">
                                {!meeting.liveMeetingLoading ?
                                    "Invite to meeting"
                                    : <Loader
                                        type="Oval"
                                        color="#FFF"
                                        height={20}
                                        width={20}
                                    />
                                }
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default InviteWidget;