import {
    UPDATE_USER_INFO_REQUEST,
    UPDATE_USER_PASSWORD_REQUEST,
    GET_USER_CALENDAR_INFO_REQUEST,
    GET_UPCOMING_USER_EVENTS,
    UPDATE_DEFAULT_CALL_OPT_REQUEST,
    GET_USER_CALENDAR_INFO_REGISTER_REQUEST
} from "./types";

export const updateUserInfoRequestAction = (email, firstName, lastName, companyName, jobTitle) => ({
    type: UPDATE_USER_INFO_REQUEST,
    params: {email, firstName, lastName, companyName, jobTitle}
});

export const updateUserPasswordRequestAction = (email, password, newPassword) => ({
    type: UPDATE_USER_PASSWORD_REQUEST,
    params: {email, password, newPassword}
});

export const getUserCalendarInfoAction = () => ({
    type: GET_USER_CALENDAR_INFO_REQUEST
});

export const getUserCalendarInfoRegisterAction = () => ({
    type: GET_USER_CALENDAR_INFO_REGISTER_REQUEST
});

export const getUserScheduledMeetingsAction = (pageNo) => ({
    type: GET_UPCOMING_USER_EVENTS,
    params: {pageNo}
});

export const updateDefaultCallOptAction = (opt) => ({
    type: UPDATE_DEFAULT_CALL_OPT_REQUEST,
    params: {opt}
})