import {
    ADD_COMMENT_REQUEST,
    DELETE_TRANSCRIPT_COMMENT_REQUEST, DELETE_TRANSCRIPT_HIGHLIGHT_COMMENT_REQUEST,
    FETCH_TRANSCRIPT_COMMENTS_FAILURE,
    FETCH_TRANSCRIPT_COMMENTS_REQUEST,
    FETCH_TRANSCRIPT_COMMENTS_SUCCESS,
    GET_MEETING_HIGHLIGHT_COMMENTS_REQUEST,
    GET_MEETING_HIGHLIGHT_COMMENTS_REQUEST_SUCCESS,
    UPDATE_COMMENTS, UPDATE_HIGHLIGHT_COMMENTS
} from "../actions/types";

const initialState = {
    commentsLoading: false,
    transcriptComments: [],
    newComment: {},
    finishedTranscriptComments: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_TRANSCRIPT_COMMENTS_REQUEST:
            return {
                ...state,
                commentsLoading: true,
            };

        case FETCH_TRANSCRIPT_COMMENTS_SUCCESS:
            let commentsShallowCopy = state.transcriptComments;

            for (let i = 0; i < action.data.length; i++) {
                let index = commentsShallowCopy.findIndex(e => e.id === action.data[i].id);
                if (index === -1)
                    //if ((commentsShallowCopy.filter(e => e.id === action.data.id).length > 0)) {
                    commentsShallowCopy = [...commentsShallowCopy, action.data[i]];
                //commentsShallowCopy.push(action.data[i]);
                //}
            }

            //console.log(commentsShallowCopy);

            return {
                ...state,
                commentsLoading: false,
                transcriptComments: commentsShallowCopy
            };

        case UPDATE_COMMENTS:
            console.log(action.data);
            let commentsShallowCopy2 = state.transcriptComments;

            let index = commentsShallowCopy2.findIndex(e => e.id === action.data.id);
            if (index === -1)
                //if ((commentsShallowCopy.filter(e => e.id === action.data.id).length > 0)) {
                commentsShallowCopy2 = [...commentsShallowCopy2, action.data];
            //commentsShallowCopy.push(action.data[i]);
            //}
            //console.log(commentsShallowCopy);

            return {
                ...state,
                commentsLoading: false,
                transcriptComments: commentsShallowCopy2
            };

            case UPDATE_HIGHLIGHT_COMMENTS:
            console.log(action.data);
            let finishedTranscriptCommentsSC = state.finishedTranscriptComments;
            finishedTranscriptCommentsSC.push(action.data)
            return {
                ...state,
                commentsLoading: false,
                finishedTranscriptComments: finishedTranscriptCommentsSC
            };

        case FETCH_TRANSCRIPT_COMMENTS_FAILURE:
            return {
                ...state,
                commentsLoading: false,
            };

        case ADD_COMMENT_REQUEST:
            return {
                ...state,
                commentsLoading: true,
            };

        case DELETE_TRANSCRIPT_COMMENT_REQUEST:
            let shallowCopy = [...state.transcriptComments];

            for (let i = 0; i < shallowCopy.length; i++) {
                let obj = shallowCopy[i];
                if (action.params.commentID === obj["id"])
                    shallowCopy.splice(i, 1);
            }

            return {
                ...state,
                transcriptComments: shallowCopy
            };

        case DELETE_TRANSCRIPT_HIGHLIGHT_COMMENT_REQUEST:
            let shallowCopy2 = [...state.finishedTranscriptComments];

            for (let i = 0; i < shallowCopy2.length; i++) {
                let obj = shallowCopy2[i];
                if (action.params.commentID === obj["id"])
                    shallowCopy2.splice(i, 1);
            }

            return {
                ...state,
                finishedTranscriptComments: shallowCopy2
            };

        case GET_MEETING_HIGHLIGHT_COMMENTS_REQUEST:

            return {
                ...state,
                commentsLoading: true,
            };

        case GET_MEETING_HIGHLIGHT_COMMENTS_REQUEST_SUCCESS:

            return {
                ...state,
                commentsLoading: false,
                finishedTranscriptComments: action.data
            };

        default:
            return state;
    }
}