import React, { useEffect } from 'react';


// ********************************************** //
// csr_target => calendar sync redirection target
// 100 => To registration page redirection
// 200 => To Settings page redirection
// ********************************************** //

const RedirectComponent = (props) => {

    useEffect(()=>{
        let to = localStorage.getItem("csr_target");
        if (to && to === "200") {
            localStorage.removeItem("csr_target");
            // props.history.push(`/settings${props.location.search}`);
            window.location.href = `/settings${props.location.search}`;
        } else if (to && to === "100") {
            localStorage.removeItem("csr_target");
            // props.history.push(`/register${props.location.search}`);
            window.location.href = `/register${props.location.search}`;
        } else {
            // props.history.push(`/`);
            window.location.href = `/`;
        }
    }, []);

    return (
        <div>
            <section className="archive account">
                <div className="container-fluid">
                    <div className="row" id="arch-block">
                        <div className="col-auto left-nav">
                            <h1>Syncronizing your calendar with Zoi Meet.</h1>
                        </div>
                    </div></div>
            </section>
        </div>
    )
}

export default RedirectComponent;