import React from "react";

import {OverlayTrigger, Tooltip} from "react-bootstrap";

import accountImg from '../../../assets/account.png';
import changePasswordImg from '../../../assets/changepassword.png';
import calendarImg from '../../../assets/calendar.png';

import "../style/SettingsMenuWidget.css";

class SettingsMenuWidget extends React.Component {
    constructor() {
        super();

        this.state = {
            activeTab: '',
            account: true,
            password: false,
            calendar: false
        }
    }

    handleSettingsTab = (choice) => {
        this.props.handleSettingsWidget(choice);

        if(choice === "account") {
            this.setState({account: true, password: false, calendar: false})
        };
        if(choice === "password") {
            this.setState({account: false, password: true, calendar: false})
        };
        if(choice === "calendar") {
            this.setState({account: false, password: false, calendar: true})
        };
    }

    render() {
        const {account, password, calendar} = this.state;

        return (
            <div className="settings-menu-outer">

                <OverlayTrigger
                    placement={"bottom"}
                    overlay={
                        <Tooltip style={{fontSize: ".6rem", fontFamily: "CeraPRO-Regular"}} id={`tooltip-bottom`}>
                            Your Profile Settings
                        </Tooltip>
                    }
                >
                    <img
                        alt="Profile"
                        className={(account) ? "settings-widget-img-selected" : "settings-widget-img"}
                        onClick={() => this.handleSettingsTab("account")}
                        src={accountImg}/>
                </OverlayTrigger>
                <OverlayTrigger
                    placement={"bottom"}
                    overlay={
                        <Tooltip style={{fontSize: ".6rem", fontFamily: "CeraPRO-Regular"}} id={`tooltip-bottom`}>
                            Your Password Settings
                        </Tooltip>
                    }
                >
                    <img
                        alt="Password"
                        className={(password) ? "settings-widget-img-selected" : "settings-widget-img"}
                        onClick={() => this.handleSettingsTab("password")}
                        src={changePasswordImg}/>
                </OverlayTrigger>
                <OverlayTrigger
                    placement={"bottom"}
                    overlay={
                        <Tooltip style={{fontSize: ".6rem", fontFamily: "CeraPRO-Regular"}} id={`tooltip-bottom`}>
                            Your Calendar Settings
                        </Tooltip>
                    }
                >
                    <img
                        alt="Calendar"
                        className={(calendar) ? "settings-widget-img-selected" : "settings-widget-img"}
                        onClick={() => this.handleSettingsTab("calendar")}
                        src={calendarImg}/>
                </OverlayTrigger>
            </div>
        );
    }
}

export default SettingsMenuWidget;