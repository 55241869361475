import React, {Component} from "react";
import DeleteIcon from "@material-ui/icons/Delete";

import "../style/CommentBox.css";

class CommentBox extends Component {

    render() {
        const {comment, commentID} = this.props;

        return (

            <div
                style={{width: "100%", margin: ".5rem 0 0 0"}}
                className="ps-3 pe-4 pt-3 pb-2 bg-light d-inline-block position-relative">

                <DeleteIcon
                    onClick={() => {
                        if (this.props.type === "dashboardHighlight")
                            this.props.deleteHighlightComment(commentID);
                        else this.props.deleteComment(commentID)
                    }}
                    style={{cursor: "pointer", height: "1rem", right: 0, top: 0, float: "right"}}
                />

                <p
                    style={{fontWeight: '500'}}
                    className="f-11">
                    {comment}
                </p>
            </div>
        );
    }
}

export default CommentBox;