import React, {Component} from "react";

import CommentBox from "../../CommentBox/container/CommentBox";

import Highlighter from "react-highlight-words";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import DeleteIcon from "@material-ui/icons/Delete";

import "../../LiveTranscript/style/LiveTranscript.css";
import {Loader} from "semantic-ui-react";

let classNames = [
    "",
    "",
    "live-transcript-category-action",
    "live-transcript-category-decision",
    "live-transcript-category-notes",
    "live-transcript-category-reminder",
    "live-transcript-category-question"];

let categories = [
    "",
    "None",
    "Action",
    "Decision",
    "Note",
    "Reminder",
    "Question"
];

class Transcript extends Component {
    constructor(props) {
        super(props);

        this.state = {
            actionDropDownMenu: false,
            currentCategory: this.props.category,
            commentInput: "",
            highlighted: this.props.highlighted,
            showCommentActions: false,
            editModeOn: false,
            transcriptData: this.props.data
        };

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    update = (e) => {
        this.setState({commentInput: e.target.value});
    };

    componentDidMount() {
        // if (this.props.active === "highlights") {
        //     this.props.fetchTranscriptComments(this.props.msgID);
        // }
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.state.actionDropDownMenu && this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({actionDropDownMenu: false})
        }
    }

    handleCat = (category, msgid, meetingID) => {
        console.log(this.props.meetingID);
        if (category === 1) this.setState({currentCategory: 1});
        if (category === 2) this.setState({currentCategory: 2});
        if (category === 3) this.setState({currentCategory: 3});
        if (category === 4) this.setState({currentCategory: 4});
        if (category === 5) this.setState({currentCategory: 5});
        if (category === 6) this.setState({currentCategory: 6});

        this.props.transcriptHandleCategory(category, msgid, localStorage.getItem(("currentMeeting")));
        this.setState({actionDropDownMenu: false});
        this.props.flushContentResult();
    };

    changeHighlight = () => {
        this.state.highlighted === 1 ?
            this.setState({highlighted: 0},
                () => this.props.handleHighlight(this.props.meetingID, this.props.msgID, this.state.highlighted)) :
            this.setState({highlighted: 1},
                () => this.props.handleHighlight(this.props.meetingID, this.props.msgID, this.state.highlighted));
    };

    convertSecondstoTime = (seconds) => {

        let hours = Math.floor(seconds / 3600);
        let minutes = Math.floor((seconds - (hours * 3600)) / 60);
        let seconds2 = seconds - (hours * 3600) - (minutes * 60);

        let timeString = "";

        timeString = hours.toString().padStart(2, '0') + ':' +
            minutes.toString().padStart(2, '0') + ':' +
            seconds2.toString().padStart(2, '0');

        return timeString;
    };

    render() {
        const {
            category,
            speakerLabel,
            msgID,
            meetingID,
            commentReducer,
            data,
            startTime
        } = this.props;

        const {
            actionDropDownMenu,
            currentCategory,
        } = this.state;

        return (
            <div>
                {(
                    this.props.active === "transcript"
                ) &&
                <div className="transcript-outer bg-light rounded mb-3 p-3">
                    <div
                        className="d-flex align-items-center justify-content-between">

                        <span className="f-11 fw-b">
                            {speakerLabel}
                        </span>

                        {(currentCategory === 1 || currentCategory === null) ?
                            <ArrowDropDownIcon
                                style={{cursor: "pointer"}}
                                onClick={() => this.setState({actionDropDownMenu: true})}
                            /> :
                            <p className={currentCategory === null ? classNames[category] : classNames[currentCategory]}
                               onClick={() => this.setState({actionDropDownMenu: true})}
                               style={{
                                   cursor: "pointer",
                                   fontSize: ".75rem",
                                   fontWeight: "bold",
                               }}>
                                {(currentCategory === null || currentCategory === 1) ? categories[category] : categories[currentCategory]}
                            </p>
                        }

                        {actionDropDownMenu &&
                        <div
                            className="action-items-pop-over-menu-dashboard stack-top"
                            ref={this.wrapperRef}
                        >

                            <li
                                className="live-transcript-category-action"
                                onClick={() => this.handleCat(2, msgID, meetingID)}
                            >

                                Action
                            </li>

                            <li
                                className="live-transcript-category-decision"
                                onClick={() => this.handleCat(3, msgID, meetingID)}
                            >

                                Decision
                            </li>

                            <li
                                className="live-transcript-category-notes"
                                onClick={() => this.handleCat(4, msgID, meetingID)}
                            >
                                Note
                            </li>

                            <li
                                className="live-transcript-category-none"
                                onClick={() => this.handleCat(1, msgID, meetingID)}
                            >

                                None
                            </li>
                        </div>
                        }
                    </div>


                    {(!this.props.isSearch) ?
                        <p
                            style={{cursor: "pointer"}}
                            onClick={() => {
                                localStorage.setItem("editMeetingID", meetingID);
                                localStorage.setItem("editTranscriptID", msgID);
                                localStorage.setItem("editTranscriptData", data);
                                this.props.openEditModal(msgID, meetingID, data);
                                //this.setState({editModeOn: true})
                            }}
                            className="f-11 mb-0">
                            {data}
                        </p>
                        :
                        <p
                            style={{cursor: "pointer"}}
                            onClick={() => {
                                localStorage.setItem("editMeetingID", meetingID);
                                localStorage.setItem("editTranscriptID", msgID);
                                localStorage.setItem("editTranscriptData", data);
                                this.props.openEditModal(msgID, meetingID, data);
                            }}
                            className="f-11 mb-0">
                            <Highlighter
                                highlightClassName="highlighted-word"
                                searchWords={[this.props.searchTerm]}
                                autoEscape={true}
                                textToHighlight={data}
                            />
                        </p>
                    }
                </div>
                }

                {this.props.active === "highlights" &&

                <div className="card highlights-card progress-line-align border-0 p-0">
                    {false && <div className="time-span"></div>}
                    <div className="highlight-card card-header pt-1 bg-white d-flex justify-content-between">
                        <div className="d-flex">
                            <div className="category-outer-dashboard-highlights-two">

                                <p
                                    onClick={() => this.setState({actionDropDownMenu: true})}
                                    className={currentCategory === null ? classNames[category] : classNames[currentCategory]}
                                    style={{
                                        cursor: "pointer",
                                        fontSize: ".75rem",
                                    }}>
                                    {(currentCategory === null || currentCategory === 1) ? categories[category] : categories[currentCategory]}
                                </p>

                                {actionDropDownMenu &&
                                <div
                                    className="action-items-pop-over-menu-dashboard-highlights stack-top"
                                    ref={this.wrapperRef}
                                >

                                    <li
                                        className="live-transcript-category-action"
                                        onClick={() => this.handleCat(2, msgID, meetingID)}
                                    >

                                        Action
                                    </li>

                                    <li
                                        className="live-transcript-category-decision"
                                        onClick={() => this.handleCat(3, msgID, meetingID)}
                                    >

                                        Decision
                                    </li>

                                    <li
                                        className="live-transcript-category-notes"
                                        onClick={() => this.handleCat(4, msgID, meetingID)}
                                    >
                                        Note
                                    </li>

                                    <li
                                        className="live-transcript-category-none"
                                        onClick={() => this.handleCat(1, msgID, meetingID)}
                                    >

                                        None
                                    </li>
                                </div>
                                }
                            </div>

                            <div
                                className="delete-button-highlight">
                                <DeleteIcon
                                    onClick={() => this.handleCat(1, msgID, meetingID)}
                                    style={{height: "1rem", cursor: "pointer"}}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="highlight-card-two card-body px-0 pb-0">
                        <div className="w-100 d-fex highlight-card-details">
                            <span className="f-11 fw-b f-dark me-2">{speakerLabel}</span>
                            <span className="f-11 f-light">{this.convertSecondstoTime(startTime)}</span>
                        </div>
                        <p
                            style={{cursor: "pointer"}}
                            onClick={() => {
                                localStorage.setItem("editMeetingID", meetingID);
                                localStorage.setItem("editTranscriptID", msgID);
                                localStorage.setItem("editTranscriptData", data);
                                this.props.openEditModal(msgID, meetingID, data);
                            }}
                            className="font-black f-11 mb-0">{data}</p>
                        {(commentReducer.commentsLoading) ?
                            <Loader
                                type="Oval"
                                color="#000"
                                height={24}
                                width={24}
                            /> :
                            <div style={{width: "100%"}}>
                                {commentReducer.finishedTranscriptComments.map((key, i) => {
                                    return (
                                        (key["transcript_id"] === this.props.msgID) ?
                                            <CommentBox
                                                type={"dashboardHighlight"}
                                                comment={key["comment"]}
                                                commentID={key["id"]}
                                                transcriptID={key["transcript_id"]}
                                            />
                                            :
                                            null
                                    )
                                })}
                            </div>
                        }

                        <div style={{display: "flex", flexDirection: "column"}}>

                            <textarea
                                className="comment-textarea"
                                name="commentInput"
                                value={this.state.commentInput}
                                placeholder="Add Comment"
                                onChange={this.update}
                                onFocus={() => this.setState({showCommentActions: true})}
                                onBlur={() => {
                                    if (this.state.commentInput.trim().length < 1) this.setState({showCommentActions: false})
                                }}
                            />

                            {this.state.showCommentActions &&
                            <div>

                                <button
                                    className={this.state.commentInput.trim().length < 1 ? "save-highlight-comment-button-disabled" : "save-highlight-comment-button"}
                                    disabled={this.state.commentInput.trim().length < 1}
                                    onClick={() => {
                                        this.setState({commentInput: "", showCommentActions: false});
                                        this.props.saveHighlightComment(this.state.commentInput, this.props.msgID);
                                    }}
                                >
                                    Save
                                </button>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                }
            </div>
        );
    }
}

export default Transcript;