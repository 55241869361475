import React, {Component} from "react";

import MeetingItem from "../../MeetingItem/container/MeetingItem";

import "../style/UpcomingMeetingsWidget.css";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

class UpcomingMeetingsWidget extends Component {
    constructor() {
        super();
        this.state = {
            windowWidth: window.innerWidth,
            currentPage: 1,
        }
    }

    handleResize = (e) => {
        this.setState({windowWidth: window.innerWidth})
    };

    handlePageChange = (type) => {
        if (type === "subtract") {
            if (this.state.currentPage > 1) {
                let tempCurrPage = this.state.currentPage;
                this.setState({currentPage: tempCurrPage - 1},
                    () => this.props.getUserScheduledMeetings(this.state.currentPage)
                );
            }
        }

        if (type === "add") {
            let tempCurrPage = this.state.currentPage;
            this.setState({currentPage: tempCurrPage + 1},
                () => this.props.getUserScheduledMeetings(this.state.currentPage)
            );
        }
    };

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    render() {
        const {calendarReducer} = this.props;
        const {windowWidth} = this.state;

        return (
            <div
                style={windowWidth > 990 ? {margin: "0"} : {margin: "250px 0 0 0"}}
                className="col-12 col-sm-12 col-md-12 col-lg-5 ps-lg-2">

                <div className="card w-100 box-shadow border-0">

                    <div className="px-4 pt-4 pb-3">


                        <h2 className="f-12 fw-b f-blue">
                            Upcoming meetings
                        </h2>

                        <p className="f-10">
                            You can turn off the AI Assistant for each meeting using the
                            toggle buttons.
                        </p>

                        <section className="w-100 d-inline-block set-card-lg-height">

                            {!calendarReducer.upcomingEventsLoading &&

                            calendarReducer.upcomingEvents.map((key, i) =>
                                <MeetingItem
                                    meetingID={key.id}
                                    meetingName={key.meeting_name}
                                    meetingDateAndTime={key.start_time}
                                    makeCall={key.make_call}
                                />
                            )}

                            {(!calendarReducer.upcomingEventsLoading && calendarReducer.upcomingEvents.length < 1) &&
                            <p className="no-upcoming-meetings-text">
                                No meetings were found on your connected calendar
                            </p>
                            }
                        </section>

                        {(this.state.currentPage > 1 || calendarReducer.upcomingEvents.length > 0) &&
                        <div style={{display: "flex"}}>

                            {this.state.currentPage > 1 &&
                            <ChevronLeftIcon
                                onClick={() => {
                                    this.handlePageChange("subtract")
                                }}
                                className="pagination-text"
                                style={{margin: "-.15rem .25rem 0 0", cursor: "pointer"}}/>
                            }

                            <p className={this.state.currentPage > 1 ? "f-12 fw-b pagination-text-small" : "f-12 fw-b pagination-text"}>
                                Page {this.state.currentPage}
                            </p>

                            {calendarReducer.upcomingEvents.length >= 10 &&
                            <ChevronRightIcon
                                onClick={() => {
                                    this.handlePageChange("add")
                                }}
                                style={{margin: "-.15rem 0 0 0", cursor: "pointer"}}/>
                            }
                        </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default UpcomingMeetingsWidget;