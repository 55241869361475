/** Initializing GA Module */
import ReactGA from 'react-ga';

/* Constants Based on Working Enviornment */
//export var appEnv = 'development';             // development | staging | production
//export var appEnv = 'staging';                 // development | staging | production
//export var appEnv = 'production';              // development | staging | production

export var appEnv = process.env.REACT_APP_ENV;

export var zoomLiveEnabled = (process.env.REACT_APP_LIVE_ZOOM === "enable");

export var msTeamEnabled = (process.env.REACT_APP_MS_TEAM === "enable");

export var msTeamLiveTranscriptsEnabled = (process.env.REACT_APP_MS_TEAM_LIVE_TRANSCRIPTS === "enable");

export var api_url = '';
export const google_signIn_clientId = '1032151483422-snv7bfj9ahlurho50iv7d3sm6svaonci.apps.googleusercontent.com';
export var analyticsID = '';
export var googleTagID = '';
export var gtm_auth = '';
export var gtm_preview = '';
export var stripetoken = '';
export var client_id_google = '';
export var client_id_outlook = '';
export var client_redirectUri_google = '';
export var client_redirectUri_outlook = '';


/* Development settings */
if (appEnv === 'development') {
  console.log('Running development environment');
  // api_url = "https://zoiserverdemo.pagekite.me"; //
  api_url = "https://bmnar9bbv3.execute-api.us-east-1.amazonaws.com" //
  gtm_auth = 'SOCc3scwQhgAqAO2D4hQUA';
  gtm_preview = 'env-2';
  analyticsID = 'GTM-DeV';
  googleTagID = 'UA-DEV_1';
  stripetoken = 'pk_test_a0th32BS4FuqcFNwAFfPmT7i00c6OgVPQ7';
  client_id_google = '393232898980-5p0667lk6p3q4l94s4klk6mj38b17a26.apps.googleusercontent.com';
  client_redirectUri_google = 'http://localhost:3000/CalendarSyncRedirect';
  client_id_outlook = '08598da8-c0c3-4506-ae2e-2c16d75c6a0b';
  client_redirectUri_outlook = 'http://localhost:3000/CalendarSyncRedirect';
}
/* Staging settings */
else if (appEnv === 'staging') {
  console.log('Running staging environment');
  api_url = 'https://bmnar9bbv3.execute-api.us-east-1.amazonaws.com';
  gtm_auth = 'SOCc3scwQhgAqAO2D4hQUA';
  gtm_preview = 'env-2';
  analyticsID = 'GTM-TEST_1';
  googleTagID = 'UA-TEST-1';
  stripetoken = 'pk_test_a0th32BS4FuqcFNwAFfPmT7i00c6OgVPQ7';
  client_id_google = '393232898980-5p0667lk6p3q4l94s4klk6mj38b17a26.apps.googleusercontent.com';
  client_redirectUri_google = 'https://development.d1lof9gwoqdqm6.amplifyapp.com/register';
  client_id_outlook = '08598da8-c0c3-4506-ae2e-2c16d75c6a0b';
  client_redirectUri_outlook = 'https://development.d1lof9gwoqdqm6.amplifyapp.com/register';
}
/* Production settings */
else {
  console.log('Running production environment');
  api_url = 'https://bmnar9bbv3.execute-api.us-east-1.amazonaws.com';
  gtm_auth = 'hruNXj9yAmFcV27DSXQQ-g';
  gtm_preview = 'env-1';
  analyticsID = 'UA-153982468-1';
  googleTagID = 'GTM-5FBLK6F';
  stripetoken = 'sk_test_Xl63v12db2qnnuALR8YKASiV009NTJN0Tk';
  client_id_google = '393232898980-5p0667lk6p3q4l94s4klk6mj38b17a26.apps.googleusercontent.com';
  client_redirectUri_google = 'https://staging.zoimeet.com/CalendarSyncRedirect';
  client_id_outlook = '08598da8-c0c3-4506-ae2e-2c16d75c6a0b';
  client_redirectUri_outlook = 'https://staging.zoimeet.com/CalendarSyncRedirect';
}


/* API URLS STARTS */

// export const api_url = "http://localhost:3000";

// export const login_api = api_url + "/api/login";
export const login_api = 'https://bmnar9bbv3.execute-api.us-east-1.amazonaws.com/api/login';
export const login_api_google = api_url + "/api/v2/user/glogin";
export const register_api = api_url + "/api/register";
export const forgot_api = api_url + '/api/forgotpassword';
export const pinvalid_api = api_url + '/api/isvalidpin';
export const userinfo_api = api_url + '/api/userinfo';
export const logout_api = api_url + '/api/logout'
export const usercalenderinfo_api = api_url + '/api/userCalenderInfo';
export const userCalenderSync = "http://localhost:8000/dev/calendar/sync";
export const userCalenderSync1 = api_url + '/api/calender/outlook';
export const userupdate_api = api_url + '/api/userupdate';
//export const meeting_api = api_url + '/api/meetings';
export const meeting_api = api_url + '/api/v2/meeting/meetings';
// export const meeting_msg_api = api_url + '/api/messages';
export const meeting_msg_api = api_url + '/api/v2/message/messagesforusermeeting';
export const rename_meeting_api = api_url + '/api/v2/meeting/renamemeeting';
export const delete_meeting_api = api_url + '/api/deletemeeting';
export const delete_message_api = api_url + '/api/v2/message/deletemessage';
export const search_meeting_api = api_url + '/api/v2/meeting/searchmeeting';
// export const search_chat_api = api_url + '/api/searchmessage';
export const search_chat_api = api_url + '/api/v2/message/searchmessageforuser';
export const export_chat_api = api_url + '/export/exportmeeting';
export const export_audio = api_url + '/export/audioexport';
export const export_msteam_audio = api_url + '/export/gcsaudio'
export const export_highlights_api = api_url + '/export/highlights';
export const export_actionitem_api = api_url + '/export/actionexport'
export const update_profileimage_api = api_url + '/api/profileimage';
export const delete_profileimage_api = api_url + '/api/profileimage';
export const change_password_api = api_url + '/api/changepassword';
export const add_highlights_api = api_url + '/api/highlights';
export const delete_highlights_api = api_url + '/api/highlights';
export const list_highlights_api = api_url + '/api/v2/message/highlights';
export const list_meetinglanguages_api = api_url + '/api/meetinglanguages';
export const edit_chat_api = api_url + '/api/v2/message/editmessage';
export const keyword_cloud_api = api_url + '/api/keywordCloud';
export const action_triggers_api = api_url + '/api/actiontriggers';
export const delete_action_triggers_api = api_url + '/api/editactiontriggers';
export const external_meeting_join_api = api_url + '/liveassistant/initaitephonecall';
export const load_actionitem_api = api_url + '/api/v2/message/actiontriggertranscripts';
export const change_action_category_api = api_url + '/api/changeactioncategory';
export const get_overview_api = api_url + '/api/v2/user/getoverview';
export const get_subscription_plans = api_url + '/api/v2/user/availableSubscriptions'
export const get_subscription_status = '';
export const get_payment_session = api_url + '/api/paymentsession'
export const update_payment_subscription = api_url + '/api/updateSubscriptions';
export const get_user_plans = api_url + '/api/v2/user/vplans'
export const make_live_conference_call = api_url + '/api/v2/meeting/startlivemeeting';
export const make_msteam_call = api_url + '/msteam_liveassistant/initiatephonecall';


/* ................................................................................................................................... */

/* WEBAPP URLS */

export const home_url = "/home";
export const settings_url = "/settings";
export const live_meeting_url = "/live";
export const login_url = '/';
export const register_url = '/register';
export const dashboard_url = '/dashboard';
export const meeting_url = '/Meeting';
export const forgot_url = '/forgot';
export const reset_url = '/reset';
export const thank_you = '/thankyou';
export const logout_url = '/logout'
export const account_url = '/Account';
export const archive_url = '/Archive';
export const archives_url = '/archives';
export const dictionary_url = '/Dictionary';
export const guest_url = '/Guest';
export const triggers_url = '/triggers';
export const onboard_url = '/Onboard';
export const account_url_second = '/Account2'
export const googleAuth_url = '/google7bbb137666f8ea65'
export const calendar_sync_redirection_url = '/CalendarSyncRedirect';


/**
 * isZoomLiveEnabled 
 * this method return true or false based on environment variable 
 * look .env file
 * live trancription for zoom meeting 
 */

export function getZoomLiveEnabled() {
  return zoomLiveEnabled;
}

/**
* msTeamEnabled 
* this method return true or false based on environment variable 
* look .env file
* action for ms teams 
*/

export function getMsTeamsEnabled() {
  return msTeamEnabled;
}

/**
* msTeamEnabled LiveMeeting transcripts
* this method return true or false based on environment variable 
* look .env file
* action for ms teams 
there by redirect to meeting html page works
*/

export function getMsTeamsLiveTranscriptsEnabled() {
  return msTeamLiveTranscriptsEnabled;
}




/** COOKIE VARIABLE */
//export const 
export const COOKIES_ENUM = Object.freeze(
  {
    USERNAME: "web_username",
    DISPLAYNAME: "web_display_name",
    FULLNAME: "web_fullname",
    USERID: "web_userid",
    EMAILID: "web_emailid",
    LC: "web_LC",
    GOOGLEUSER: "web_GoogleUser",
    FIRSTLOGIN: "web_first_login",
    MPIN: "web_mpin",
    SHOWLIVEMENU: "web_showLiveMenu",
    SHOWJOINMENU: "web_showJoinMenu",
    ZOOM_MEETINGPIN: "web_zoomPin",
    ZOOM_PASSWORD: "web_zoomPassword",
    ZOOM_TITLE: "web_zoomTitle",
    ACTION: "action",

  });

export const SESSIONSTORAGE_ENUM = Object.freeze({

  CURRENTSELECTEDMEETING: "web_selected_meeting",
  FROMLOGIN: "from_login_page",
  ONEXIT: "on_exit",
  SUBSCRIPTIONSTATUS: "SubStatus",
  // SUB_EXP_LIMIT: "Sub_exp_limit"

});

export const ORDER_ENUM = Object.freeze({
  Ascending: 0,
  Descending: 1
})




/* Store urls */

export const playStore_url = "https://play.google.com/store/apps/details?id=com.zoimeet.enterprise&hl=en";
export const vuzixStore_url = "https://www.vuzix.com/appstore/app/zoimeet-live";
//export const appleStore_url = "";


export function validEmail(email) {
  let email_reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  return email_reg.test(email) !== false;
}

/**
 * function return all meeting languages 
 * temp function
 * need to change get all languages to api call
 */


export function getAllLanguages() {
  const allLanguages = {

    en: "English"

  };

  return allLanguages;
}
/* ar: "Arabic",
    zh: "Chinese",
ja: "Japanese",
    es: "Spanish",
    nl: "Dutch",
    pt: "Portuguese",
    fr: "French",
    de: "German",
    pl: "Polish",
    ko: "Korean",
    it: "Italian" */

export function getSelectedLanguageCode(languagename) {

  var languagesDic = getAllLanguages();
  return Object.keys(languagesDic).find(key => languagesDic[key] === languagename);
}



export function setSessionStorage(key, value) {
  window.sessionStorage.setItem(key, value);
}

export function getSessionStorage(key) {
  return sessionStorage.getItem(key);
}
export function deleteSessionStorage(key) {
  return sessionStorage.removeItem(key);
}

export function setLocalStorage(key, value) {
  localStorage.setItem(key, value);
}

export function getLocalStorage(key) {
  return localStorage.getItem(key);
}
export function deleteLocalStorage(key) {
  return localStorage.removeItem(key);
}


export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

/**
* convert milliseconds to minutes and seconds
*/

export function millisToMinutesAndSeconds(millis) {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
}
export function differenceBetweenTimes(startTime, endTime) {
  // const DATE_OPTIONS_TIME = { hour: '2-digit', minute: '2-digit', seconds: '2-digit'};

  var startDate = new Date(startTime);
  var endDate = new Date(endTime);
  var dif = endDate.getTime() - startDate.getTime();

  var Seconds_from_T1_to_T2 = dif / 1000;
  var Seconds_Between_Dates = Math.abs(Seconds_from_T1_to_T2);

  // var string = endTime + ' UTC';
  // var date =  new Date(string);
  // var string = date.toLocaleTimeString()
  // var dateString = date.toString();
  // var splitString = string.split(", ");//For Temperory Usage
  return toHHMMSS(Seconds_Between_Dates);
}

var toHHMMSS = (secs) => {
  var sec_num = parseInt(secs, 10)
  var hours = Math.floor(sec_num / 3600)
  var minutes = Math.floor(sec_num / 60) % 60
  var seconds = sec_num % 60

  return [hours, minutes, seconds]
    .map(v => v < 10 ? "0" + v : v)
    .filter((v, i) => v !== "00" || i > 0)
    .join(":")
}


export function getHostName(url) {
  var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
  if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
    return match[2];
  }
  else {
    return null;
  }
}

export function getDomain() {
  var hostName = getHostName(api_url);
  var domain = hostName;

  if (hostName != null) {
    var parts = hostName.split('.').reverse();

    if (parts != null && parts.length > 1) {
      domain = parts[1] + '.' + parts[0];

      if (hostName.toLowerCase().indexOf('.co.uk') !== -1 && parts.length > 2) {
        domain = parts[2] + '.' + domain;
      }
    }
  }

  console.log(domain + ";path=/");

  return domain;
}

export function setCookie(cname, cvalue) {

  var baseDomain = '.' + getDomain();

  var expireAfter = new Date();

  //setting up  cookie expire date after a week
  expireAfter.setDate(expireAfter.getDate() + 7);

  if (baseDomain.includes('.zoimeet.')) {
    document.cookie = cname + "=" + cvalue + ";domain=" + baseDomain + ";expires=" + expireAfter + ";path=/";
  } else {
    document.cookie = cname + "=" + cvalue + ";expires=" + expireAfter + ";path=/";
  }
  // sessionScheckSessionValidityActiontorage.setItem(cname, cvalue);
}

export function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  // console.log("Coookie Details getCookie", name, ca);
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";

  // return sessionStorage.getItem(cname);
}

export function deleteEachCookie() {

  deleteCookie(COOKIES_ENUM.DISPLAYNAME);
  deleteCookie(COOKIES_ENUM.USERNAME);
  deleteCookie(COOKIES_ENUM.LC);
  deleteCookie(COOKIES_ENUM.EMAILID);
  deleteCookie(COOKIES_ENUM.GOOGLEUSER);
  deleteCookie(COOKIES_ENUM.FULLNAME);
  deleteCookie(COOKIES_ENUM.USERID);
  deleteCookie(COOKIES_ENUM.ACTION);
  deleteCookie(COOKIES_ENUM.ZOOM_MEETINGPIN);
  deleteCookie(COOKIES_ENUM.ZOOM_TITLE);
  deleteCookie(COOKIES_ENUM.ZOOM_PASSWORD);
  sessionStorage.removeItem('status');

}

export function deleteAllCookies() {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }

  // sessionStorage.clear();
}

export function deleteCookie(name) {
  setCookie(name, '', -1);
  // sessionStorage.removeItem(name);
}

export function sendDataToAnalytics(catagoryName) {

  var action = 'User Id ' + getCookie(COOKIES_ENUM.USERID);
  var value = 'Email ' + getCookie(COOKIES_ENUM.EMAILID);

  ReactGA.event({
    category: catagoryName,
    action: action,
    value: value,
    label: getCookie(COOKIES_ENUM.FULLNAME)
  });

}

/**
     * For checking if a string is empty, null, undefined or contains only white-space
     */
export function isEmpty(str) {
  if (Boolean(str)) {
    return !str.trim();
  } else {
    return true;
  }
}



