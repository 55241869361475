import React, {Component} from 'react';
import {Route, Router} from 'react-router-dom';
import * as Constants from './components/Common';
import Home from './pages/Home/Home'
import Archives from "./pages/Archives/Archives";
import Login from './pages/Login/Login'
import Register from './pages/Register/Register'
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import Triggers from "./pages/Triggers/Triggers";
import ThankYou from "./pages/RegistrationThankYou/ThankYou";
import Settings from "./pages/Settings/Settings";
import Logout from './pages/Logout/Logout';
import CalendarSyncRedirect from './pages/CalendarSyncRedirect/calendarSyncRedirectPage';

import store from './store';
import {Provider} from "react-redux";
import {createBrowserHistory} from 'history';
import LiveMeeting from "./pages/LiveMeeting/LiveMeeting";
import Dashboard from "./pages/Dashboard/Dashboard";
import ResetPassword from "./pages/ResetPassword/ResetPassword";

class App extends Component {

    render() {
        return (
            <Provider store={store}>
                <Router history={createBrowserHistory()}>
                    <div className="App">

                        <Route exact path={Constants.login_url} component={Login}/>
                        <Route path={Constants.logout_url} component={Logout}/>
                        <Route path={Constants.register_url} component={Register}/>
                        <Route path={Constants.dashboard_url} component={Dashboard}/>
                        {<Route path={Constants.home_url} component={Home}/>}
                        {<Route path={Constants.archives_url} component={Archives}/>}
                        {<Route path={Constants.triggers_url} component={Triggers}/>}
                        {<Route path={Constants.settings_url} component={Settings}/>}
                        {<Route exact path={Constants.live_meeting_url} component={LiveMeeting}/>}
                        <Route path={Constants.forgot_url} component={ForgotPassword}/>
                        <Route path={Constants.reset_url} component={ResetPassword}/>
                        <Route path={Constants.thank_you} component={ThankYou}/>
                        <Route path={Constants.calendar_sync_redirection_url} component={CalendarSyncRedirect}/>
                    </div>
                </Router>
            </Provider>
        );
    }
}

export default App;
