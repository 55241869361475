import React, {Component} from "react";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import CommentBox from "../../CommentBox/container/CommentBox";

import "../style/LiveTranscript.css";

let classNames = [
    "",
    "",
    "live-transcript-category-action",
    "live-transcript-category-decision",
    "live-transcript-category-notes",
    "live-transcript-category-reminder",
    "live-transcript-category-question"];

let categories = [
    "",
    "None",
    "Action",
    "Decision",
    "Note",
    "Reminder",
    "Question"
];

class LiveTranscript extends Component {
    constructor(props) {
        super(props);

        this.state = {
            actionDropDownMenu: false,
            currentCategory: this.props.category,
            commentInput: "",
            highlighted: this.props.highlighted,
            showCommentActions: false,
            windowWidth: window.innerWidth,
        };

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    update = (e) => {
        this.setState({commentInput: e.target.value});
    };

    componentDidMount() {
        this.props.fetchTranscriptComments(this.props.msgID);
        document.addEventListener('mousedown', this.handleClickOutside);
        window.addEventListener("resize", this.handleResize);
    }

    handleResize = (e) => {
        this.setState({windowWidth: window.innerWidth})
    };

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.state.actionDropDownMenu && this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({actionDropDownMenu: false})
        }
    }

    handleCat = (category, msgid, meetingID) => {
        if (category === 1) this.setState({currentCategory: 1});
        if (category === 2) this.setState({currentCategory: 2});
        if (category === 3) this.setState({currentCategory: 3});
        if (category === 4) this.setState({currentCategory: 4});
        if (category === 5) this.setState({currentCategory: 5});
        if (category === 6) this.setState({currentCategory: 6});

        this.props.handleLiveCategory(category, msgid,  localStorage.getItem("liveMeetingID"));
        //this.props.handleCategory(category, msgid,  localStorage.getItem("liveMeetingID"));
        this.setState({actionDropDownMenu: false});
    };

    convertSecondstoTime = (seconds) => {

        let hours = Math.floor(seconds / 3600);
        let minutes = Math.floor((seconds - (hours * 3600)) / 60);
        let seconds2 = seconds - (hours * 3600) - (minutes * 60);

        let timeString = "";
            timeString = hours.toString().padStart(2, '0') + ':' +
                minutes.toString().padStart(2, '0') + ':' +
                seconds2.toString().padStart(2, '0');

        return timeString;
    };

    render() {
        const {
            category,
            startTime,
            speakerLabel,
            msgID,
            meetingID,
            commentReducer,
            data,
            widget,
        } = this.props;

        const {
            actionDropDownMenu,
            currentCategory,
            windowWidth
        } = this.state;

        return (
            widget.activeTab.activeTab === "transcript" ?
                <div
                    className="live-transcript-outer"
                >

                    <div className={windowWidth > 1080 ? "live-transcript-inner": "live-transcript-inner-mobile"}>

                        <div className="live-transcript-header">

                            <p className="live-transcript-speaker-label">
                                {speakerLabel !== "" ? speakerLabel : ""}
                            </p>

                            <div className="category-outer">

                                {(currentCategory === 1 || currentCategory === null) ?

                                    <ArrowDropDownIcon
                                        style={{cursor: "pointer"}}
                                        onClick={() => this.setState({actionDropDownMenu: true})}
                                    />  :
                                    <p className={currentCategory === null ? classNames[category] : classNames[currentCategory]}
                                       onClick={() => this.setState({actionDropDownMenu: true})}
                                       style={{
                                           cursor: "pointer",
                                           fontSize: ".75rem",
                                           fontWeight: "bold",
                                       }}>
                                        {(currentCategory === null || currentCategory === 1) ? categories[category] : categories[currentCategory]}
                                    </p>
                                }

                                {actionDropDownMenu &&
                                <div
                                    className={windowWidth > 1080 ? "action-items-pop-over-menu":"action-items-pop-over-menu-mobile"}
                                    ref={this.wrapperRef}
                                >

                                    <li
                                        className="live-transcript-category-action"
                                        onClick={() => this.handleCat(2, msgID, meetingID)}
                                    >

                                        Action
                                    </li>

                                    <li
                                        className="live-transcript-category-decision"
                                        onClick={() => this.handleCat(3, msgID, meetingID)}
                                    >

                                        Decision
                                    </li>

                                    <li
                                        className="live-transcript-category-notes"
                                        onClick={() => this.handleCat(4, msgID, meetingID)}
                                    >
                                        Note
                                    </li>

                                    <li
                                        className="live-transcript-category-none"
                                        onClick={() => this.handleCat(1, msgID, meetingID)}
                                    >

                                        None
                                    </li>
                                </div>
                                }
                            </div>
                        </div>

                        <p
                            onClick={() => {
                                localStorage.setItem("editMeetingID", meetingID);
                                localStorage.setItem("editTranscriptID", msgID);
                                localStorage.setItem("editTranscriptData", data);
                                this.props.openEditModalLive(msgID, meetingID, data);
                            }}
                            className="live-transcript-data">
                            {data}
                        </p>

                        <p className="live-transcript-timestamp">
                            {this.convertSecondstoTime(startTime)
                            }
                        </p>
                    </div>
                </div>
                :
                <div
                    className={windowWidth > 1080 ? "live-highlight-outer":"live-highlight-outer-mobile"}
                >
                <span className="timeline-point">

                </span>

                    <p className="live-highlight-timestamp">

                        {this.convertSecondstoTime(startTime)}
                    </p>

                    <div className={windowWidth > 1080 ? "live-transcript-inner": "live-transcript-inner-mobile"}>

                        <div className="live-transcript-header">

                            <p className="live-transcript-speaker-label">
                                {speakerLabel !== "" ? speakerLabel : ""}
                            </p>

                            <div className="category-outer">

                                {(currentCategory === 1 || currentCategory === null) ?

                                    <MoreVertIcon
                                        className="arrow-dropdown-menu"
                                        onClick={() => this.setState({actionDropDownMenu: true})}
                                    /> :
                                    <p className={currentCategory === null ? classNames[category] : classNames[currentCategory]}
                                       onClick={() => this.setState({actionDropDownMenu: true})}
                                       style={{
                                           cursor: "pointer",
                                           fontSize: ".75rem",
                                           fontWeight: "bold",
                                       }}>
                                        {(currentCategory === null || currentCategory === 1) ? categories[category] : categories[currentCategory]}
                                    </p>
                                }

                                {actionDropDownMenu &&
                                <div
                                    className={windowWidth > 1080 ? "action-items-pop-over-menu":"action-items-pop-over-menu-mobile"}
                                    ref={this.wrapperRef}
                                >

                                    <li
                                        className="live-transcript-category-action"
                                        onClick={() => this.handleCat(2, msgID)}
                                    >

                                        Action
                                    </li>

                                    <li
                                        className="live-transcript-category-decision"
                                        onClick={() => this.handleCat(3, msgID)}
                                    >

                                        Decision
                                    </li>

                                    <li
                                        className="live-transcript-category-notes"
                                        onClick={() => this.handleCat(4, msgID)}
                                    >
                                        Note
                                    </li>

                                    <li
                                        className="live-transcript-category-none"
                                        onClick={() => this.handleCat(1, msgID)}
                                    >

                                        None
                                    </li>
                                </div>
                                }
                            </div>
                        </div>

                        <p
                            onClick={() => {
                                localStorage.setItem("editMeetingID", meetingID);
                                localStorage.setItem("editTranscriptID", msgID);
                                localStorage.setItem("editTranscriptData", data);
                                this.props.openEditModalLive(msgID, meetingID, data);
                            }}
                            className="live-transcript-data">
                            {data}
                        </p>

                        {commentReducer.transcriptComments.length > 0 &&
                        <div>
                            {commentReducer.transcriptComments.map((key, i) => {
                                return (
                                    (key["transcript_id"] === this.props.msgID) ?
                                        <CommentBox
                                            type={"liveHighlight"}
                                            comment={key["comment"]}
                                            commentID={key["id"]}
                                            transcriptID={key["transcript_id"]}
                                        />
                                        :
                                        null
                                )
                            })}
                        </div>
                        }

                        <textarea
                            className="comment-textarea"
                            name="commentInput"
                            value={this.state.commentInput}
                            placeholder="Add Comment"
                            onChange={this.update}
                            onFocus={() => this.setState({showCommentActions: true})}
                            onBlur={() => {
                                if (this.state.commentInput.trim().length < 1) this.setState({showCommentActions: false})
                            }}
                        />

                        {this.state.showCommentActions &&
                        <div className="comment-actions-div">

                            <button
                                className={this.state.commentInput.trim().length < 1 ? "save-comment-button-disabled" : "save-comment-button"}
                                disabled={this.state.commentInput.trim().length < 1}
                                onClick={() => {
                                    this.setState({commentInput: "", showCommentActions: false});
                                    this.props.saveComment(this.state.commentInput, this.props.msgID);
                                }}
                            >
                                Save
                            </button>
                        </div>
                        }
                    </div>
                </div>
        );
    }
}

export default LiveTranscript;